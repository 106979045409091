/**
 * @module useIsVisible
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
 * Represents a hook to track whether or not an element is visible in the viewport.
 *
 * @param {node} ref - The ref object that points to the elements on which to check visibility.
 *
 * @returns {boolean} - Boolean flag denoting whether or not the ref object is visible.
 */
export default function useIsVisible(ref) {
  const [isVisible, setIsVisible] = React.useState(false);

  React.useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      const { isIntersecting, intersectionRate } = entry;
      const isInViewPort = isIntersecting || intersectionRate > 0;
      setIsVisible(isInViewPort);
    });

    const targetRef = ref?.current;

    if (targetRef) {
      observer.observe(targetRef);
    }

    return () => {
      if (targetRef) {
        observer.unobserve(targetRef);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isVisible;
}
