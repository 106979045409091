/**
 * @module mediaDetailFetcher
 */
import axios from 'axios';
import _ from 'lodash';
import {
  Log,
  replaceMagnoliaAssetsToImgix,
} from '@lifechurch/web-tools-io/dist/utils/helpers';
import { getAPIBase } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/getAPIBase';
import { getMagnoliaItem } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/getMagnoliaItem';
import { convertS3DamItems } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/imgix';
import { getLabelList } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/labels';
import Baptized from '../../assets/media-detail-series-form-icon/baptized.svg';
import Commit from '../../assets/media-detail-series-form-icon/commit.svg';
import HorizontalBars from '../../assets/media-detail-series-form-icon/horizontal-bars.svg';
import Known from '../../assets/media-detail-series-form-icon/known-icon.svg';
import LifeGroup from '../../assets/media-detail-series-form-icon/lifeGroup.svg';
import NewHere from '../../assets/media-detail-series-form-icon/new-here.svg';
import Prayer from '../../assets/media-detail-series-form-icon/prayer.svg';
import Question from '../../assets/media-detail-series-form-icon/question.svg';
import Renew from '../../assets/media-detail-series-form-icon/renew.svg';
import Serve from '../../assets/media-detail-series-form-icon/serve.svg';
import {
  fetchAccordionData,
  fetchInThisSeries,
  fetchRelatedContent,
} from './commonFetchers';
import {
  ENDPOINT_WORKSPACE_MAP,
  MAGNOLIA_ENDPOINTS,
  MEDIA_TYPES,
  MGNL_ENV_VARS,
  PAGINATION_OPTIONS,
  WEB_DISTRIBUTION_PLATFORM_UUID,
} from '../constants';

/**
 * NOTE: With the introduction to pagination, the logic is updated to include:
 * - Set pagination object based on response.
 * - Set latest to first of results array ONLY IF response offset is 0.
 * - Set past to items 1 through 10 (9 total) ONLY IF response offset is 0.
 * - Set past to items 1 through end ONLY IF response offset is 0 and results length is less than 10.
 * - Set past to all results ONLY IF response offset is not 0.
 *
 * This logic ensures we only set latest if at the start of the results, and
 * allows for divisible-by-3 result totals (unless at the end).
 *
 * Results length cap value for initial results and pagination calculation, set
 * to 12 because, unlike collection fetching that includes the latest item with
 * the result, detail does not do so. As such, this value is divisible by 3 to
 * match with the layout of the divisible-by-3 based grid.
 */

const resultsLengthCap = 12;

export const arrIcon = {
  '6b721d23-f533-4ae8-81e8-99be904419e7': Baptized,
  '109cdde4-a9cd-4f69-9812-ad24e59683e1': NewHere,
  '365f27b1-8b99-4cc4-abe7-1d882cc95568': Prayer,
  '234687f8-6aa6-4472-9915-2093cc1c223e': Serve,
  'b3d93035-8a5a-4080-8f2d-be1c9d6a6b8c': Commit,
  'bda76148-e7c1-48b8-bb0c-919ab26dad45': Known,
  'e2eabdb6-f8f1-4b1d-8225-cb367c6e46ca': LifeGroup,
  'e68c512a-b51b-48c9-80c7-2f68fc766d12': Question,
  'fe551be9-0f3b-4fc2-a736-9bbf3c84a977': Renew,
};
export const arrIconLocal = {
  'horizontal-bars': HorizontalBars,
};

/**
 * Convenience function to fetch the current podcast quotes.
 *
 * @param {object} shareableQuotes - The object containing the podcast quotes data.
 * @param {number} numberOfQuotes - The number of quotes to be fetched.
 *
 * @returns {Array} Array of quote links for the podcast.
 */
export async function getQuotes(shareableQuotes, numberOfQuotes) {
  const urls = [];
  const imgixPromises = [];
  const shareableQuotesNodes = shareableQuotes?.['@nodes'];

  try {
    if (shareableQuotesNodes?.length) {
      shareableQuotesNodes.forEach((item) => {
        urls.push(shareableQuotes[item].quoteImage);
      });
    } else {
      for (let i = 1; i <= numberOfQuotes; i += 1) {
        urls.push(
          `${process.env.IMGIX_BASE_URL}/Media/Craig-Groeschel-Leadership-Podcast/default-quotes/cglpquote_${i}-2x.png`,
        );
      }
    }

    for (let i = 0; i < urls.length; i += 1) {
      imgixPromises[i] = replaceMagnoliaAssetsToImgix(
        urls[i],
        process.env.IMGIX_BASE_URL,
      );
    }

    const results = await Promise.all(imgixPromises);
    return results;
  } catch (err) {
    Log.error(err);
    return null;
  }
}

/**
 * Convenience function to fetch series-based media item data.
 *
 * @param {object} mediaItemData - The media data object.
 *
 * @returns {object} The fetched media item data.
 */
export async function fetchMediaItemSeriesBased(mediaItemData) {
  let formListing;
  const apiPath = `/.rest/delivery/modal?jcr:uuid=${Object.keys(arrIcon).join(
    '|',
  )}`;
  await axios
    .get(encodeURI(`${getAPIBase(MGNL_ENV_VARS)}${apiPath}`))
    .then((res) => {
      if (res.status === 200) {
        formListing = res.data.results;
      }
    })
    .catch((err) => {
      Log.log({ err });
    });

  const mediaType = mediaItemData?.['@path'].split('/')?.[1];

  const inThisSeries = await fetchInThisSeries({
    data: mediaItemData,
    getMoreItems: true,
    mediaType,
    source: 'media',
  });
  const relatedContent = await fetchRelatedContent(mediaItemData);

  return {
    formListing,
    inThisSeries,
    relatedContent,
  };
}

/**
 * Convenience function to fetch song-based media items data.
 *
 * @param {object} data - The media data object.
 *
 * @returns {object} The object containing the song list.
 */
export async function fetchingSongList(data) {
  const mediaItemPath = data?.['@path'];

  if (mediaItemPath) {
    const [, mediaType, collectionName] = mediaItemPath.split('/');
    const mediaCollection = `/${mediaType}/${collectionName}`;

    const { results } = await getMagnoliaItem({
      caller: 'src/helpers/dataFetchers/mediaDetailFetcher.js > fetchSongList',
      mgnlEnvVars: MGNL_ENV_VARS,
      path: encodeURI(
        `${MAGNOLIA_ENDPOINTS.delivery.mediaItems}?@ancestor=${mediaCollection}&availablePlatforms[eq]=${WEB_DISTRIBUTION_PLATFORM_UUID}&orderBy=startDate`,
      ),
      workspaceMap: ENDPOINT_WORKSPACE_MAP,
    }); // NOSONAR

    const tempArr = results || [];
    const songList = _.sortBy(tempArr, (item) => Number(item.part));

    return { songList };
  }
  return null;
}

/**
 * Convenience function to fetch episode-based media item data.
 *
 * @param {object} data - The media data object.
 *
 * @returns {object} The object containing the related content.
 */
export async function fetchMediaItemEpisodeBased(data) {
  const relatedContent = await fetchRelatedContent(data);
  return { relatedContent };
}

/**
 * Convenience function to fetch podcast-based media item data.
 *
 * @param {object} data - The media data object.
 *
 * @returns {object} The fetched media item data.
 */
export async function fetchMediaItemPodcastBased(data) {
  const NUMBER_OF_QUOTES = 5;

  if (!data) {
    return {
      accordionData: {},
      pagination: {},
      quotes: [],
      resourceLabels: [],
    };
  }

  const { shareableQuotes } = data;

  const [tempArr] = await Promise.all([
    getLabelList({
      ancestor: `media_item_podcast`,
      mgnlEnvVars: MGNL_ENV_VARS,
      workspaceMap: ENDPOINT_WORKSPACE_MAP,
    }),
  ]);
  const accordionDataResult = await fetchAccordionData({
    data,
    getMoreItems: true,
  });
  const quotes = await getQuotes(shareableQuotes, NUMBER_OF_QUOTES); // NOSONAR

  return {
    accordionData: accordionDataResult.accordionData,
    pagination: accordionDataResult.pagination,
    quotes,
    resourceLabels: tempArr,
  };
}

/**
 * Convenience function to fetch early-childhood-based media item data.
 *
 * @param {object} data - The media data object.
 *
 * @returns {object} The fetched media item data.
 */
export async function fetchMediaItemEarlyChildhoodBased(data) {
  const collection = data?.['@path']?.split('/')?.[1];
  let pastMediaList = [];
  let json;

  if (collection) {
    const {
      limit = PAGINATION_OPTIONS.mediaDetail.limit,
      offset = PAGINATION_OPTIONS.mediaDetail.offset,
    } = data;
    const limitQuery =
      limit && !Number.isNaN(parseInt(limit, 10)) ? `&limit=${limit}` : '';
    const offsetQuery =
      offset && !Number.isNaN(parseInt(offset, 10)) ? `&offset=${offset}` : '';

    json = await getMagnoliaItem({
      caller:
        'src/helpers/dataFetchers/mediaDetailFetcher.js > fetchMediaItemEarlyChildhoodBased',
      mgnlEnvVars: MGNL_ENV_VARS,
      path: encodeURI(
        `${MAGNOLIA_ENDPOINTS.delivery.mediaItems}?@ancestor=/${collection}&availablePlatforms[eq]=${WEB_DISTRIBUTION_PLATFORM_UUID}${limitQuery}${offsetQuery}&orderBy=startDate desc`,
      ),
      workspaceMap: ENDPOINT_WORKSPACE_MAP,
    }); // NOSONAR

    pastMediaList = json?.results ?? [];
  }

  const [labels] = await Promise.all([
    getLabelList({
      ancestor: `media_listing_more_content_labels`,
      mgnlEnvVars: MGNL_ENV_VARS,
      workspaceMap: ENDPOINT_WORKSPACE_MAP,
    }),
  ]);

  const pagination = {
    limit: json?.limit ?? 0,
    offset: json?.offset ?? 0,
    total: json?.total ?? 0,
  };

  if (pagination.offset === 0 && pastMediaList.length >= resultsLengthCap) {
    pagination.next = resultsLengthCap;
  } else if (pagination.offset === 0) {
    pagination.next = pastMediaList.length;
  } else {
    pagination.next = pagination.offset + pastMediaList.length;
  }

  return { labels, pagination, pastMediaList };
}

/**
 * Data fetcher for media item data.
 *
 * @param {object} props - The media item props data object.
 *
 * @returns {object} The fetched media item data.
 */
async function fetchMediaItem(props) {
  const params = window?.location?.search
    ? window.location.search
        .slice(1)
        .split('&')
        .map((p) => p.split('='))
        .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {})
    : false;

  const hasPreviewSlug = params?.previewSlug;
  const path = encodeURI(
    `${MAGNOLIA_ENDPOINTS.delivery.mediaItems}${
      hasPreviewSlug
        ? `/${params.previewSlug.toLowerCase()}`
        : `?@jcr:uuid=${
            props?.mediaID ?? props?.['@id']
          }&availablePlatforms[eq]=${WEB_DISTRIBUTION_PLATFORM_UUID}`
    }`,
  );

  const data = await getMagnoliaItem({
    caller: 'src/helpers/dataFetchers/mediaDetailFetcher.js > fetchMediaItem',
    mgnlEnvVars: MGNL_ENV_VARS,
    path,
    workspaceMap: ENDPOINT_WORKSPACE_MAP,
  }); // NOSONAR
  const dataToConvert = hasPreviewSlug ? data : data?.results?.[0];
  const media =
    dataToConvert && Object.keys(dataToConvert).length
      ? convertS3DamItems(dataToConvert, process.env.IMGIX_BASE_URL)
      : null;

  let collectionData = null;
  const mediaCollectionName = media?.['@path']?.split('/')?.[2];

  if (mediaCollectionName) {
    const collectionResults = await getMagnoliaItem({
      caller: 'src/helpers/dataFetchers/mediaDetailFetcher.js > fetchMediaItem',
      mgnlEnvVars: MGNL_ENV_VARS,
      path: encodeURI(
        `${MAGNOLIA_ENDPOINTS.delivery.mediaCollections}?slug=${mediaCollectionName}&limit=1`,
      ),
      workspaceMap: ENDPOINT_WORKSPACE_MAP,
    });
    const collection = collectionResults?.results?.[0];

    if (collection?.['@id']) {
      collectionData = collection;
    }
  }

  if (media?.['@id']) {
    const mediaItemType = media['@path'].split('/')[1];
    let tempType = '';

    switch (mediaItemType) {
      case 'messages':
      case 'weekend-messages':
      case 'lifegroups':
      case 'switch':
      case 'loop':
        tempType = MEDIA_TYPES.SERIES;
        break;
      case 'worship':
        tempType = MEDIA_TYPES.SONG;
        break;
      case 'stories':
      case 'konnect':
      case 'crosstown':
        tempType = MEDIA_TYPES.EPISODE;
        break;
      case 'leadershippodcast':
      case 'leadership-podcast':
        tempType = MEDIA_TYPES.PODCAST;
        break;
      case 'early-childhood':
        tempType = MEDIA_TYPES.EARLY_CHILDHOOD;
        break;
      default:
        break;
    }

    const [tempArr] = await Promise.all([
      getLabelList({
        ancestor: `media_item_${tempType}`,
        mgnlEnvVars: MGNL_ENV_VARS,
        workspaceMap: ENDPOINT_WORKSPACE_MAP,
      }),
    ]);

    // Add pagination attributes to the collection object for fetcher below.
    media.limit = props?.limit;
    media.next = props?.next ?? 0;
    media.offset = props?.offset ?? 0;

    let mediaTypeSpecificData = {};

    switch (tempType) {
      case MEDIA_TYPES.SERIES:
        mediaTypeSpecificData = await fetchMediaItemSeriesBased(media); // NOSONAR
        break;
      case MEDIA_TYPES.SONG:
        mediaTypeSpecificData = await fetchingSongList(media); // NOSONAR
        break;
      case MEDIA_TYPES.EPISODE:
        mediaTypeSpecificData = await fetchMediaItemEpisodeBased(media); // NOSONAR
        break;
      case MEDIA_TYPES.PODCAST:
        mediaTypeSpecificData = await fetchMediaItemPodcastBased(media); // NOSONAR
        break;
      case MEDIA_TYPES.EARLY_CHILDHOOD:
        mediaTypeSpecificData = await fetchMediaItemEarlyChildhoodBased(media); // NOSONAR
        break;
      default:
        break;
    }

    return {
      collectionData,
      labelList: tempArr || [],
      mediaItemData: media,
      mediaTypeSpecificData,
      pagination: mediaTypeSpecificData.pagination,
      type: tempType,
    };
  }
  return null;
}

export { fetchMediaItem };
