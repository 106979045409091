/**
 * @module Constants
 */

// Environment
export const ENVIRONMENT = {
  dev: process.env.NODE_ENV === 'development',
  development: process.env.NODE_ENV === 'development',
  prod: process.env.NODE_ENV === 'production',
  production: process.env.NODE_ENV === 'production',
  review: process.env.NODE_ENV === 'review',
  staging: process.env.NODE_ENV === 'staging',
  test:
    process.env.NODE_ENV === 'test' ||
    (process.env.IS_JEST_ENV && process.env.IS_JEST_ENV.toString() === 'true'),
};

// Log Configuration
export const LOG_CONFIG = {
  colorize:
    process.env.LOG_OPTIONS_COLORIZE &&
    process.env.LOG_OPTIONS_COLORIZE.toString() === 'true',
  includeTimestamp:
    process.env.LOG_OPTIONS_INCLUDE_TIMESTAMP &&
    process.env.LOG_OPTIONS_INCLUDE_TIMESTAMP.toString() === 'true',
  label: process.env.LOG_OPTIONS_LABEL || 'Magnolia React Client',
  printOutput:
    (process.env.LOG_OPTIONS_PRINT_OUTPUT &&
      process.env.LOG_OPTIONS_PRINT_OUTPUT.toString() === 'true') ||
    !ENVIRONMENT.production,
  showIcons:
    process.env.LOG_OPTIONS_SHOW_ICONS &&
    process.env.LOG_OPTIONS_SHOW_ICONS.toString() === 'true',
};

// -----------------------------------------------------------------------------
//  Start: Magnolia Helpers
// -----------------------------------------------------------------------------

export const MGNL_ENV_VARS = {
  REACT_APP_CMS: process.env.REACT_APP_CMS,
  REACT_APP_MGNL_API_CONTENT_TYPE: process.env.REACT_APP_MGNL_API_CONTENT_TYPE,
  REACT_APP_MGNL_API_NAV: process.env.REACT_APP_MGNL_API_NAV,
  REACT_APP_MGNL_API_PAGES: process.env.REACT_APP_MGNL_API_PAGES,
  REACT_APP_MGNL_API_TEMPLATES: process.env.REACT_APP_MGNL_API_TEMPLATES,
  REACT_APP_MGNL_APP_BASE: process.env.REACT_APP_MGNL_APP_BASE,
  REACT_APP_MGNL_AUTHOR: process.env.REACT_APP_MGNL_AUTHOR,
  REACT_APP_MGNL_BASE_AUTHOR: process.env.REACT_APP_MGNL_BASE_AUTHOR,
  REACT_APP_MGNL_BASE_PUBLIC: process.env.REACT_APP_MGNL_BASE_PUBLIC,
  REACT_APP_MGNL_DAM_RAW: process.env.REACT_APP_MGNL_DAM_RAW,
  REACT_APP_MGNL_HOST: process.env.REACT_APP_MGNL_HOST,
  REACT_APP_MGNL_IS_PREVIEW: process.env.REACT_APP_MGNL_IS_PREVIEW,
  REACT_APP_MGNL_LANGUAGES: process.env.REACT_APP_MGNL_LANGUAGES,
  REACT_APP_MGNL_STATIC: process.env.REACT_APP_MGNL_STATIC,
};

export const ENDPOINT_WORKSPACE_MAP = {
  '/.rest/delivery/careersMenu': 'careersMenu',
  '/.rest/delivery/distributionPlatform': 'distributionPlatform',
  '/.rest/delivery/featuredNextStepCard': 'featuredNextStepCard',
  '/.rest/delivery/footer': 'footer',
  '/.rest/delivery/globalLocationBanner': 'globalLocationBanner',
  '/.rest/delivery/homepageHero': 'homepageHero',
  '/.rest/delivery/labeldictionary': 'labeldictionary',
  '/.rest/delivery/location': 'location',
  '/.rest/delivery/mediaPerson': 'mediaPerson',
  '/.rest/delivery/mediav2': 'media_v2',
  '/.rest/delivery/mediaV2': 'media_v2',
  '/.rest/delivery/menu': 'menu',
  '/.rest/delivery/messageNotes': 'messageNotes',
  '/.rest/delivery/modal': 'modal',
  '/.rest/delivery/nextStepCard': 'nextStepCard',
  '/.rest/delivery/pagenav/v1': 'website',
  '/.rest/delivery/pages/v1': 'website',
  '/.rest/delivery/servingOpportunities': 'servingOpportunities',
  '/.rest/delivery/specialEvent': 'specialEvent',
  '/.rest/delivery/tag': 'tag',
  '/.rest/delivery/talkItOver': 'talkItOver',
  '/.rest/delivery/tasks': 'tasks',
  '/.rest/delivery/v1/collections': 'media_v2',
  '/.rest/delivery/v1/items': 'media_v2',
  '/.rest/mediaV2': 'media_v2',
  mediaV2: 'media_v2',
  mediav2: 'media_v2',
};

// -----------------------------------------------------------------------------
//  End: Magnolia Helpers
// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
//  Start: Analytics Tracking
// -----------------------------------------------------------------------------

// Categories
export const CATEGORIES = {
  appDownload: 'App Download',
  compositeButtonGroup: 'Composite Button Group',
  downloadLeaderGuide: 'Download Leader Guide',
  header: 'Header',
  latestMessage: 'Latest Message',
  launched: 'Launched',
  lifeChurchIsForYou: 'Life.Church is For You',
  lifeChurchIsForYourCommunity: 'Life.Church is For Your Community',
  lifeChurchIsForYourFamily: 'Life.Church is For Your Family',
  locationInformation: 'Location Information',
  locationModal: 'Location Modal',
  podcastFilter: 'Podcast Filter',
  prelaunch: 'Prelaunch',
  serviceTimes: 'Service Times',
  stickyBar: 'Sticky Bar',
  subscribeLeaderGuide: 'Subscribe to Leader Guide',
  switch: 'Switch',
  whatToExpect: 'What to Expect',
};

// Actions
export const ACTIONS = {
  changed: 'Changed',
  clicked: 'Clicked',
  closed: 'Closed',
  collapsed: 'Collapsed',
  expanded: 'Expanded',
  formSubmit: 'Form Submit',
  opened: 'Opened',
  paused: 'Paused',
  played: 'Played',
  selected: 'Selected',
  stopped: 'Stopped',
  tapped: 'Tapped',
  toggledOff: 'Toggled Off',
  toggledOn: 'Toggled On',
};

// Events
export const EVENTS = {
  buttonAction: 'Button Action',
  buttonClick: 'Button Click',
  downloadLeaderGuide: 'Download Leader Guide',
  leadershipPodcastSubscribeFormSubmit:
    'Leadership Podcast Subscribe Form Submit',
  linkClick: 'Link Click',
  mediaPlayed: 'Media Played',
  menuItemSelect: 'Menu Item Select',
  multiStep: {
    buttonClick: 'Button Tap',
    signUpFinished: 'Form Submitted',
    signUpStarted: 'Form Started',
  },
  pageScrolled: 'Page Scrolled',
  playMedia: 'Play Media',
};

// Screens
export const SCREENS = {
  multiStep: {
    basicInfo: 'Sign Up to Serve Form',
    contactInfo: 'Contact Info Form',
    errorScreen: 'Error Screen',
    location: 'Location Form',
    ministryTeam: 'Ministry Team Form',
    thankYou: 'Thank You Form',
  },
  podcastDetailPage: 'Leadership Podcast Detail Page',
  podcastListingPage: 'Leadership Podcast Listing Page',
};

// Parameters
export const PARAMETERS = {
  mediaTypes: {
    video: 'video',
  },
  multiStep: {
    formName: 'Serving',
  },
};

// -----------------------------------------------------------------------------
//  End: Analytics Tracking
// -----------------------------------------------------------------------------

/**
 * Authentication (Auth0) Routes. Note that this includes an array of URLs that
 * have both trailing slash and non-trailing slash values, as Fastly applies the
 * slash for production but it isn't always present for Staging or localhost.
 */
export const AUTH_ROUTES = [
  '/auth/auth0',
  '/auth/auth0/',
  '/auth/auth0/callback',
  '/auth/auth0/callback/',
  process.env.AUTH_CALLBACK_URL,
  '/logout',
  '/logout/',
  '/orgsignup',
  '/orgsignup/',
];

/**
 * Smart app URL Routes, used to auto-detect a device based on browser userAgent
 * and forward on to the appropriate OS-specific app store.
 */
export const SMART_APP_DOWNLOAD_ROUTES = [
  '/app/download',
  '/app/download/',
  process.env.SMART_APP_DOWNLOAD_URL,
];

// Locations Visibility Items
export const LOCATIONS = {
  hide: {
    community: process.env.LOCATIONS_HIDE_COMMUNITY?.split(',') ?? [],
    lifeKids: process.env.LOCATIONS_HIDE_LIFEKIDS?.split(',') ?? [],
    map: process.env.LOCATIONS_HIDE_MAP?.split(',') ?? [],
  },
  launchedV2: process.env.LOCATIONS_LAUNCHED_V2?.split(',') ?? [],
  liveInYourCity: process.env.LOCATIONS_LIVE_IN_YOUR_CITY?.split(',') ?? [],
  prelaunchedV2: process.env.LOCATIONS_PRELAUNCHED_V2?.split(',') ?? [],
};

// RegExp
export const REGEXP = {
  multiSlash: /\/+/g,
  multiSlashSaveUrlProtocol: /(?<!:)\/+/g,
};

// Status Types
export const STATUS_TYPES = {
  ERROR: 'error',
  IDLE: 'idle',
  INFO: 'info',
  PENDING: 'pending',
  REJECTED: 'rejected',
  RESOLVED: 'resolved',
  SUCCESS: 'success',
  WARNING: 'warning',
};

// Pagination
export const PAGINATION_OPTIONS = {
  accordionData: {
    limit: 10,
    offset: 0,
  },
  mediaCollection: {
    limit: 12,
    offset: 0,
  },
  mediaCollectionListingFetcher: {
    limit: 12,
    offset: 0,
  },
  mediaDetail: {
    limit: 12,
    offset: 0,
  },
};

// Window Breakpoints
export const BREAKPOINTS = {
  large: 1024,
  medium: 768,
  small: 640,
  xLarge: 1200,
};

export const MEDIA_TRACKING = {
  segment: {
    events: {
      timeTriggers: {
        3: '3',
        10: '10',
        30: '30',
        60: '60',
        start: '0',
      },
    },
  },
};

// Magnolia Delivery API
export const WEB_DISTRIBUTION_PLATFORM_UUID =
  '49b543e6-bcef-4242-b273-363e87d5b0a3';

// Algolia Search
export const ALGOLIA = {
  apiKey: process.env.ALGOLIA_API_KEY,
  appId: process.env.ALGOLIA_APP_ID,
};

// Hubspot
export const HUBSPOT = {
  formIds: {
    getUpdates: '643f0cf1-8238-4cf1-8706-f92860dfa0ca',
    podcastCollectionContentForm: '76a971af-d87a-4f27-b0a2-bd42f650cee1',
  },
};

// Media Types
export const MEDIA_TYPES = {
  ALBUM_SONG: 'album_song',
  EARLY_CHILDHOOD: 'early-childhood',
  EPISODE: 'episode',
  PODCAST: 'podcast',
  SERIES: 'series',
  SONG: 'song',
};

// Magnolia endpoints
export const MAGNOLIA_ENDPOINTS = {
  delivery: {
    mediaCollections: '/.rest/delivery/v1/collections',
    mediaItems: '/.rest/delivery/v1/items',
  },
};

// Themes
export const THEMES = {
  contentOnly: 'content_only',
};

// Careers
export const CAREER_TEAMS = {
  'Business Operations': {
    cover_url: 'careers/teams/BusStrat_Lg.jpg',
    description:
      'At Life.Church, we know that people are the most important resource as we pursue our mission. That’s why it’s crucial to bring in, train up, and empower Life.Church staff with the data, tools, and finances they need to do ministry powerfully. Our business operations teams play a major role in making Life.Church happen.',
    id: 'business-operations',
    thumbnail_url: 'careers/teams/BusStrat_Sm.jpg',
  },
  'Creative, Content, Communications': {
    cover_url: 'careers/teams/CMComm_Lg.jpg',
    description:
      'Quality content isn’t an accident. It’s what happens when a team of talented people collaborate, chase an idea, and create something truly special. And it’s what our Creative Content and Communications teams do every single day. From filmmaking and writing to social media and marketing, find your creative fit in one of our open roles.',
    id: 'creative-content-communications',
    thumbnail_url: 'careers/teams/CMComm_Sm.jpg',
  },
  'Digital Technology': {
    cover_url: 'careers/teams/Tech_Lg.jpg',
    description:
      'Our technology teams don’t just build the systems that make Life.Church tick—they build relationships that help us thrive. If you step into a tech role here, you’ll do more than answer help tickets, troubleshoot hardware and software, or manage projects. You’ll turn ideas into reality.',
    id: 'digital-technology',
    thumbnail_url: 'careers/teams/Tech_Sm.jpg',
  },
  Internships: {
    cover_url: 'careers/teams/Internships_Lg.jpg',
    description:
      'Our focus is on developing each intern spiritually, personally, and professionally. Internships are available to young adults throughout the year and in most Life.Church ministry and Central support areas.',
    id: 'internships',
    thumbnail_url: 'careers/teams/Internships_Sm.jpg',
  },
  'Location Support': {
    cover_url: 'careers/teams/Locations_Lg.jpg',
    description:
      'With warehouses, Central Support, and dozens of physical locations, efficiency is key at Life.Church. As our church grows, our location operations teams keep new and existing Life.Church properties safe, secure, and on schedule.',
    id: 'location-support',
    thumbnail_url: 'careers/teams/Locations_Sm.jpg',
  },
  Ministry: {
    cover_url: 'careers/teams/Ministry_Lg.jpg',
    description:
      'When you join the Life.Church staff at one of our locations, you won’t just be a new hire—you’ll be a leader we can’t wait to empower, a learner we can’t wait to teach, and a follower of Christ we can’t wait to grow alongside. If you’re interested in pastoring on our Host Team, Operations Team, LifeGroups/LifeMissions Team, Worship Team, or with kids and youth, these are the roles for you. These ministry roles are only available at physical Life.Church locations.',
    id: 'ministry',
    thumbnail_url: 'careers/teams/Ministry_Sm.jpg',
  },
  'Staff Childcare': {
    cover_url: 'careers/teams/StaffKids_Lg.jpg',
    description:
      'As Life.Church grows, so does our staff family—and their families, too! We provide weekday childcare to our youngest (unofficial) team members, from birth all the way up to pre-K. If you’re interested in playing a part in raising up the next generation of Life.Church leaders, check out our full-time, part-time, and temporary childcare roles.',
    id: 'staff-childcare',
    thumbnail_url: 'careers/teams/StaffKids_Sm.jpg',
  },
  YouVersion: {
    cover_url: 'careers/teams/YV_Lg.jpg',
    description:
      'On the YouVersion Team, you’ll combine the skills God has given you with your passion to encourage and challenge people to seek intimacy with God every day—no matter where they are or what languages they speak. We’re not just building an app. We’re creating new ways to explore God’s Word, helping people build life-transforming daily habits.',
    id: 'youversion',
    thumbnail_url: 'careers/teams/YV_Sm.jpg',
  },
};

export const CONSENT_MANAGER_CONFIG = {
  footerLinkCTA: 'Cookie Preferences',
};

export const CONSENT_MANAGER_THIRD_PARTY_SERVICES = {
  google: {
    destinations: ['Google Analytics', 'Google Analytics 4 Web'],
    enabled: false,
    markups: {
      googleOptimizeScript: {
        src: 'https://www.googleoptimize.com/optimize.js?id=OPT-TRHQ9SW',
      },
      leadershipPodcastAdTracking: {
        content: `
          window.addEventListener('load', function(){
            document.addEventListener('click', function(event){
              if(event.target.closest('a') && event.target.closest('a').innerText == 'Watch the Episode'){
                gtag('event', 'conversion', {'send_to': 'AW-858021753/IiAvCM7Lnc8DEPm-kZkD'});
              }
              if(event.target.closest('button') && event.target.closest('button').innerText == 'Download Leader Guide'){
                gtag('event', 'conversion', {'send_to': 'AW-858021753/R7q7CKqT784DEPm-kZkD'});
              }
            });
          });
        `,
      },
    },
    name: 'Google',
    scriptSrcUrls: ['googleoptimize.com', 'googletagmanager.com'],
  },
};

export const LOCAL_STORAGE_KEYS = {
  auth0: {
    redirectOriginParams: 'LC:AUTH:RedirectOriginParams',
  },
  consentManager: {
    gtmConsent: 'LC:CM:GtmConsent',
    userPreferences: 'LC:CM:ConsentPreferences:Main',
  },
};

// Special Events
export const SPECIAL_EVENTS = {
  fallFest: '75ecc696-040d-42ff-9d99-2387af53c8d4',
};

// Labels dictionary defaults and fallbacks.
export const LABELS = {
  lco_on_demand_ehoth: [
    {
      name: 'title_starts_soon',
      value: 'Life.Church Online Starts Soon',
    },
    {
      name: 'title_live_now',
      value: 'Life.Church Online is Live Now!',
    },
    {
      name: 'cta_url',
      value: 'https://live.life.church/',
    },
    {
      name: 'cta_label',
      value: 'Join Now',
    },
    {
      name: 'countdown_display_threshold_show_countdown',
      value: '45',
    },
    {
      name: 'countdown_display_threshold_hide_countdown',
      value: '0',
    },
    {
      name: 'countdown_display_threshold_hide_all',
      value: '15',
    },
    {
      name: 'badge_label',
      value: 'Live Now',
    },
    {
      name: 'start',
      value: `${process.env.LCO_ON_DEMAND_EHOTH_START}`,
    },
    {
      name: 'end',
      value: `${process.env.LCO_ON_DEMAND_EHOTH_END}`,
    },
    {
      name: 'days',
      value: `${process.env.LCO_ON_DEMAND_EHOTH_DAYS}`,
    },
    {
      name: 'is_enabled',
      value: `${process.env.LCO_ON_DEMAND_EHOTH_ENABLED}`,
    },
    {
      name: 'timer_label_minutes',
      value: 'Minutes',
    },
    {
      name: 'timer_label_seconds',
      value: 'Seconds',
    },
    {
      name: 'bg_image_desktop',
      value:
        'pages/LCO/on-demand-banner/lco-on-demand-ehoth-banner-desktop.png',
    },
  ],
};

// Distances
export const MINIMUM_DISTANCE_KM = 80.5;

// Video Orientation
export const VIDEO_PORTRAIT_WIDTH = 33;
export const VIDEO_ORIENTATION = {
  LANDSCAPE: 'landscape',
  PORTRAIT: 'portrait',
};
