/**
 * @module Carousel
 */
import React from 'react';
import { EditorContextHelper } from '@magnolia/react-editor';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import { truncate } from '@lifechurch/web-tools-io/dist/utils/helpers/truncate';
import { convertValueToClassName } from '@lifechurch/web-tools-io/dist/utils/helpers/validators';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import useWindowSize from '@lifechurch/web-tools-io/dist/hooks/useWindowSize';
import LCImage from '../LCImage/LCImage';
import useModals from '../../hooks/useModals';
import RichText from '../RichText/RichText';
import { ACTIONS, ENVIRONMENT, EVENTS } from '../../helpers/constants';
import './Carousel.scss';

const truncateOptions = { breakAtSpace: true };

const Carousel = ({
  alt,
  content,
  image,
  linkaction,
  modalTrigger,
  slideWidth,
  target,
  title,
  type,
  url,
  urllink,
  urltarget,
}) => {
  const { user } = useAuth();
  const { addModalTrigger, showModal } = useModals();
  const { isMobile, isTablet } = useWindowSize();
  const isDevMode = EditorContextHelper.inIframe();
  const Tag = url || urllink ? 'a' : 'div';
  const htmlAlt = alt || image?.metadata?.caption || '';
  const htmlAttributes = { alt: htmlAlt };
  const imgixParams =
    type === 'text-carousel' ? { ar: '1:1', fit: 'crop' } : {};
  const isModalTrigger = Boolean(linkaction) && linkaction === 'modaltrigger';
  const hash = !!title && title.replace(/\s+/g, '-').toLowerCase();
  const [titleLimit, setTitleLimit] = React.useState(0);
  const [contentLimit, setContentLimit] = React.useState(0);
  const [finalContent, setFinalContent] = React.useState(
    truncate(content, 320, truncateOptions),
  );
  const [finalTitle, setFinalTitle] = React.useState(
    truncate(title, 30, truncateOptions),
  );

  // Keeping backwards compatibility with old URL props
  const finalUrl = urllink || url;
  const finalTarget = urltarget || target;

  const containerClass = `carousel-item ${convertValueToClassName(type)} ${
    isDevMode ? 'in-dev-mode' : ''
  } ${isModalTrigger ? 'modal-item' : ''} ${Tag === 'a' ? 'link' : ''}`.trim();

  /**
   * Single-run convenience effect to add modal trigger if present.
   */
  React.useEffect(() => {
    if (isModalTrigger) {
      addModalTrigger(modalTrigger);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Convenience function to check and set width and text truncation, if needed.
   * Note: The ignore directives are only due to the fact that titleLimit and
   * contentLimit should always not equal their respective max values on first
   * render and check, making test coverage for these cases unnecessary to have
   * the values be equal. Solution and skipping is agreeable and acceptable.
   */
  const checkWidth = () => {
    const largeMaxTitle = isTablet ? 48 : 86;
    const maxTitle = isMobile ? 30 : largeMaxTitle;
    const largeMaxContent = isTablet ? 400 : 600;
    const maxContent = isMobile ? 320 : largeMaxContent;

    /* istanbul ignore next */
    if (titleLimit !== maxTitle) {
      setTitleLimit(maxTitle);
      setFinalTitle(truncate(title, maxTitle, truncateOptions));
    }
    /* istanbul ignore next */
    if (contentLimit !== maxContent) {
      setContentLimit(maxContent);
      setFinalContent(truncate(content, maxContent, truncateOptions));
    }
  };

  React.useEffect(() => {
    checkWidth();
    function handleResize() {
      checkWidth();
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // Note: Adding dependencies for hook-related state items so resize logic
    // for determining/setting image source is properly utilized and set.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, isTablet]);

  const checkIfAlbum = () => {
    const w = parseInt(slideWidth, 10);
    return w === 131 || w === 145 || w === 214;
  };

  /**
   * Handler function for button click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  const handleButtonClick = (event) => {
    if (event?.target?.preventDefault && ENVIRONMENT.test) {
      event.preventDefault();
    }
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Carousel',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    if (isModalTrigger) {
      showModal(modalTrigger, true);
      window.location.hash = hash;
    }
  };

  /**
   * Handler function for link click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  const handleLinkClicked = (event) => {
    if (isModalTrigger || !finalUrl || finalUrl === '#') {
      event.preventDefault();
    }
    handleButtonClick(event);
  };

  return (
    <Tag
      className={containerClass}
      data-testid="lc-carousel"
      href={finalUrl}
      onClick={handleLinkClicked}
      target={finalTarget || '_self'}
    >
      {image ? (
        <div
          className="carousel-item-image-container"
          data-testid="carousel-item-image"
        >
          <LCImage
            backup={checkIfAlbum() ? 'album-img' : 'cinema-img'}
            className="carousel-item-image"
            htmlAttributes={htmlAttributes}
            imgixParams={imgixParams}
            src={`${image['@id'] || image['@link'] || image}`}
            width={type === 'text-carousel' ? 500 : parseInt(slideWidth, 10)}
          />
        </div>
      ) : null}
      {
        /* istanbul ignore next */ !!isDevMode && type !== 'text-carousel' ? (
          <h2 className="title text-weight-bold">{finalTitle}</h2>
        ) : null
      }
      {type === 'text-carousel' ? (
        <div className="carousel-item-content">
          {finalTitle ? (
            <h2
              className="carousel-item-title text-weight-bold"
              data-testid="carousel-item-title"
            >
              {finalTitle}
            </h2>
          ) : null}
          {finalContent ? (
            <RichText
              content={finalContent}
              data-testid="carousel-item-content"
            />
          ) : null}
        </div>
      ) : null}
    </Tag>
  );
};

export default Carousel;
