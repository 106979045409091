/**
 * @module CompositeButtonGroup
 */
import React from 'react';
import {
  callGtagEvent,
  callSegmentTrack,
} from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import { titleCase } from '@lifechurch/web-tools-io/dist/utils/helpers/titleCase';
import { convertValueToClassName } from '@lifechurch/web-tools-io/dist/utils/helpers/validators';
import useWindowSize from '@lifechurch/web-tools-io/dist/hooks/useWindowSize';
import { v4 as uuidv4 } from 'uuid';
import ButtonItem from '../ButtonItem/ButtonItem';
import { ACTIONS, CATEGORIES, EVENTS } from '../../helpers/constants';
import './CompositeButtonGroup.scss';

const CompositeButtonGroup = ({
  alignment,
  items,
  layout,
  // eslint-disable-next-line no-unused-vars
  metadata,
  sbOnMobile,
  sbOnTabletAndUp,
  sectionId,
  type,
}) => {
  const { user } = useAuth();
  const { isMobile } = useWindowSize();
  const buttonGroupId = uuidv4();
  const nodeLength = items?.['@nodes']?.length ?? 0;
  const nodeNames = items?.['@nodes'] || [];
  const nodes = nodeNames.map((nodeName) => items[nodeName]);

  const containerClass = `button-group-wrapper container ${
    isMobile
      ? convertValueToClassName(sbOnMobile)
      : convertValueToClassName(sbOnTabletAndUp)
  }`.trim();
  const itemListClass = `button-item-listing ${convertValueToClassName(type)} ${
    nodeLength && nodeLength === 1
      ? 'btn-width-auto'
      : `${convertValueToClassName(layout)}`
  } ${convertValueToClassName(alignment)}`.trim();

  if (type !== 'buttons-group') {
    let btn_item_style = '';
    switch (type) {
      case 'channel-buttons-group':
        btn_item_style = 'btn-channel';
        break;
      case 'img-buttons-group':
        btn_item_style = 'image-link';
        break;
      case 'share-links-group':
        btn_item_style = 'share-link';
        break;
      default:
        break;
    }
    // Set style to each btn item.
    if (nodes.length) {
      nodes.forEach((element) => {
        // eslint-disable-next-line no-param-reassign
        element.style = btn_item_style;
      });
    }
  }

  function normalizeGroupType(typeValue) {
    return titleCase(typeValue.replace(/-/g, ' '));
  }
  /* istanbul ignore next*/
  const checkBtnGroup = () => {
    const ele = document.querySelector(
      `.buttons-group#btn-listing-${buttonGroupId} > div`,
    );
    if (ele && !isMobile && nodeLength > 1) {
      const btns = [...ele.querySelectorAll('.btn, .anchor-link')];
      if (btns.length) {
        const heights = btns.map((item) => item.clientHeight);
        if (new Set(heights).size > 1) {
          const maxHeight = Math.max.apply(null, heights);
          btns.forEach((item) => {
            // eslint-disable-next-line no-param-reassign
            item.style.height = `${maxHeight}px`;
          });
        }
      }
    }
  };

  React.useEffect(() => {
    checkBtnGroup();
    /* istanbul ignore next*/
    function handleResize() {
      checkBtnGroup();
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // Note: Adding dependencies for hook-related state items so resize logic
    // for determining/setting image source is properly utilized and set.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  function handleInPageLinkClick(event, url) {
    event.preventDefault();
    /* istanbul ignore next */
    if (typeof window !== 'undefined') {
      const element = document.querySelector(url);
      if (element) {
        const elementOffset = element.offsetTop;
        window.scrollTo({
          behavior: 'smooth',
          top: elementOffset,
        });
      }
    }
  }

  /**
   * Handler function for ButtonItem click. The default logic for this event
   * will fire off a gtag event.
   *
   * @param {Event} event - The Event object associated with the click event.
   * @param {string} url - The url that will be used to redirect to another page or as a in-page link.
   */
  function onButtonItemClick(event, url) {
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: `${CATEGORIES.compositeButtonGroup} - ${normalizeGroupType(
          type,
        )}`,
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });

    // Note: try/catch logic included within `callGtagEvent`.
    /* istanbul ignore next */
    callGtagEvent({
      parameters: {
        event_category: `${
          CATEGORIES.compositeButtonGroup
        } - ${normalizeGroupType(type)}`,
        event_label: `${event.currentTarget.textContent}`,
      },
      type: 'click',
    });

    if (url?.startsWith('#') && url?.length > 1) {
      handleInPageLinkClick(event, url);
    }
  }

  return (
    <div
      className={containerClass}
      data-testid="composite-button-group"
      id={sectionId}
    >
      <div className={itemListClass} id={`btn-listing-${buttonGroupId}`}>
        {items
          ? nodes.map((item) => (
              <ButtonItem
                buttonSize={item.buttonSize}
                darkVariant={item.darkVariants}
                icon={item.icon}
                iconPosition={item.icon?.iconPosition}
                image={item.image}
                key={item['@id']}
                modalTrigger={item.modalTrigger}
                onClick={(event) => onButtonItemClick(event, item.url)}
                style={item.style}
                target={item.target}
                text={item.text}
                url={item.url}
              />
            ))
          : null}
      </div>
    </div>
  );
};

export default CompositeButtonGroup;
