/**
 * @module Tabs
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import './Tabs.scss';

/**
 * Represents a set of tabs.
 *
 * @param {object} props - The component props object.
 * @param {string} props.activeTab - The id of the active tab.
 * @param {Function} props.onClick - The callback function triggered when a tab is clicked.
 * @param {Array<Tab>} props.tabs - The array of Tab objects.
 *
 * @returns {React.ReactElement} The Tabs component.
 */
export default function Tabs({ activeTab, onClick, tabs }) {
  /**
   * Handler function for tab click event.
   *
   * @param {Event} event - The Event object associated with the click.
   * @param {string} tabId - The unique id value of the clicked tab.
   */
  function handleOnTabClick(event, tabId) {
    onClick(event, tabId);
  }

  return tabs?.length ? (
    <div className="tabs" role="tablist">
      {tabs.map((tab) =>
        tab?.title && tab?.id ? (
          <button
            aria-selected={activeTab === tab.id}
            className="tab"
            id={tab.id}
            key={tab.id}
            onClick={(event) => handleOnTabClick(event, tab.id)}
            role="tab"
          >
            {tab.title}
          </button>
        ) : null,
      )}
    </div>
  ) : null;
}
