/**
 * @module ContentAndForm
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import ButtonItem from '../ButtonItem/ButtonItem';
import Hubspot from '../EmbedItem/Hubspot/Hubspot';
import SocialMediaShare from './SocialMediaShare';
import { ACTIONS, EVENTS } from '../../helpers/constants';

/**
 * Component for showing the information and additional resources of the media collection.
 *
 * @param {object} props - The component props object.
 * @param {object} props.collectionData - The data of the media collection.
 * @param {object} props.data - The data of the media element.
 * @param {boolean} [props.showForm] - Boolean flag indicating whether or not to show the form.
 * @param {boolean} [props.showSocialShare] - Boolean flag indicating whether or not to show social media share icons.
 * @param {boolean} [props.showCollectionTitle] - Boolean flag indicating whether or not to show the collection title.
 * @param {boolean} [props.showDescription] - Boolean flag indicating whether or not to show the description.
 * @param {Function} [props.onTalkItOverClick] - The callback to open the TalkIt Over section.
 *
 * @returns {React.ReactElement} The ContentAndForm component.
 */
function ContentAndForm({
  collectionData = {},
  data,
  showForm = false,
  showSocialShare = true,
  showCollectionTitle = false,
  showDescription = true,
  onTalkItOverClick,
}) {
  const {
    description,
    headline,
    hubspotFormID,
    hubspotStyle,
    talkItOver,
    title,
  } = data;

  const { title: collectionTitle } = collectionData;
  const { user } = useAuth();

  const additionalResources =
    collectionData.additionalResources ?? data.additionalResources ?? null;
  const biblePlanURL = collectionData.biblePlanURL ?? data.biblePlanURL ?? '';
  const openURL = collectionData.openURL ?? data.openURL ?? '';

  /**
   * Handler function for button click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleButtonClick(event) {
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Media - Content and Form',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  return (
    <div
      className="content-and-form container mb-bit_more_relaxed"
      data-testid="lc-content-and-form"
    >
      <div className="content-wrapper">
        <h2 className="content-title mt-none" itemProp="headline">
          {headline || title}
        </h2>
        {showCollectionTitle && collectionTitle ? (
          <p className="content-collection-title my-none" itemProp="isPartOf">
            {collectionTitle}
          </p>
        ) : null}
        {showDescription && description ? (
          <p
            className="content-text mt-more_slight_tight"
            itemProp="description"
          >
            {description}
          </p>
        ) : null}

        {(talkItOver && Object.keys(talkItOver).length) ||
        biblePlanURL ||
        openURL ||
        additionalResources ? (
          <div
            className={`button-group-wrapper resources-links ${
              showSocialShare ? 'mb-normal' : ''
            }`}
          >
            <h3 className="content-subtitle">Additional Resources</h3>
            <ul className="button-item-listing flex-group flex-row left-align">
              {talkItOver && onTalkItOverClick ? (
                <ButtonItem
                  buttonSize="small"
                  onClick={(event) => {
                    onTalkItOverClick(event);
                    handleButtonClick(event);
                  }}
                  style="btn-secondary"
                  text="Talk It Over"
                />
              ) : null}
              {biblePlanURL ? (
                <ButtonItem
                  buttonSize="small"
                  onClick={handleButtonClick}
                  style="btn-secondary"
                  target="_blank"
                  text="Bible Plan"
                  url={biblePlanURL}
                />
              ) : null}
              {openURL ? (
                <ButtonItem
                  buttonSize="small"
                  onClick={handleButtonClick}
                  style="btn-secondary"
                  target="_blank"
                  text="Church Resources"
                  url={openURL}
                />
              ) : null}

              {additionalResources?.['@nodes']?.map((item) => {
                const resItem = additionalResources[item];
                return (
                  <ButtonItem
                    buttonSize="small"
                    key={resItem['@id']}
                    onClick={handleButtonClick}
                    style="btn-secondary"
                    target={
                      resItem.field === 'url' && resItem.target
                        ? resItem.target
                        : '_blank'
                    }
                    text={resItem.text}
                    url={
                      resItem.field === 'url'
                        ? resItem.link
                        : resItem.asset_public
                    }
                  />
                );
              })}
            </ul>
          </div>
        ) : null}

        {showSocialShare ? (
          <SocialMediaShare
            data={data}
            instagram={false}
            linkedin={false}
            youtube={false}
          />
        ) : null}
      </div>

      {hubspotFormID || showForm ? (
        <div className="form-wrapper">
          <Hubspot
            className={hubspotStyle || ''}
            hubspotFormID={hubspotFormID}
          />
        </div>
      ) : null}
    </div>
  );
}

export default ContentAndForm;
