/**
 * @module mediaCollectionFetcher
 */
import _ from 'lodash';
import { getMagnoliaItem } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/getMagnoliaItem';
import { getLabelList } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/labels';
import {
  fetchAccordionData,
  fetchInThisSeries,
  fetchRelatedContent,
} from './commonFetchers';
import {
  ENDPOINT_WORKSPACE_MAP,
  MAGNOLIA_ENDPOINTS,
  MEDIA_TYPES,
  MGNL_ENV_VARS,
  PAGINATION_OPTIONS,
  WEB_DISTRIBUTION_PLATFORM_UUID,
} from '../constants';

/**
 * NOTE: With the introduction to pagination, the logic is updated to include:
 * - Set pagination object based on response.
 * - Set latest to first of results array ONLY IF response offset is 0.
 * - Set past to items 1 through 10 (9 total) ONLY IF response offset is 0.
 * - Set past to items 1 through end ONLY IF response offset is 0 and results length is less than 10.
 * - Set past to all results ONLY IF response offset is not 0.
 *
 * This logic ensures we only set latest if at the start of the results, and
 * allows for divisible-by-3 result totals (unless at the end).
 *
 * Results length cap value for initial results and pagination calculation, set
 * to 10 because the first result item in a 0-offset based response is seen
 * standalone (as a hero) and the others are listed in a grid, so this value is
 * one more than the divisible-by-3 based grid.
 */

export const resultsLengthCap = 10;

/**
 * Data fetcher for series based media collection data.
 *
 * @param {MediaItemsData} mediaItems - The object containing the media children data.
 * @param {object} data - The collection data object.
 *
 * @returns {object} The fetched media collection data, containing `inThisSeries` and `relatedContent` attributes.
 */
export async function fetchMediaCollectionSeriesBased(mediaItems, data) {
  const mediaType = data?.['@path']?.split('/')?.[1];
  const inThisSeries = await fetchInThisSeries({
    data,
    mediaItems,
    mediaType,
    source: 'mediacollection',
  });
  const relatedContent = await fetchRelatedContent(data);
  return { inThisSeries, relatedContent };
}

/**
 * Convenience function to get album list data based on the media items.
 *
 * @param {MediaItemsData} mediaItems - The object containing the media children data.
 *
 * @returns {AlbumData} The album data object containing the sorted album list data and pagination data.
 */
export function getAlbumList(mediaItems) {
  const aboutAlbumAndSong = mediaItems?.results?.length
    ? _.sortBy(mediaItems.results, (item) => Number(item.part))
    : [];
  return { aboutAlbumAndSong };
}

/**
 * Convenience function to fetch episode-based media collection data.
 *
 * @param {MediaItemsData} mediaItems - The object containing the media children data.
 *
 * @returns {EpisodesData} The fetched episodes media collection data.
 */
export async function fetchMediaCollectionEpisodeBased(mediaItems) {
  const [labels] = await Promise.all([
    getLabelList({
      ancestor: `media_listing_header_labels`,
      mgnlEnvVars: MGNL_ENV_VARS,
      workspaceMap: ENDPOINT_WORKSPACE_MAP,
    }),
  ]);
  const pagination = {
    limit: mediaItems?.limit ?? 0,
    offset: mediaItems?.offset ?? 0,
    total: mediaItems?.total ?? 0,
  };
  const results = mediaItems?.results || [];
  let pastMedia;
  const latest = pagination.offset === 0 ? results[0] : null;

  if (pagination.offset === 0 && results.length >= resultsLengthCap) {
    pastMedia = results.slice(1, resultsLengthCap);
    pagination.next = resultsLengthCap;
  } else if (pagination.offset === 0) {
    pastMedia = results.slice(1);
    pagination.next = results.length;
  } else {
    pastMedia = results;
    pagination.next = pagination.offset + results.length;
  }

  return {
    currentSeriesLabels: labels,
    latest,
    pagination,
    pastMedia,
  };
}

/**
 * Convenience function to fetch podcast-based media collection data.
 *
 * @param {MediaItemsData} mediaItems - The object containing the media children data.
 * @param {object} data - The collection data object.
 *
 * @returns {object} The fetched media collection data.
 */
export async function fetchMediaCollectionPodcastBased(mediaItems, data) {
  const latest = mediaItems?.results?.[0];
  const featuredImg = latest?.featuredImage_public ?? '';
  const [modalLabels] = await Promise.all([
    getLabelList({
      ancestor: `media_item_podcast`,
      mgnlEnvVars: MGNL_ENV_VARS,
      workspaceMap: ENDPOINT_WORKSPACE_MAP,
    }),
  ]);
  const podcastTextAndImage = {
    featuredImg,
    latest,
    modalLabels,
  };
  const accordionDataResult = await fetchAccordionData({
    data,
    mediaItems,
    source: 'mediacollection',
  });

  return {
    accordionData: accordionDataResult.accordionData,
    pagination: accordionDataResult.pagination,
    podcastTextAndImage,
  };
}

/**
 * Convenience function to fetch early-childhood-based media collection data.
 *
 * @param {MediaItemsData} mediaItems - The object containing the media children data.
 *
 * @returns {EarlyChildhoodData} The fetched media collection data.
 */
export function getMediaCollectionEarlyChildhoodBased(mediaItems) {
  const pagination = {
    limit: mediaItems?.limit ?? 0,
    offset: mediaItems?.offset ?? 0,
    total: mediaItems?.total ?? 0,
  };
  const results = mediaItems?.results || [];
  let pastMedia;
  const latest = pagination.offset === 0 ? results[0] : null;
  if (pagination.offset === 0 && results.length >= resultsLengthCap) {
    pastMedia = results.slice(1, resultsLengthCap);
    pagination.next = resultsLengthCap;
  } else if (pagination.offset === 0) {
    pastMedia = results.slice(1);
    pagination.next = results.length;
  } else {
    pastMedia = results;
    pagination.next = pagination.offset + results.length;
  }

  return { latest, pagination, pastMedia };
}

/**
 * Convenience function to fetch mediaV2 collection data.
 *
 * @param {object} props - The media collection props data object.
 *
 * @returns {object} The fetched media collection data.
 */
async function fetchMediaCollection(props) {
  const {
    collectionType,
    parts,
    limitItems = PAGINATION_OPTIONS.mediaCollection.limit,
    offset = PAGINATION_OPTIONS.mediaCollection.offset,
    offsetItems,
  } = props;
  let { limit = PAGINATION_OPTIONS.mediaCollection.limit } = props;
  let orderByQuery = '&orderBy=startDate desc';

  if (collectionType === 'album') {
    limit = parts;
    orderByQuery = '';
  }

  const limitQuery =
    limit && !Number.isNaN(parseInt(limit, 10)) ? `&limit=${limit}` : '';
  const offsetQuery =
    offset && !Number.isNaN(parseInt(offset, 10)) ? `&offset=${offset}` : '';
  const limitItemsQuery =
    limitItems && !Number.isNaN(parseInt(limitItems, 10))
      ? `&limitItems=${limitItems}`
      : '';
  const offsetItemsQuery =
    offsetItems && !Number.isNaN(parseInt(offsetItems, 10))
      ? `&offsetItems=${offsetItems}`
      : '';

  const paginationQueryParams = `${limitQuery}${offsetQuery}${orderByQuery}${limitItemsQuery}${offsetItemsQuery}`;

  const params = window?.location?.search
    ? window.location.search
        .slice(1)
        .split('&')
        .map((p) => p.split('='))
        .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {})
    : false;
  const previewSlug = params?.previewSlug;

  let queryParams = `?@jcr:uuid=${props.mediaCollectionID || props['@id']}`;

  if (previewSlug) {
    const [mediaType, collectionName] = previewSlug.toLowerCase().split('/');
    queryParams = `?@ancestor=/${mediaType}&slug=${collectionName}`;
  }

  queryParams += `&availablePlatforms[eq]=${WEB_DISTRIBUTION_PLATFORM_UUID}${paginationQueryParams}&includeChildren=true`;

  const path = encodeURI(
    `${MAGNOLIA_ENDPOINTS.delivery.mediaCollections}${queryParams}`,
  );

  const data = await getMagnoliaItem({
    caller:
      'src/helpers/dataFetchers/mediaCollectionFetcher.js > fetchMediaCollection',
    mgnlEnvVars: MGNL_ENV_VARS,
    path,
    workspaceMap: ENDPOINT_WORKSPACE_MAP,
  }); // NOSONAR

  const collectionData = data?.results?.[0];

  if (!collectionData) {
    return null;
  }

  const { mediaItems, ...collection } = collectionData;

  let tempType = '';

  if (collection?.['@path']) {
    const collectionTypeName = collection?.['@path']?.split('/')?.[1];
    switch (collectionTypeName) {
      case 'stories':
      case 'konnect':
      case 'crosstown':
        tempType = MEDIA_TYPES.EPISODE;
        break;
      case 'worship':
        tempType = MEDIA_TYPES.ALBUM_SONG;
        break;
      case 'leadershippodcast':
      case 'leadership-podcast':
        tempType = MEDIA_TYPES.PODCAST;
        break;
      case 'early-childhood':
        tempType = MEDIA_TYPES.EARLY_CHILDHOOD;
        break;
      case 'messages':
      case 'weekend-messages':
      case 'lifegroups':
      case 'switch':
      case 'loop':
        tempType = MEDIA_TYPES.SERIES;
        break;
      default:
        break;
    }
  }

  let collectionTypeSpecificData = {};
  switch (tempType) {
    case MEDIA_TYPES.SERIES:
      collectionTypeSpecificData = await fetchMediaCollectionSeriesBased(
        mediaItems,
        collection,
      ); // NOSONAR
      break;
    case MEDIA_TYPES.ALBUM_SONG:
      collectionTypeSpecificData = getAlbumList(mediaItems); // NOSONAR
      break;
    case MEDIA_TYPES.EPISODE:
      collectionTypeSpecificData = await fetchMediaCollectionEpisodeBased(
        mediaItems,
      ); // NOSONAR
      break;
    case MEDIA_TYPES.PODCAST:
      collectionTypeSpecificData = await fetchMediaCollectionPodcastBased(
        mediaItems,
        collection,
      ); // NOSONAR
      break;
    case MEDIA_TYPES.EARLY_CHILDHOOD:
      collectionTypeSpecificData =
        getMediaCollectionEarlyChildhoodBased(mediaItems); // NOSONAR
      break;
    default:
      break;
  }

  let labelList = [];
  if (tempType) {
    const [tempArr] = await Promise.all([
      getLabelList({
        ancestor: `media_collection_${tempType}`,
        mgnlEnvVars: MGNL_ENV_VARS,
        workspaceMap: ENDPOINT_WORKSPACE_MAP,
      }),
    ]);
    labelList = tempArr;
  }

  return {
    collectionTypeSpecificData,
    labelList,
    mediaCollectionData: collection,
    pagination: collectionTypeSpecificData.pagination,
    type: tempType,
  };
}

export { fetchMediaCollection };
