/**
 * @module CommonFetchers
 */
import _ from 'lodash';
import { timestampToDate } from '@lifechurch/web-tools-io/dist/utils/helpers/date';
import { collectionTypeToPath } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/collectionTypeUtils';
import { getMagnoliaItem } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/getMagnoliaItem';
import { getLogoPathBasedOnCollectionType } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/imgix';
import { getLabelList } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/labels';
import { mediaUrlFromType } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/mediaTypeUtils';
import { getUrlStructureByPath } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/urls';
import { rectifyMediaCollectionType } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/rectifyMediaCollectionType';
import {
  ENDPOINT_WORKSPACE_MAP,
  MAGNOLIA_ENDPOINTS,
  MGNL_ENV_VARS,
  PAGINATION_OPTIONS,
  WEB_DISTRIBUTION_PLATFORM_UUID,
} from '../constants';

/**
 * Convenience function to get data for the In This Series section.
 *
 * @param {object} params - The params object.
 * @param {object} params.data - The media collection or media item object data.
 * @param {boolean} [params.getMoreItems] - The boolean flag indicating whether or not to fetch more items.
 * @param {MediaItemsData} [params.mediaItems] - The object containing the media children data.
 * @param {boolean} params.mediaType - The current media type.
 * @param {'collection'|'media'} params.source - String which indicates the name of the source, which can be 'media' or 'collection'.
 *
 * @returns {object} - The object that contains In This Series elements and the media collection type.
 */
export async function fetchInThisSeries({
  data,
  getMoreItems = false,
  mediaItems = {},
  mediaType,
  source,
}) {
  let mediaItemsResults = mediaItems?.results;

  if (getMoreItems && data) {
    let string = '';
    const [, type, mediaName] = data['@path'].split('/');

    if (source === 'media') {
      string = `/${type}/${mediaName}`;
    } else {
      string = data?.['@path'];
    }

    const ascendantOrderingTypes = ['messages', 'lifegroups', 'switch', 'loop'];

    const descOrAsc = ascendantOrderingTypes.includes(type) ? 'asc' : 'desc';

    const mediaPath = encodeURI(
      `${MAGNOLIA_ENDPOINTS.delivery.mediaItems}?@ancestor=${string}&availablePlatforms[eq]=${WEB_DISTRIBUTION_PLATFORM_UUID}&orderBy=startDate ${descOrAsc}`,
    );

    const mediaData = await getMagnoliaItem({
      caller: 'src/helpers/dataFetchers/commonFetchers.js > fetchInThisSeries',
      mgnlEnvVars: MGNL_ENV_VARS,
      path: mediaPath,
      workspaceMap: ENDPOINT_WORKSPACE_MAP,
    });
    mediaItemsResults = mediaData?.results;
  }

  const inThisSeries = mediaItemsResults?.length
    ? _.sortBy(
        mediaItemsResults,
        (item) =>
          /* istanbul ignore next */ Number(item.part) ||
          Number(item.episodeNumber) ||
          Number(item.trackNumber),
      )
    : [];

  const mediaCollectionType = rectifyMediaCollectionType(mediaType);

  return {
    inThisSeries,
    mediaCollectionType,
  };
}

/**
 * Convenience function to get the query and specific type data from non-specific items.
 *
 * Note: With the migration to PaaS, the timeout is not the same as that of the
 * now-old Cloud3 setup. As such, for tests, accessing URLs with no things like
 * no or invalid limit values is not advisable since it would either cause
 * timeout errors and failed tests and/or needing to increase test timeout
 * values. With test coverage for adding this filter, it's safe to ignore for
 * the "not present" or "invalid value" cases.
 *
 * @param {object} params - = The params object.
 * @param {string} params.path - The current media element path.
 * @param {object} params.pickSpecificItem - The data for the specific items of the related data.
 * @param {object} params.queryParams - The object containing query parameters.
 *
 * @returns {object} - The object that contains the query and specific type.
 */
export function getDataForNonSpecificItems({
  path,
  pickSpecificItem = {},
  queryParams,
}) {
  let type = 'mediaCollections';
  let specificType = path.split('/')?.[1];
  const { mediaDisplayType, startDate, endDate, tags } = pickSpecificItem;

  const displayType = mediaDisplayType?.field ?? 'mediaCollection';

  if (mediaDisplayType) {
    if (displayType === 'mediaCollection') {
      specificType = mediaDisplayType?.media_collection_type;
    } else {
      type = 'mediaItems';
      specificType = mediaDisplayType?.filterByMediaCollectionType;
    }
  }

  const filterStartDate = startDate && new Date(startDate).getTime();
  const filterEndDate = endDate && new Date(endDate).getTime();
  const filterTag = tags?.length === 1 && tags?.[0] === '' ? [] : tags;

  let query = `${MAGNOLIA_ENDPOINTS.delivery[type]}?`;

  /**
   * Somehow the else if is seen by Jest as uncovered in tests, even though
   * there definitely assertions to have type be both mediaItems and mediaCollections.
   * Adding as ignore, as it's known that these are both covered.
   */
  /* istanbul ignore next */
  if (type === 'mediaItems') {
    const [, mediaType, mediaName] = path.split('/');
    query += `@ancestor=/${mediaType}/${mediaName}&`;
  } else if (type === 'mediaCollections') {
    query += `@ancestor=/${collectionTypeToPath(specificType)}/&`;
  }

  if (filterStartDate) {
    query += `startDate[gte]=${timestampToDate(filterStartDate)}&`;
  }

  if (filterEndDate) {
    query += `startDate[lte]=${timestampToDate(filterEndDate)}&`;
  }

  if (filterTag?.length) {
    filterTag.forEach((t) => {
      query += `tag[like]=${t}&`;
    });
  }

  query += `availablePlatforms[eq]=${WEB_DISTRIBUTION_PLATFORM_UUID}`;

  const { descOrAsc, limit, orderBy } = queryParams;

  /* istanbul ignore next */
  if (orderBy === `startDate`) {
    query += `&orderBy=${orderBy} ${descOrAsc}&limit=${limit + 1}`;
  }

  return {
    query,
    specificType,
  };
}

/**
 * Convenience function to fetch the Related Content for the specified media element.
 *
 * Note: With the migration to PaaS, the timeout is not the same as that of the
 * now-old Cloud3 setup. As such, for tests, accessing URLs with no things like
 * no or invalid limit values is not advisable since it would either cause
 * timeout errors and failed tests and/or needing to increase test timeout
 * values. With test coverage for adding this filter, it's safe to ignore for
 * the "not present" or "invalid value" cases.
 *
 * @param {object} data - The media element for which to fetch the related data.
 *
 * @returns {object} - The object containing the related content data.
 */
export async function fetchRelatedContent(data) {
  if (data?.relatedContent) {
    /* istanbul ignore next */
    const limit = Number(data.relatedContent.maxItems) || 0;
    const { pickSpecificItem } = data.relatedContent;
    const orderBy = data.relatedContent.orderBy || 'startDate';

    /* istanbul ignore next */
    const descOrAsc = orderBy === 'title' ? 'asc' : 'desc';

    if (data?.relatedContent?.showRelatedContent?.toString() === 'true') {
      let specificType = '';
      let dataFiltered = [];

      if (pickSpecificItem?.field?.toString() === 'true') {
        // No need to assert against mapping a blank array fallback scenario.
        /* istanbul ignore next */
        await Promise.all(
          (pickSpecificItem?.['@nodes'] ?? []).map(async ($k) => {
            const specificItem = pickSpecificItem[$k];
            const isMediaCollection = specificItem?.field === 'mediaCollection';

            const type = isMediaCollection ? 'mediaCollections' : 'mediaItems';
            const tmp = [];

            const items = isMediaCollection
              ? specificItem?.mediaCollections
              : specificItem?.mediaItems;

            if (items) {
              const mediaPromises = items?.['@nodes'].map(async (node) => {
                const id = items[node].field;

                if (data['@id'] === id) {
                  return null;
                }

                const mediaPath = `${MAGNOLIA_ENDPOINTS.delivery[type]}?@jcr:uuid=${id}&availablePlatforms[eq]=${WEB_DISTRIBUTION_PLATFORM_UUID}`;
                const json = await getMagnoliaItem({
                  caller:
                    'src/helpers/dataFetchers/commonFetchers.js > fetchRelatedContent',
                  mgnlEnvVars: MGNL_ENV_VARS,
                  path: encodeURI(mediaPath),
                  workspaceMap: ENDPOINT_WORKSPACE_MAP,
                }); // NOSONAR
                return json.results;
              });
              const results = await Promise.all(mediaPromises);
              tmp.push(...results.flat().filter((result) => result !== null));
            }

            dataFiltered = _.orderBy(tmp, orderBy, descOrAsc);

            /* istanbul ignore next */
            if (dataFiltered.length) {
              const firstItem = dataFiltered[0];
              specificType = firstItem?.['@path'].split('/')?.[1];
            }
          }),
        );
      } else {
        const queryParams = { descOrAsc, limit, orderBy };
        const nonSpecificItemsData = getDataForNonSpecificItems({
          path: data['@path'],
          pickSpecificItem,
          queryParams,
        });

        specificType = nonSpecificItemsData.specificType;

        const json = await getMagnoliaItem({
          caller:
            'src/helpers/dataFetchers/commonFetchers.js > fetchRelatedContent',
          mgnlEnvVars: MGNL_ENV_VARS,
          path: encodeURI(nonSpecificItemsData.query),
          workspaceMap: ENDPOINT_WORKSPACE_MAP,
        }); // NOSONAR

        dataFiltered = json.results.filter(
          (item) => item['@id'] !== data['@id'],
        );
        dataFiltered = _.orderBy(dataFiltered, orderBy, descOrAsc);
      }

      const [tempArr] = await Promise.all([
        getLabelList({
          ancestor: `related_content_labels`,
          mgnlEnvVars: MGNL_ENV_VARS,
          workspaceMap: ENDPOINT_WORKSPACE_MAP,
        }),
      ]);

      const returnVal = dataFiltered.length
        ? {
            itemType: rectifyMediaCollectionType(specificType),
            logoPath: getLogoPathBasedOnCollectionType(
              specificType,
              process.env.IMGIX_BASE_URL,
            ),
            relatedCollectionType: specificType,
            relatedContent: dataFiltered,
          }
        : {};
      /* istanbul ignore next */
      returnVal.labelList = tempArr || [];

      return returnVal;
    }
  }

  return {};
}

/**
 * Convenience function to fetch data for the accordion.
 *
 * @param {object} params - The params object.
 * @param {object} params.data - The media collection or media item object data.
 * @param {boolean} [params.getMoreItems] - The boolean flag indicating whether or not to fetch more items.
 * @param {MediaItemsData} [params.mediaItems] - The object containing the media children data.
 * @param {'collection'|'media'} [params.source] - String which indicates the name of the source, which can be 'media' or 'collection'. The default value is 'media'.
 *
 * @returns {object} - The object containing the accordion data and corresponding pagination data.
 */
export async function fetchAccordionData({
  data,
  getMoreItems = false,
  mediaItems = {},
  source = 'media',
}) {
  let podcastItems = mediaItems;

  if (getMoreItems && data) {
    const {
      limit = PAGINATION_OPTIONS.accordionData.limit,
      offset = PAGINATION_OPTIONS.accordionData.offset,
    } = data;
    const limitQuery =
      limit && !Number.isNaN(parseInt(limit, 10)) ? `&limit=${limit}` : '';
    const offsetQuery =
      offset && !Number.isNaN(parseInt(offset, 10)) ? `&offset=${offset}` : '';
    let string = '';
    if (source === 'media') {
      const mediaType = data?.['@path']?.split('/')?.[1];
      string = `/${mediaType}`;
    } else {
      string = data['@path'];
    }
    podcastItems = await getMagnoliaItem({
      caller: 'src/helpers/dataFetchers/commonFetchers.js > fetchAccordionData',
      mgnlEnvVars: MGNL_ENV_VARS,
      path: encodeURI(
        `${MAGNOLIA_ENDPOINTS.delivery.mediaItems}?@ancestor=${string}&availablePlatforms[eq]=${WEB_DISTRIBUTION_PLATFORM_UUID}${limitQuery}${offsetQuery}&orderBy=startDate desc`,
      ),
      workspaceMap: ENDPOINT_WORKSPACE_MAP,
    }); // NOSONAR
  }

  const tmp = {};
  const nodes = [];
  const orderedResults = podcastItems?.results?.length
    ? _.orderBy(podcastItems.results, (item) => Number(item.part), ['desc'])
    : [];

  /**
   * Note: This is covered as needed with tests, but added for ignore due to
   * conditional check used in ordered results, as limits/ordering has been
   * suppressed from test coverage due to note above.
   */
  /* istanbul ignore next */
  orderedResults.forEach((item) => {
    const {
      collection,
      item: itemSlug,
      subCollection,
    } = getUrlStructureByPath(item['@path']);
    if (item['@id'] !== data['@id']) {
      const key = item['@id'].split('-').join('');
      const title =
        item['@nodeType'] === 'lc:mediaPodcast'
          ? `${item.part}: ${item.title}`
          : item.title;
      const url = mediaUrlFromType({
        slug: itemSlug,
        subCollection,
        type: collection,
      });

      tmp[key] = {
        ...item,
        allowedPlayIcon: true,
        'mgnl:template': 'lifechurch:components/accordionitem',
        target: '_self',
        title,
        url,
      };

      nodes.push(key);
    }
  });

  tmp['@nodes'] = nodes;

  const paginationNext =
    (podcastItems?.offset ?? 0) + (podcastItems?.limit ?? 0);
  const pagination = {
    limit: podcastItems?.limit ?? 0,
    next: paginationNext,
    offset: podcastItems?.offset ?? 0,
    total: podcastItems?.total ?? 0,
  };

  return {
    accordionData: tmp,
    pagination,
  };
}
