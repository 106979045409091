/**
 * @module MediaDetailAlbumSongBased
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import useWindowSize from '@lifechurch/web-tools-io/dist/hooks/useWindowSize';
import SongDetail from '../../MediaSharedComponents/SongDetail';
import MediaYouTubeOrFallBackPlayer from '../../MediaSharedComponents/MediaYouTubeOrFallBackPlayer';

const MediaDetailAlbumSongBased = (props) => {
  const { mediaItemData, mediaItemAlbumSongBased, collectionData } = props;
  const { isMobile } = useWindowSize();

  return (
    <section
      className="media-detail-album-song-based"
      data-testid="lc-media-detail-album-song-based"
    >
      <MediaYouTubeOrFallBackPlayer {...mediaItemData} />
      <SongDetail
        collectionData={collectionData}
        data={mediaItemData}
        isMobile={isMobile}
        preload={mediaItemAlbumSongBased}
      />
    </section>
  );
};

export default MediaDetailAlbumSongBased;
