/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module Checkbox
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { convertValueToClassName } from '@lifechurch/web-tools-io/dist/utils/helpers/validators';
import check from './check.svg';
import './Checkbox.scss';

/**
 * Represents a form control element containing a checkbox input wrapped with a label element.
 *
 * @param {object} props - The component props object.
 * @param {string} props.inputName - The value to use for the input name attribute.
 * @param {string} props.label - The label value for the component.
 * @param {string} props.type - The input type (Example: checkbox).
 *
 * @returns {React.ReactElement} The Checkbox component.
 */
const Checkbox = ({ inputName, label, type }) => {
  const [isClicked, setIsClicked] = React.useState(false);

  /**
   * Handler function for click event.
   */
  function handleClicked() {
    setIsClicked(!isClicked);
  }

  return (
    <div className="form-control">
      <label className={/* istanbul ignore next */ isClicked ? 'active' : ''}>
        <input
          className="display-none"
          data-testid="checkbox-input"
          name={inputName}
          type={type}
        />
        <span
          className={convertValueToClassName(type)}
          data-testid="checkbox-img-wrapper"
          onClick={handleClicked}
        >
          <img alt="check" src={check} />
        </span>
        <span
          className="label-text"
          data-testid="checkbox-label"
          onClick={handleClicked}
        >
          {label}
        </span>
      </label>
    </div>
  );
};

export default Checkbox;
