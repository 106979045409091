/**
 * @module AppDownload
 */
import React from 'react';
import { implementUtmParams } from '@lifechurch/web-tools-io/dist/utils/helpers/utmParams';

/**
 * Represents a view presented to the user when one of the specified "smart URL" routes is being processed.
 *
 * @returns {React.ReactElement} The AppDownload component.
 */
function AppDownload() {
  /**
   * Single-run convenience effect to calculate URL params and track metrics
   * to Segment for analytics. After tracking, determine the proper URL to which
   * to redirect the user based on the device browser user agent. If not on a
   * supported mobile device for Android or iOS, redirect to main /app route.
   * Note: A brief setTimeout is used to ensure the initial render happens prior
   * to redirecting. If not used, things happen immediately and the target page
   * does not load until manually refreshed.
   */
  React.useEffect(() => {
    setTimeout(() => {
      const ua = window?.navigator?.userAgent || '';
      const supportedOS = {
        android: [/Android/i],
        iOS: [/iPad/i, /iPhone/i, /iPod/i],
      };
      const appStoreUrls = {
        apple: process.env.LC_APP_STORE_URL_APPLE,
        google: process.env.LC_APP_STORE_URL_GOOGLE,
      };
      const isAndroid = supportedOS.android.some((item) => {
        return ua.match(item);
      });
      const isIOS = supportedOS.iOS.some((item) => {
        return ua.match(item);
      });

      const fallbackUrl =
        process.env.SMART_APP_FALLBACK_URL || 'https://www.life.church/app/';
      let targetUrl = fallbackUrl;
      /**
       * If mobile device, redirect to corresponding app store URL. Otherwise,
       * simply replace window location with fallback URL.
       */
      if (isAndroid || isIOS) {
        targetUrl = isAndroid ? appStoreUrls.google : appStoreUrls.apple;
        window.location.replace(
          implementUtmParams(targetUrl, window?.location),
        );
      } else {
        window.location.replace(
          implementUtmParams(targetUrl, window?.location),
        );
      }
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container" data-testid="callback-view">
      <div className="content-center">
        <div className="callback flex-center"></div>
      </div>
    </div>
  );
}

export default AppDownload;
