/**
 * @module LocationPosterContact
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import { implementUtmParams } from '@lifechurch/web-tools-io/dist/utils/helpers/utmParams';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import { ACTIONS, EVENTS } from '../../helpers/constants';
import LocationPosterAddress from './LocationPosterAddress';
import Spans from '../IconSpans/Spans';

const LocationPosterContact = (props) => {
  const { user } = useAuth();
  const emailUrl = `https://my.life.church/forms/questions?Location=${props.campusCode}`;

  /**
   * Convenience function to trigger analytics tracking call.
   *
   * @param {Event} event - The Event object associated with the click.
   * @param {string} label - The label value for the properties object.
   */
  function callAnalytics(event, label) {
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Location Poster',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: label || event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  // Handler Functions.
  function handleSocialClick(event, label) {
    callAnalytics(event, label);
  }

  function handlePhoneClick(event) {
    callAnalytics(event, 'Phone');
  }

  return (
    <div
      className="poster-address py-normal"
      data-testid="lc-location-poster-contact"
    >
      <LocationPosterAddress address={props.primaryAddress} title="Location" />
      {props.secondaryAddress?.secondaryStreet1 ? (
        <LocationPosterAddress
          address={{
            cityOrTown: props.secondaryAddress?.secondaryCityOrTown,
            latitude: props.secondaryAddress?.secondaryLatitude,
            longitude: props.secondaryAddress?.secondaryLongitude,
            state: props.secondaryAddress?.secondaryState,
            street1: props.secondaryAddress?.secondaryStreet1,
            street2: props.secondaryAddress?.secondaryStreet2,
            zip: props.secondaryAddress?.secondaryZip,
          }}
          title="Weekday Contact"
        />
      ) : null}
      {props.phone ? (
        <p className="text-weight-semi_bold display-flex justify-space-between">
          <span className="section-title">Phone Number</span>
          <span>
            <a
              data-testid="phone-link"
              href={`tel:${props.phone}`}
              onClick={handlePhoneClick}
            >
              {props.phone}
            </a>
          </span>
        </p>
      ) : null}

      {(!!props.facebook || !!props.instagram || !!props.email) && (
        <p className="text-weight-semi_bold display-flex justify-space-between align-center">
          <span className="section-title">Contact Us</span>
          <span className="display-flex justify-flex-end social-media">
            {props.facebook ? (
              <span>
                <a
                  className="social-link"
                  href={`https://facebook.com/${props.facebook}`}
                  onClick={(event) => handleSocialClick(event, 'Facebook')}
                  rel="noreferrer"
                  target="_blank"
                >
                  <span className="icon icon-facebook">
                    <Spans />
                  </span>
                </a>
              </span>
            ) : null}
            {props.instagram ? (
              <span>
                <a
                  className="social-link"
                  href={`https://instagram.com/${props.instagram}`}
                  onClick={(event) => handleSocialClick(event, 'Instagram')}
                  rel="noreferrer"
                  target="_blank"
                >
                  <span className="icon icon-instagram">
                    <Spans />
                  </span>
                </a>
              </span>
            ) : null}
            {props.email ? (
              <span>
                <a
                  className="social-link"
                  href={implementUtmParams(emailUrl, window?.location)}
                  onClick={(event) => {
                    event.preventDefault();
                    props.handleOpenModal(emailUrl);
                    handleSocialClick(event, 'Email');
                  }}
                  rel="noreferrer"
                  target="_blank"
                >
                  <span className="icon icon-email">
                    <Spans />
                  </span>
                </a>
              </span>
            ) : null}
          </span>
        </p>
      )}
    </div>
  );
};

export default LocationPosterContact;
