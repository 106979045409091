/**
 * @module LocationPosterSpan
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

const LocationPosterSpan = ({ addLineBreak, className, endWith, text }) => {
  if (!text) {
    return null;
  }

  const finalText = endWith ? `${text}${endWith}` : text;
  const spanProps = {};
  if (className && typeof className === 'string') {
    spanProps.className = className;
  }

  return (
    <>
      <span {...spanProps}>{finalText}</span>
      {addLineBreak ? <br /> : null}
    </>
  );
};

export default LocationPosterSpan;
