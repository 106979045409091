/**
 * @module File
 */
import React from 'react';
import './File.scss';

/* istanbul ignore next */
const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

/**
 * Represents a form control element containing a file input.
 *
 * @param {object} props - The component props object.
 * @param {string} props.hint - The value to display for the component hint element.
 * @param {string} props.inputName - The value to use for the input name attribute.
 * @param {string} props.label - The label value for the component.
 *
 * @returns {React.ReactElement} The File component.
 */
const File = ({ hint, inputName, label }) => {
  const [files, setFiles] = React.useState(null);

  /**
   * Handler function for the file change event.
   *
   * @param {Event} event - The Event object associated with the event.
   */
  /* istanbul ignore next */
  const handleFilesChange = async (event) => {
    const targetFiles = event.target.files;
    const base64Promises = [];

    Object.values(targetFiles).forEach((file) => {
      base64Promises.push(toBase64(file));
    });

    const base64s = await Promise.all(base64Promises);
    const tmp = base64s.map((base64, index) => ({
      base64,
      file: targetFiles[index],
    }));

    setFiles(tmp);
  };

  /**
   * Handler function for the remove event.
   *
   * @param {number} index - The index number of the element to remove.
   */
  const handleRemove = (index) => {
    const tmp = [];
    for (let i = 0; i < files.length; i += 1) {
      if (i !== index) {
        tmp.push(files[i]);
      }
    }
    setFiles(tmp);
  };

  return (
    <div className="form-control file" data-testid="file-component-wrapper">
      <label data-testid="file-label">{label}</label>
      <div className="form-group">
        <input
          data-testid="file-input-type-file"
          multiple={true}
          name={`${inputName}[]`}
          onChange={handleFilesChange}
          type="file"
        />
        <span className="file-style">Select file</span>
      </div>
      <span className="mb-normal">{hint}</span>
      {files?.length ? (
        <>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label data-testid="file-uploaded-label">Uploaded</label>
          <div className="file-uploaded">
            {files.map((f, i) => (
              <div
                className="file-item mb-normal px-normal py-kinda_relax"
                data-testid={`file-wrapper-${i}`}
                key={f.file.name}
              >
                <div className="file-title" data-testid={`file-title-${i}`}>
                  <svg
                    fill="none"
                    height="32"
                    viewBox="0 0 32 32"
                    width="32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clipRule="evenodd"
                      d="M8 4H24V23H19V28H8V4ZM21 30L26 25V4V2H24H8H6V4V28V30H8H21Z"
                      fill="#C6C6C6"
                      fillRule="evenodd"
                    />
                  </svg>
                  <span>{f.file.name}</span>
                </div>
                <button
                  data-testid={`file-remove-button-${i}`}
                  onClick={() => handleRemove(i)}
                  type="button"
                >
                  <svg
                    fill="none"
                    height="20"
                    viewBox="0 0 18 20"
                    width="18"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask fill="white" id="path-1-inside-1">
                      <path
                        clipRule="evenodd"
                        d="M7 0H11V1H18V3H17L16 20H2L1 3H0V1H7V0Z"
                        fillRule="evenodd"
                      />
                    </mask>
                    <path
                      d="M11 0H13V-2H11V0ZM7 0V-2H5V0H7ZM11 1H9V3H11V1ZM18 1H20V-1H18V1ZM18 3V5H20V3H18ZM17 3V1H15.1142L15.0035 2.88256L17 3ZM16 20V22H17.8858L17.9965 20.1174L16 20ZM2 20L0.00345125 20.1174L0.11419 22H2V20ZM1 3L2.99655 2.88256L2.88581 1H1V3ZM0 3H-2V5H0V3ZM0 1V-1H-2V1H0ZM7 1V3H9V1H7ZM11 -2H7V2H11V-2ZM13 1V0H9V1H13ZM11 3H18V-1H11V3ZM16 1V3H20V1H16ZM18 1H17V5H18V1ZM17.9965 20.1174L18.9965 3.11744L15.0035 2.88256L14.0035 19.8826L17.9965 20.1174ZM2 22H16V18H2V22ZM-0.996549 3.11744L0.00345125 20.1174L3.99655 19.8826L2.99655 2.88256L-0.996549 3.11744ZM1 1H0V5H1V1ZM2 3V1H-2V3H2ZM0 3H7V-1H0V3ZM5 0V1H9V0H5Z"
                      fill="#C6C6C6"
                      mask="url(#path-1-inside-1)"
                    />
                  </svg>
                </button>
              </div>
            ))}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default File;
