/**
 * @module PrelaunchedWhatToExpectV2
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import useWindowSize from '@lifechurch/web-tools-io/dist/hooks/useWindowSize';
import Button from '../../ButtonItem/ButtonItem';
import WhatToExpectImgSmall from '../../LocationV2/assets/WhatToExpectSmall.png';
import WhatToExpectImgMedium from '../../LocationV2/assets/WhatToExpectMedium.png';
import WhatToExpectImgLarge from '../../LocationV2/assets/WhatToExpectLarge.png';
import { ACTIONS, ENVIRONMENT, EVENTS } from '../../../helpers/constants';

/**
 * Represents the new What to Expect section.
 *
 * @param {object} props - The component props object.
 * @param {LocationData} props.locationData - The location data object for the What to Expect section.
 *
 * @returns {React.ReactElement} The PrelaunchedWhatToExpectV2 component.
 */
export default function PrelaunchedWhatToExpectV2({ locationData }) {
  const { user } = useAuth();
  const { description, subtitle, title } = locationData;
  const { isMobile, isTablet } = useWindowSize();
  let imgSrc = WhatToExpectImgLarge;

  /**
   * Handler function for button click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleButtonClick(event) {
    /* istanbul ignore next */
    if (event?.target?.preventDefault && ENVIRONMENT.test) {
      event.preventDefault();
    }
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Location Pre-Launched - What to Expect',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  /* istanbul ignore next*/
  if (isMobile) {
    imgSrc = WhatToExpectImgSmall;
  } else if (isTablet) {
    imgSrc = WhatToExpectImgMedium;
  }

  return (
    <section className="prelaunched-wte" data-testid="lc-prelaunched-wte">
      <div className="container">
        {title ? (
          <div className="wte-header text-center">
            <h2>{title}</h2>
          </div>
        ) : null}
        <div className="wte-content">
          {imgSrc ? (
            <div className="display-flex">
              <img alt="What to Expect" src={imgSrc} />
            </div>
          ) : /* istanbul ignore next*/ null}
          <div>
            {subtitle ? <h3>{subtitle}</h3> : null}
            {description ? <p>{description}</p> : null}
            <div className="wte-btn-group">
              <Button
                buttonSize="medium"
                onClick={handleButtonClick}
                style="btn-primary"
                text="Attend Online"
                url="https://live.life.church/"
              />
              <Button
                buttonSize="medium"
                onClick={handleButtonClick}
                style="btn-secondary"
                text="About Life.Church"
                url="https://www.life.church/who-we-are/"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
