/**
 * @module Accordion
 */
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import useWindowSize from '@lifechurch/web-tools-io/dist/hooks/useWindowSize';
import { EditableArea, EditorContextHelper } from '@magnolia/react-editor';
import { convertValueToClassName } from '@lifechurch/web-tools-io/dist/utils/helpers/validators';
import { v4 as uuidv4 } from 'uuid';
import { ACTIONS, EVENTS } from '../../helpers/constants';
import './Accordion.scss';

const Accordion = ({
  defaultShow,
  items,
  metadata,
  onShowMoreClick,
  pagination,
  sbOnMobile,
  sbOnTabletAndUp,
  sectionId,
  singleSelect,
}) => {
  const { user } = useAuth();
  const { isMobile } = useWindowSize();
  const isDevMode = EditorContextHelper.inIframe();
  const accordionId = sectionId || `accordion-${uuidv4()}`;

  const containerClass = `accordion container px-normal ${
    isMobile
      ? convertValueToClassName(sbOnMobile)
      : convertValueToClassName(sbOnTabletAndUp)
  }`.trim();

  let initialDefaultShow = items['@nodes'].length;
  if (isDevMode) {
    // Same as default value set, but keeping for consistency and clarity.
    initialDefaultShow = items['@nodes'].length;
  } else if (Number(defaultShow)) {
    initialDefaultShow = Number(defaultShow);
  }

  const [show, setShow] = React.useState(initialDefaultShow);
  const [isLoading, setIsLoading] = React.useState(false);

  const accordions = { ...items }; // Spread to copy object.
  accordions['@nodes'] = items['@nodes'].slice(0, show);
  if (accordions['@nodes']) {
    let firstOpened = '';
    // eslint-disable-next-line array-callback-return
    accordions['@nodes'].map((key) => {
      if (accordions[key].isOpened && !firstOpened) {
        firstOpened = key;
      }
      accordions[key].singleSelect = singleSelect;
      accordions[key].accordionId = accordionId;
      if (singleSelect) {
        accordions[key].isFirstOpened = firstOpened === key;
      }
    });
  }

  const isMoreBtnVisible =
    pagination && pagination.next < pagination.total
      ? true
      : show < accordions['@nodes'].length;

  /**
   * Handler function for Show More button click. If a handler function is
   * specified in props, call it with setShow as its callback argument to make
   * sure the state is updated after the function is complete.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  const handleShowMore = (event) => {
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Accordion',
        component_url: null,
        label: event.currentTarget.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    if (onShowMoreClick && typeof onShowMoreClick === 'function') {
      setIsLoading(true);
      onShowMoreClick((data) => {
        setShow(data.numShow);
        // Since data loads so quickly, it's feasible that toggling between
        // button label of Show More and Loading depending on state would flash
        // too quickly, so a small, short timer helps to avoid instant flicker.
        setTimeout(() => {
          setIsLoading(false);
        }, 250);
      });
    } else {
      setShow(show + defaultShow);
    }
  };

  return (
    <div className={containerClass} id={accordionId}>
      {accordions?.['@nodes'] ? (
        <ul className="accordion-listing border-radius-s shadow-s">
          <EditableArea
            content={accordions}
            parentTemplateId={metadata['mgnl:template']}
          />
          {isMoreBtnVisible ? (
            <div className="accordion-item">
              <button
                className="accordion-showmore px-slightly_more_relaxed py-normal line-height-normal"
                disabled={isLoading}
                onClick={handleShowMore}
              >
                {isLoading ? 'Loading...' : 'Show More'}
              </button>
            </div>
          ) : null}
        </ul>
      ) : null}
    </div>
  );
};

export default Accordion;
