/**
 * @module PodcastCollectionContentForm
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import {
  callGtagEvent,
  callSegmentTrack,
} from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import { getQueryParam } from '@lifechurch/web-tools-io/dist/utils/helpers/queryParams';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import SocialMediaShare from './SocialMediaShare';
import Hubspot from '../EmbedItem/Hubspot/Hubspot';
import {
  ACTIONS,
  CATEGORIES,
  EVENTS,
  HUBSPOT,
  SCREENS,
} from '../../helpers/constants';

const PodcastCollectionContentForm = ({
  description,
  headline,
  label,
  title,
}) => {
  const { user } = useAuth();
  const { form_desc, form_title, social_share_desc, social_share_title } =
    label;

  return (
    <div
      className="podcast-collection-content-form container mb-bit_more_relaxed mb-tablet-very_relaxed"
      data-testid="lc-podcast-collection-content-form"
    >
      <div className="collection-form container text-center">
        {form_title ? <h2 className="title">{form_title}</h2> : null}
        {form_desc ? <p className="description">{form_desc}</p> : null}
        <Hubspot
          className="btn-inside-input"
          hubspotFormID={HUBSPOT.formIds.podcastCollectionContentForm}
          onFormReady={
            /* istanbul ignore next*/ () => {
              document
                .getElementsByClassName('hs-submit')[0]
                .addEventListener('click', (event) => {
                  const emailInputParent =
                    document.getElementsByClassName('hs-email')[0];
                  const emailInput = emailInputParent.querySelectorAll(
                    "input[name='email']",
                  )[0];
                  const emailValue = emailInput ? emailInput.value : '';
                  const utmMap = {
                    campaign: getQueryParam('utm_campaign'),
                    medium: getQueryParam('utm_medium'),
                    name: getQueryParam('utm_name'),
                    source: getQueryParam('utm_source'),
                  };

                  /**
                   * Note: With the completion of epic MND-1694 and Segment
                   * tracking improvements, the new set of event properties is
                   * used, but the original (legacy) ones are also spread on
                   * to the properties object.
                   */
                  const legacySegmentProperties = {
                    campaign: utmMap.campaign,
                    email: emailValue,
                    screen: SCREENS.podcastListingPage,
                  };

                  callGtagEvent({
                    parameters: {
                      event_category: CATEGORIES.subscribeLeaderGuide,
                    },
                    type: 'Subscribe',
                  });
                  callSegmentTrack({
                    event: EVENTS.leadershipPodcastSubscribeFormSubmit,
                    properties: {
                      action: ACTIONS.formSubmit,
                      component: 'Podcast Collection Content Form',
                      component_url: event?.currentTarget?.getAttribute('href'),
                      label: event?.currentTarget?.textContent,
                      logged_in: !!user,
                      preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
                      referrer: document?.referrer || null,
                      title: document?.title || '',
                      url: window?.location?.href,
                      user_id:
                        user?.['https://www.life.church/rock_person_alias_id'],
                      ...legacySegmentProperties,
                    },
                  });
                  document.cookie =
                    'leadership_podcast_optin=1; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT';
                });
            }
          }
        />
      </div>
      <div className="collection-content text-center">
        {social_share_title ? (
          <h2 className="title mt-auto mb-normal">{social_share_title}</h2>
        ) : null}
        {social_share_desc ? (
          <p className="description mt-auto mb-normal">{social_share_desc}</p>
        ) : null}
        <SocialMediaShare
          center={true}
          data={{ description, headline, title }}
          email={false}
          facebookUrl="https://facebook.com/craiggroeschel"
          instagramUrl="https://www.instagram.com/craiggroeschel/"
          linkedinUrl="https://www.linkedin.com/company/craiggroeschel/"
          tiktok={true}
          tiktokUrl="https://www.tiktok.com/@craiggroeschel/"
          twitter={false}
          twitterUrl="https://twitter.com/craiggroeschel"
          youtubeUrl="https://youtube.com/craiggroeschel"
        />
      </div>
    </div>
  );
};

export default PodcastCollectionContentForm;
