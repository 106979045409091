/**
 * @module MenuModalLink
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import { stringToSlug } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/urls';
import { implementUtmParams } from '@lifechurch/web-tools-io/dist/utils/helpers/utmParams';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import { ACTIONS, EVENTS } from '../../helpers/constants';

const MenuModalLink = ({ menuItem }) => {
  const { user } = useAuth();

  if (!menuItem) {
    return null;
  }

  const { customLink, openInNewTab, name, navigationExcerpt } = menuItem;
  const field = customLink?.field;
  const target = openInNewTab ? '_blank' : undefined;

  const urlsMap = {
    external: customLink?.external,
    internal: customLink?.internal?.['@path'],
  };
  const url = urlsMap?.[field] || '';

  const linkUrl = new URL(url, window?.location?.origin);
  let windowUrlPath = window?.location?.pathname;
  let linkUrlPath = linkUrl?.pathname;
  if (!windowUrlPath.endsWith('/')) {
    windowUrlPath = `${windowUrlPath}/`;
  }
  if (!linkUrlPath.endsWith('/')) {
    linkUrlPath = `${linkUrlPath}/`;
  }
  const isActive = windowUrlPath === linkUrlPath;

  /**
   * Handler function for button or link item element click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleElementClick(event) {
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Careers Menu Modal',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  return (
    <a
      className={`menu-modal-link${isActive ? ' active' : ''}`}
      data-menu-id={stringToSlug(name)}
      data-testid="lc-menu-modal-link"
      href={implementUtmParams(url, window?.location)}
      onClick={handleElementClick}
      rel="noreferrer"
      target={target}
    >
      {name ? <span className="modal-link-title">{name}</span> : null}
      {name && navigationExcerpt ? <span> </span> : null}
      {navigationExcerpt ? (
        <span className="modal-link-description">{navigationExcerpt}</span>
      ) : null}
    </a>
  );
};

export default MenuModalLink;
