/**
 * @module AccordionItem
 */
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import { implementUtmParams } from '@lifechurch/web-tools-io/dist/utils/helpers/utmParams';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import { v4 as uuidv4 } from 'uuid';
import Icon from '../ButtonItem/Icon';
import RichText from '../RichText/RichText';
import { ACTIONS, EVENTS } from '../../helpers/constants';
import './AccordionItem.scss';

/**
 * Represents an accordion item, comprised of a top-level <li> element with a header/title area and collapsible content area.
 *
 * @param {object} props - The component props object.
 *
 * @returns {React.ReactElement} The AccordionItem component.
 */
const AccordionItem = (props) => {
  const { user } = useAuth();
  const [open, setOpen] = React.useState(false);
  const [cId] = React.useState(`accordion-content-${uuidv4()}`); // NOSONAR
  const { content, target, url } = props;
  const Tag = content ? 'button' : 'div';

  /**
   * Handler function for item anchor tag click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  const handleAnchorClick = (event) => {
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Accordion Item',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  };

  /**
   * Handler function for item collapse/expand.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  const handleCollapse = (event) => {
    /* istanbul ignore next */
    if (Tag === 'button') {
      callSegmentTrack({
        event: EVENTS.buttonAction,
        properties: {
          action: open ? ACTIONS.collapsed : ACTIONS.expanded,
          component: 'Accordion Item',
          component_url: null,
          label: event?.currentTarget?.textContent,
          logged_in: !!user,
          preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
          referrer: document?.referrer || null,
          title: document?.title || '',
          url: window?.location?.href,
          user_id: user?.['https://www.life.church/rock_person_alias_id'],
        },
      });
      setOpen(!open);
      const element = document.querySelector(`#${cId}`);
      /* istanbul ignore next */
      if (element) {
        const elementHeight = element.getAttribute('height') ?? 0;
        element.setAttribute('style', `height: ${!open ? elementHeight : 0}px`);
      }

      if (!!props.singleSelect && props.singleSelect.toString() === 'true') {
        const accordion = element.querySelector('div').closest('.accordion');
        const accordionItemsActive = accordion.querySelectorAll(
          `.accordion-item.active`,
        );
        Array.from(accordionItemsActive).forEach((item) => {
          item.querySelector('button.accordion-item-head').click();
        });
      }
    }
  };

  /**
   * Handler function for resize event.
   */
  const handleResize = () => {
    const accordionContent = document.querySelectorAll('.accordion-content');
    for (let i = 0; i <= accordionContent.length; i += 1) {
      if (accordionContent[i]) {
        const richText = accordionContent[i].querySelector('.rich-text');
        const height = richText.offsetHeight;
        accordionContent[i].setAttribute('height', height);
      }
    }
  };

  /**
   * Handler function for initial items collapse/expand for single selection.
   */
  const handleInitialSingleSelectionCollapse = () => {
    const { isFirstOpened } = props;
    if (isFirstOpened) {
      setOpen(true);
      const element = document.querySelector(`#${cId}`);
      /* istanbul ignore next */
      if (element) {
        const elementHeight = element.getAttribute('height') ?? 0;
        element.setAttribute('style', `height: ${elementHeight}px`);
      }
    }
  };

  /**
   * Single-run convenience effect to add resize handler, calculate item
   * height, and handle collapse if not opened.
   *
   * Note: To account for the brief time during which the styles are rendering
   * with the content of the page, adding setTimeout to ensure accurate height
   * is calculated and isn't too small of a value, as has been occurring.
   */
  React.useEffect(() => {
    setTimeout(() => {
      handleResize();
      if (props.isOpened) {
        if (props.singleSelect) {
          handleInitialSingleSelectionCollapse();
        } else {
          handleCollapse();
        }
      }
    }, 100);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <li
      className={`accordion-item${content ? ' has-content' : ''}${
        url ? ' cursor-pointer' : ''
      }${open ? ' active' : ''}`}
    >
      {Tag !== 'button' ? (
        <a
          className="item-link"
          href={implementUtmParams(url, window?.location)}
          onClick={handleAnchorClick}
          target={target || '_self'}
        >
          <ItemHead {...props} Tag={Tag} onClick={handleCollapse} />
        </a>
      ) : (
        <ItemHead {...props} Tag={Tag} onClick={handleCollapse} />
      )}

      {content ? (
        <div className="accordion-content" id={cId}>
          <RichText
            className="text-left text-paragraph_normal bg-white"
            content={content}
          />
        </div>
      ) : null}
    </li>
  );
};

const ItemHead = ({ allowedPlayIcon, icon, onClick, Tag, title }) => {
  return (
    <Tag className="accordion-item-head px-normal" onClick={onClick}>
      <h2 className="accordion-item-title my-none py-normal text-paragraph_large text-left text-weight-bold">
        {title}
      </h2>
      {Tag === 'button' ? (
        <svg
          viewBox="0 0 256 512"
          width="13px"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
            fill="currentColor"
          ></path>
        </svg>
      ) : null}
      {!!allowedPlayIcon && Tag !== 'button' ? (
        <svg
          className="play-icon"
          fill="none"
          height="33"
          viewBox="0 0 32 33"
          width="32"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            d="M16 29.3069C23.1797 29.3069 29 23.4866 29 16.3069C29 9.12718 23.1797 3.30688 16 3.30688C8.8203 3.30688 3 9.12718 3 16.3069C3 23.4866 8.8203 29.3069 16 29.3069ZM12.75 21.503L21.75 16.3069L12.75 11.1107L12.75 21.503Z"
            fill="#C6C6C6"
            fillRule="evenodd"
          />
        </svg>
      ) : null}
      {icon ? <Icon icon={icon} /> : null}
    </Tag>
  );
};

export default AccordionItem;
