/**
 * @module WeeklyEventsContent
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import _ from 'lodash';
import { DAYS_OF_WEEK } from '@lifechurch/web-tools-io/dist/utils/helpers';
import { formatTimeTo12h } from '@lifechurch/web-tools-io/dist/utils/helpers/date';
import Spans from '../IconSpans/Spans';
import EventDaysAndTimes from './EventDaysAndTimes';

/**
 * Represents the content of weekly events.
 *
 * @param {object} props - The component props object.
 * @param {object} props.weeklyEventTimes - The object containing weekly event times data.
 *
 * @returns {React.ReactElement} The WeeklyEventsContent component.
 */
export default function WeeklyEventsContent({ weeklyEventTimes }) {
  if (!weeklyEventTimes) {
    return null;
  }

  const weeklyNodes = weeklyEventTimes?.['@nodes'];
  const weeklySchedule = weeklyNodes?.reduce((acc, node) => {
    const eventTime = weeklyEventTimes?.[node] || {};
    const { dow, time, title } = eventTime;

    const day = DAYS_OF_WEEK[dow];
    if (day) {
      const currentEvent = acc[title] || {};
      const currentDow = currentEvent[day] || [];
      acc[title] = {
        ...currentEvent,
        [day]: [...currentDow, formatTimeTo12h(time)],
      };
    }

    return acc;
  }, {});

  return (
    <div
      className="display-flex flex-column row-gap-normal"
      data-testid="lc-weekly-events-content"
    >
      {Object.entries(weeklySchedule).map(([eventName, eventData]) => (
        <div className="poster-event" key={eventName}>
          <div className="poster-event-name">
            <span
              className={`icon ${
                eventName.includes('Switch') ? 'switch' : 'lc'
              }-circular-logo`}
            >
              <Spans />
            </span>
            <div
              dangerouslySetInnerHTML={{
                __html: _.replace(eventName, /~\//g, '/'),
              }}
            ></div>
          </div>
          <div className="display-flex flex-column row-gap-normal">
            <EventDaysAndTimes schedule={eventData} />
          </div>
        </div>
      ))}
    </div>
  );
}
