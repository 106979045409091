/**
 * @module PodcastFilter
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import {
  callGtagEvent,
  callSegmentTrack,
} from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import { Log } from '@lifechurch/web-tools-io/dist/utils/helpers/browserLogger';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import ButtonItem from '../ButtonItem/ButtonItem';
import { ACTIONS, CATEGORIES, EVENTS } from '../../helpers/constants';

const items = {
  '@nodes': [
    'filterBtn0',
    'filterBtn1',
    'filterBtn2',
    'filterBtn3',
    'filterBtn4',
    'filterBtn5',
  ],
  filterBtn0: {
    '@id': 'd4e590b3',
    buttonSize: 'large',
    darkVariants: false,
    style: 'btn-secondary',
    text: 'Habits & Goals',
    url: '/leadershippodcast/habits-and-goals/',
  },
  filterBtn1: {
    '@id': '4b3e3ca3',
    buttonSize: 'large',
    darkVariants: false,
    style: 'btn-secondary',
    text: 'Decision-Making',
    url: '/leadershippodcast/decision-making',
  },
  filterBtn2: {
    '@id': '0d9cfcef',
    buttonSize: 'large',
    darkVariants: false,
    style: 'btn-secondary',
    text: 'Leading Yourself',
    url: '/leadershippodcast/leading-yourself/',
  },
  filterBtn3: {
    '@id': 'bb70cb19',
    buttonSize: 'large',
    darkVariants: false,
    style: 'btn-secondary',
    text: 'Communication',
    url: '/leadershippodcast/communication/',
  },
  filterBtn4: {
    '@id': '9d84e9cd',
    buttonSize: 'large',
    darkVariants: false,
    style: 'btn-secondary',
    text: 'Team Culture',
    url: '/leadershippodcast/team-culture/',
  },
  filterBtn5: {
    '@id': '3f2f4082',
    buttonSize: 'large',
    darkVariants: false,
    style: 'btn-secondary',
    text: 'Productivity',
    url: '/leadershippodcast/productivity/',
  },
};

const PodcastFilter = () => {
  const { user } = useAuth();
  const filterBtns = items['@nodes'].map((node) => items?.[node]);
  const description =
    'Start growing with some of Craig’s most powerful leadership topics.';
  const title = 'Dive in.';

  /* istanbul ignore next */
  const handleButtonClick = (event) => {
    try {
      /* istanbul ignore next */
      callSegmentTrack({
        event: EVENTS.buttonAction,
        properties: {
          action: ACTIONS.clicked,
          component: 'Podcast Filter',
          component_url: event?.currentTarget?.getAttribute('href'),
          label: event?.currentTarget?.textContent,
          logged_in: !!user,
          preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
          referrer: document?.referrer || null,
          title: document?.title || '',
          url: window?.location?.href,
          user_id: user?.['https://www.life.church/rock_person_alias_id'],
        },
      });

      callGtagEvent({
        parameters: {
          event_category: CATEGORIES.podcastFilter,
          event_label: event.target.textContent,
        },
        type: 'click',
      });
    } catch (error) {
      Log.error(error);
    }
  };

  return (
    <div className="podcast-filter" data-testid="lc-podcast-filter">
      <div className="container">
        <div className="content maxWidth-768 text-center">
          <h1>{title}</h1>
          <p>{description}</p>
          <div className="filter-buttons display-flex flex-wrap justify-center gap-normal px-normal flex-column">
            {filterBtns.map((item) => (
              <ButtonItem
                buttonSize={item.buttonSize}
                darkVariant={item.darkVariants}
                key={item['@id']}
                onClick={handleButtonClick}
                style={item.style}
                text={item.text}
                url={item.url}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PodcastFilter;
