/**
 * @module mediaCollectionListingFetcher
 */
import { collectionTypeToPath } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/collectionTypeUtils';
import { getMagnoliaItem } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/getMagnoliaItem';
import { fetchInThisSeries } from './commonFetchers';
import {
  ENDPOINT_WORKSPACE_MAP,
  MAGNOLIA_ENDPOINTS,
  MGNL_ENV_VARS,
  PAGINATION_OPTIONS,
  WEB_DISTRIBUTION_PLATFORM_UUID,
} from '../constants';

/**
 * Convenience function to retrieve and return a data object of media collection listing.
 *
 * @param {object} data - The data object used for media collection listing retrieval.
 *
 * @returns {object} The media collection listing data object.
 */
async function fetchMediaCollectionListing(data) {
  // Retrieve props from data object and assemble limit and offset query strings
  // if legitimate values are found.
  const {
    limit = PAGINATION_OPTIONS.mediaCollectionListingFetcher.limit,
    mediaCollectionType,
    offset = PAGINATION_OPTIONS.mediaCollectionListingFetcher.offset,
  } = data;
  let limitQuery =
    limit && !Number.isNaN(parseInt(limit, 10)) ? `&limit=${limit}` : '';
  let offsetQuery =
    offset && !Number.isNaN(parseInt(offset, 10)) ? `&offset=${offset}` : '';
  const supportedMediaCollectionTypes = [
    'crosstown',
    'early_childhood',
    'early-childhood',
    'konnect',
    'life_groups',
    'lifegroups',
    'loop',
    'messages',
    'stories',
    'switch',
    'weekend_message',
    'worship',
  ];
  const isSupportedTypeForPagination =
    supportedMediaCollectionTypes.includes(mediaCollectionType);
  if (!isSupportedTypeForPagination) {
    limitQuery = '';
    offsetQuery = '';
  }
  const response = await getMagnoliaItem({
    caller:
      'src/helpers/dataFetchers/mediaCollectionListingFetcher.js > fetchMediaCollectionListing',
    mgnlEnvVars: MGNL_ENV_VARS,
    path: encodeURI(
      `${
        MAGNOLIA_ENDPOINTS.delivery.mediaCollections
      }?@ancestor=/${collectionTypeToPath(
        mediaCollectionType,
      )}/&availablePlatforms[eq]=${WEB_DISTRIBUTION_PLATFORM_UUID}${limitQuery}${offsetQuery}&orderBy=startDate desc`,
    ),
    workspaceMap: ENDPOINT_WORKSPACE_MAP,
  }); // NOSONAR

  /**
   * With the introduction to pagination, the logic is now updated to include:
   * - Set pagination object based on response.
   * - Set latest to first of results array ONLY IF response offset is 0.
   * - Set past to items 1 through 10 (9 total) ONLY IF response offset is 0.
   * - Set past to items 1 through end ONLY IF response offset is 0 and results length is less than 10.
   * - Set past to all results ONLY IF response offset is not 0.
   *
   * This logic ensures we only set latest if at the start of the results, and
   * allows for divisible-by-3 result totals (unless at the end).
   */
  const pagination = {
    limit: response?.limit ?? 0,
    offset: response?.offset ?? 0,
    total: response?.total ?? 0,
  };

  // Depending on the page/media collection type, different default values are
  // desired for initial 0-offset-based returns based on the layout of the page.
  // For example, media/messages (weekend_message collection type) has rows of
  // three items, whereas worship/albums (worship collection type) has rows of
  // four items. Returning the same for all would result in inconsistent rows.
  const mediaTypeComparator = mediaCollectionType === 'worship' ? 9 : 10;
  const results = response?.results ?? [];
  let latest = results?.[0];
  let past = results.slice(1);

  if (isSupportedTypeForPagination) {
    latest = pagination.offset === 0 ? results[0] : null;
    if (pagination.offset === 0 && results.length >= mediaTypeComparator) {
      past = results.slice(1, mediaTypeComparator);
      pagination.next = mediaTypeComparator;
    } else if (pagination.offset === 0) {
      past = results.slice(1);
      pagination.next = results.length;
    } else {
      past = results;
      pagination.next = pagination.offset + results.length;
    }
  }

  let inThisSeries;
  if (data.mediaCollectionType !== 'worship' && latest) {
    inThisSeries = await fetchInThisSeries({
      data: latest,
      getMoreItems: true,
      mediaType: data?.mediaCollectionType,
    });
  }

  return {
    inThisSeries,
    latestCollection: latest,
    pagination: isSupportedTypeForPagination ? pagination : null,
    pastCollections: past,
  };
}

export { fetchMediaCollectionListing };
