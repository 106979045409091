import React from 'react';
import { EditorContextHelper } from '@magnolia/react-editor';

const SelectOptions = ({ displayName, value, autoSelect }) => {
  const isDevMode = EditorContextHelper.inIframe();
  return (
    <option
      value={value}
      selected={`${
        !!autoSelect && autoSelect == 'true' ? 'selected' : 'false'
      }`}
    >
      {displayName}{' '}
      {!!isDevMode &&
        !!autoSelect &&
        autoSelect == 'true' &&
        '( Default selected option )'}
    </option>
  );
};

export default SelectOptions;
