/**
 * @module Search
 */
import React from 'react';
import './Search.scss';
import EmbedItem from '../EmbedItem/EmbedItem';

const Search = () => {
  const type = {
    field: 'iframe',
    iframeSource: process.env.LIFEGROUP_SEARCH_DOMAIN,
  };

  /**
   * Handler function for LifeGroups Search messages.
   *
   * @param {string} data - The data string used to append to URL.
   */
  function handleLifeGroupsMessage(data) {
    if (data) {
      window.history.replaceState(
        null,
        null,
        `${window.location.pathname}?${data}`, // NOSONAR
      );
    }
  }

  /**
   * Convenience function to call Segment tracking.
   *
   * @param {object} data - The data object used to populate analytics information.
   *
   * @see {@link https://segment.com/docs/connections/spec/page/}.
   * @see {@link https://segment.com/docs/connections/spec/screen/}.
   * @see {@link https://segment.com/docs/connections/spec/track/}.
   */
  function handleSegmentMessage(data) {
    // Note: Ignore only for edge case of data; all cases of type covered.
    /* istanbul ignore next */
    if (data && typeof data === 'object') {
      switch (data.type) {
        case 'page':
          try {
            window.analytics.page(data.category, data.name, data.properties);
          } catch (error) {
            // Silent failure is acceptable.
          }
          break;
        case 'screen':
          try {
            window.analytics.screen(data.name, data.properties);
          } catch (error) {
            // Silent failure is acceptable.
          }
          break;
        case 'track':
          try {
            window.analytics.track(data.event, data.properties);
          } catch (error) {
            // Silent failure is acceptable.
          }
          break;
        default:
          break;
      }
    }
  }

  React.useEffect(() => {
    const acceptedDomains = ['.life.church', 'lifechurch.io'];
    window.addEventListener('message', (message) => {
      if (!message?.origin) {
        return;
      }
      const { hostname } = new URL(message.origin);
      const validDomain = acceptedDomains.find((domain) =>
        hostname.endsWith(domain),
      );
      const channel = message?.data?.channel;
      if (!validDomain || !channel) {
        return;
      }
      switch (channel) {
        case 'lifegroups':
          handleLifeGroupsMessage(message.data.data);
          break;
        case 'segment':
          handleSegmentMessage(message.data.data);
          break;
        default:
          break;
      }
    });

    if (
      window.location.pathname.includes('lifegroups/search') &&
      window.location.search.length > 0
    ) {
      /**
       * Note: No need to cover instance of iframe not being present, as this is
       * just a bulletproof edge case prevention measure and conditional check.
       */
      /* istanbul ignore next */
      const iframe = document.getElementById('iframe-lifegroups-search-form');
      /* istanbul ignore next */
      if (iframe) {
        const iframeUrl = iframe.getAttribute('src') + window.location.search;
        iframe.setAttribute('src', iframeUrl);
      }
    }
  }, []);

  return (
    <section className="search-form" data-testid="lg-search-container">
      <EmbedItem
        id={'lifegroups-search'}
        sectionId={'lifegroups-search-form'}
        type={type}
      />
    </section>
  );
};

export default Search;
