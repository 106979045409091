/**
 * @module LaunchedLatestMessageV2
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import { Log } from '@lifechurch/web-tools-io/dist/utils/helpers/browserLogger';
import { getMagnoliaItem } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/getMagnoliaItem';
import { mediaUrlFromType } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/mediaTypeUtils';
import { getUrlStructureByData } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/urls';
import { implementUtmParams } from '@lifechurch/web-tools-io/dist/utils/helpers/utmParams';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import Spans from '../../IconSpans/Spans';
import LCBackground from '../../LCImage/LCBackground';
import {
  ACTIONS,
  ENDPOINT_WORKSPACE_MAP,
  EVENTS,
  MGNL_ENV_VARS,
  WEB_DISTRIBUTION_PLATFORM_UUID,
} from '../../../helpers/constants';
import './LaunchedLatestMessageV2.scss';

/**
 * Represents the Latest Message section for a launched campus location page.
 *
 * @param {object} props - The component props object.
 * @param {object} props.locationData - The location data object.
 *
 * @returns {React.Component} The LaunchedLatestMessageV2 component.
 */
export default function LaunchedLatestMessageV2({
  locationData,
  ...passThroughProps
}) {
  const { user } = useAuth();
  const [latestMessage, setLatestMessage] = React.useState(null);

  React.useEffect(() => {
    async function fetchLatestMessage() {
      try {
        const response = await getMagnoliaItem({
          caller:
            'src/components/LocationLaunched/V2/LaunchedLatestMessageV2.js > fetchLatestMessage',
          forceCall: passThroughProps?.testOverride,
          mgnlEnvVars: MGNL_ENV_VARS,
          path: encodeURI(
            `/.rest/delivery/v1/collections?@ancestor=/messages/&availablePlatforms[eq]=${WEB_DISTRIBUTION_PLATFORM_UUID}&orderBy=startDate desc, mgnl:lastActivated desc&limit=1`,
          ),
          workspaceMap: ENDPOINT_WORKSPACE_MAP,
        }); // NOSONAR
        const latest = response?.results?.[0] ?? {};

        const { superHeroImage_public, cinemaImage_public } =
          latest.channelImageList;

        /* istanbul ignore next*/
        const thumbnail =
          latest?.featuredImage?.['@link'] ?? cinemaImage_public;
        const urlStructure = getUrlStructureByData(latest);
        const mediaURL = mediaUrlFromType(
          urlStructure.collection,
          urlStructure.subCollection,
          urlStructure.item,
        );
        setLatestMessage({
          backgroundImage: superHeroImage_public,
          mediaURL,
          thumbnail,
        });
      } catch (error) {
        /* istanbul ignore next*/
        Log.error(error);
      }
    }

    fetchLatestMessage(); // NOSONAR
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Handler function for button click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleButtonClick(event) {
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Location Launched - Latest Message',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  return (
    <>
      {locationData && latestMessage ? (
        <section
          className="location-latest-message-v2"
          data-testid="lc-launched-latest-message-v2"
        >
          <LCBackground
            className="latest-message-bg"
            src={latestMessage.backgroundImage}
          >
            <div className="backdrop" />
          </LCBackground>
          <div className="container">
            <div className="latest-message-header text-center">
              <h2>{locationData.title}</h2>
            </div>
            <div className="content">
              <div>
                <img
                  alt="Latest Message thumbnail"
                  src={latestMessage.thumbnail}
                />
                <a
                  className="latest-message-play-btn"
                  href={implementUtmParams(
                    `${window.location.origin}${latestMessage.mediaURL}/`,
                    window?.location,
                  )}
                  onClick={handleButtonClick}
                  rel="noreferrer"
                  target="_blank"
                >
                  <span className="icon icon-play">
                    <Spans />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
}
