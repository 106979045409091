/* eslint-disable react/jsx-props-no-spreading */
/**
 * @module LocationLaunched
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { LOCATIONS } from '../../helpers/constants';
import LocationLaunchedV1 from './LocationLaunchedV1';
import LocationLaunchedV2 from './LocationLaunchedV2';
import './LocationLaunched.scss';

const LocationLaunched = (props) => {
  const showLaunchedV2 = LOCATIONS?.launchedV2?.includes(props.campusCode);

  return showLaunchedV2 ? (
    <LocationLaunchedV2 {...props} />
  ) : (
    <LocationLaunchedV1 {...props} />
  );
};

export default LocationLaunched;
