/**
 * @module PodcastMediaButton
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import ButtonItem from '../ButtonItem/ButtonItem';

const PodcastMediaButton = ({
  buttonSize = 'large',
  iconClass,
  onClick,
  text,
  url,
}) => {
  return (
    <ButtonItem
      buttonSize={buttonSize}
      icon={{ channelIcon: iconClass, field: 'channel-icons' }}
      iconPosition="icon-to-left"
      iconRenderAsBg={true}
      onClick={onClick}
      style="btn-channel"
      target="_blank"
      text={text}
      url={url}
    />
  );
};

export default PodcastMediaButton;
