/**
 * @module PodcastCollectionSubscribe
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import LCBackground from '../LCImage/LCBackground';
import PodcastMediaButton from './PodcastMediaButton';
import { ACTIONS, EVENTS } from '../../helpers/constants';

const PodcastCollectionSubscribe = (props) => {
  const { user } = useAuth();
  const { applePodcastURL, label, spotifyURL, youtubeURL } = props;
  const mobileBg = `${process.env.IMGIX_BASE_URL}/Media/Craig-Groeschel-Leadership-Podcast/iphone-small.png`;
  const desktopBg = `${process.env.IMGIX_BASE_URL}/Media/Craig-Groeschel-Leadership-Podcast/iphone-medium.png`;

  const podcastIcons = [
    {
      iconSlug: 'apple-podcasts',
      label: 'Apple Podcasts',
      url: applePodcastURL,
    },
    {
      iconSlug: 'spotify',
      label: 'Spotify',
      url: spotifyURL,
    },
    {
      iconSlug: 'youtube',
      label: 'YouTube',
      url: youtubeURL,
    },
    {
      iconSlug: 'LC-app-listen',
      label: 'Life.Church App',
      url: 'https://www.life.church/app/',
    },
  ];

  /**
   * Handler function for button click.
   *
   * @param {Event} event - The Event object associated with the click.
   * @param {string} [labelValue] - The label value to use in the analytics properties data object.
   */
  function handleButtonClick(event, labelValue) {
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Podcast Collection Subscribe',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: labelValue || event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  return (
    <div
      className="podcast-collection-subscribe text-white text-center py-slightly_more_relaxed py-tablet-even_more_relaxed py-laptop-very_relaxed mb-auto mb-tablet-very_relaxed"
      data-testid="lc-podcast-collection-subscribe"
    >
      <LCBackground
        className="iphone-img has-bg-image container"
        imgixParams={{ fit: 'clip' }}
        src={props.isMobile ? mobileBg : desktopBg}
      ></LCBackground>
      <div className="container">
        <h2 className="title">{label.subscribeTitle}</h2>
        <div className="button-group-wrapper display-flex">
          <ul className="button-item-listing channel-buttons-group full-width">
            {podcastIcons.map((podcastIcon) => {
              return podcastIcon.url ? (
                <PodcastMediaButton
                  iconClass={`icon-channel-${podcastIcon.iconSlug}`}
                  key={`podcast-icon-${podcastIcon.iconSlug}`}
                  onClick={(event) =>
                    handleButtonClick(event, podcastIcon.label)
                  }
                  target="_blank"
                  url={podcastIcon.url}
                />
              ) : null;
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PodcastCollectionSubscribe;
