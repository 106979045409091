/**
 * @module PrelaunchedHeroV2
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import { ACTIONS, EVENTS } from '../../../helpers/constants';
import Button from '../../ButtonItem/ButtonItem';
import LCBackground from '../../LCImage/LCBackground';

/**
 * Represents the new Hero section.
 *
 * @param {object} props - The component props object.
 * @param {Function} props.handleOpenModal - Handler function for modal open event.
 * @param {LocationData} props.locationData - The location data object for the Hero section.
 *
 * @returns {React.ReactElement} The PrelaunchedHeroV2 component.
 */
export default function PrelaunchedHeroV2({ handleOpenModal, locationData }) {
  const { user } = useAuth();
  const { campusImage, description, label, name, subtitle } = locationData;

  /**
   * Handler function for button click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleButtonClick(event) {
    handleOpenModal(event);

    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Location Pre-Launched - Header',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }
  return (
    <section className="prelaunched-hero" data-testid="lc-prelaunched-hero-v2">
      <LCBackground className="hero-bg" src={campusImage}>
        <div className="container">
          <div className="hero-content text-white text-center">
            {name ? <h1>{`Life.Church\n${name}`}</h1> : null}
            {subtitle ? <h2>{subtitle}</h2> : null}
            {description ? <p>{description}</p> : null}
            {label ? (
              <div className="hero-btn mt-normal">
                <Button
                  buttonSize="medium"
                  darkVariant="true"
                  onClick={handleButtonClick}
                  style="btn-primary"
                  target=""
                  text={label}
                  url="#"
                />
              </div>
            ) : null}
          </div>
        </div>
      </LCBackground>
    </section>
  );
}
