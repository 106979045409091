/**
 * @module LaunchedLifeChurchIsForYouV2
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import useWindowSize from '@lifechurch/web-tools-io/dist/hooks/useWindowSize';
import Button from '../../ButtonItem/ButtonItem';
import ForYouSharedSection from './ForYouSharedSection';
import switchLogo from '../../LocationV2/assets/SwitchLogo.svg';
import { ACTIONS, CATEGORIES, EVENTS } from '../../../helpers/constants';
import './LaunchedLifeChurchIsForYouV2.scss';

/**
 * Represents the Life.Church is For You section for a launched campus location page.
 *
 * @param {object} props - The component props object.
 * @param {object} [props.forYouData] - The data object for the For You section.
 * @param {object} [props.forYourCommunityData] - The data object for the For Your Community section.
 * @param {object} [props.forYourFamilyData] - The data object for the For Your Family section.
 * @param {object} [props.lcSwitchData] - The data object for the LifeChurch Switch section.
 *
 * @returns {React.Component} The LaunchedLifeChurchIsForYouV2 component.
 */
export default function LaunchedLifeChurchIsForYouV2({
  forYouData,
  forYourCommunityData,
  forYourFamilyData,
  lcSwitchData,
}) {
  const { user } = useAuth();
  const { isMobile, isDesktop } = useWindowSize();

  /**
   * Handler function for button click.
   *
   * @param {string} event - The Event object associated with the click.
   * @param {string} category - The event properties category value.
   */
  function handleButtonClick(event, category) {
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: `Location Launched - ${category}`,
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  return (
    <section
      className="location-lc-for-you-v2"
      data-testid="lc-launched-life-church-is-for-you-v2"
    >
      {forYouData ? (
        <ForYouSharedSection
          actionBtns={[
            {
              onClick: (event) => {
                handleButtonClick(event, CATEGORIES.lifeChurchIsForYou);
              },
              style: 'btn-secondary',
              text: 'About Life.Church',
              url: 'https://www.life.church/who-we-are/',
            },
          ]}
          className="for-you"
          sectionData={forYouData}
        />
      ) : null}

      {forYourFamilyData ? (
        <ForYouSharedSection
          actionBtns={[
            {
              onClick: (event) => {
                handleButtonClick(event, CATEGORIES.lifeChurchIsForYourFamily);
              },
              text: 'Pre-Register For LifeKids',
              url: `https://my.life.church/forms/family/?source=web&referrer=${window.location.origin}${window.location.pathname}&location=${forYourFamilyData.campusCode}`,
            },
            {
              onClick: (event) => {
                handleButtonClick(event, CATEGORIES.lifeChurchIsForYourFamily);
              },
              style: 'btn-secondary',
              text: 'About LifeKids',
              url: 'https://www.life.church/kids/',
            },
          ]}
          className="for-your-family"
          sectionData={forYourFamilyData}
        />
      ) : null}

      {lcSwitchData ? (
        <div className="lc-switch bg-black text-white">
          <div className="container">
            {isMobile ? (
              <img alt="LifeChurch Switch" src={lcSwitchData.img} />
            ) : null}
            <div className="content">
              {isDesktop ? <img alt="Switch logo" src={switchLogo} /> : null}
              <h2>{lcSwitchData.subtitle}</h2>
              <p>{lcSwitchData.description}</p>
              {lcSwitchData.serviceEventTimes.size > 0 ? (
                <div className="switch-events">
                  {[...lcSwitchData.serviceEventTimes].map(([day, hours]) => (
                    <div className="text-white" key={day}>
                      <h3>{day}</h3>
                      <div className="display-flex column-gap-tighter">
                        {[...hours].map((hour) => (
                          <span key={hour}>{hour}</span>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}
              <div>
                <Button
                  buttonSize="medium"
                  darkVariant="true"
                  onClick={(event) => {
                    handleButtonClick(event, CATEGORIES.switch);
                  }}
                  style="btn-secondary"
                  text="About Switch"
                  url="https://www.life.church/switch/"
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {forYourCommunityData ? (
        <ForYouSharedSection
          actionBtns={[
            {
              onClick: (event) => {
                handleButtonClick(
                  event,
                  CATEGORIES.lifeChurchIsForYourCommunity,
                );
              },
              style: 'btn-secondary',
              text: 'About Local Missions',
              url: 'https://www.life.church/missions/',
            },
          ]}
          className="for-your-community"
          sectionData={forYourCommunityData}
        />
      ) : null}
    </section>
  );
}
