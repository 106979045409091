/**
 * @module OpenContentListArticle
 */
import React from 'react';
import { fetchArticleCard } from '../../helpers/dataFetchers/findsFetchers';
import OpenContentListItem from '../OpenContentListItem/OpenContentListItem';

const OpenContentListArticle = (props) => {
  const {
    article,
    backgroundSize,
    linkTitle,
    preload,
    type,
    wholeCardLink = 'false',
  } = props;
  const [data, setData] = React.useState(preload);

  React.useEffect(() => {
    async function fetchData() {
      if (!preload) {
        setData(await fetchArticleCard({ article }));
      } else {
        setData(preload);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [article]);

  const getArticleURL = () => {
    const baseURL =
      process.env.FINDS_REACT_APP_CMS || 'https://finds.life.church/';
    const slug = data?.['@name'];
    return `${baseURL}${slug}`;
  };

  return data ? (
    <OpenContentListItem
      alt={data['@name']}
      backgroundSize={backgroundSize}
      content={`<p>${data.description}</p>`}
      description={data.description}
      image={data.featuredImage}
      linkTitle={linkTitle}
      subTitle={[data.author, data.duration].filter(Boolean).join(' • ')}
      title={data.title}
      type={type}
      url={getArticleURL()}
      wholeCardLink={wholeCardLink}
    />
  ) : null;
};

export default OpenContentListArticle;
