/**
 * @module Banner
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import { convertValueToClassName } from '@lifechurch/web-tools-io/dist/utils/helpers/validators';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import useWindowSize from '@lifechurch/web-tools-io/dist/hooks/useWindowSize';
import Button from '../ButtonItem/ButtonItem';
import RichText from '../RichText/RichText';
import { ACTIONS, EVENTS } from '../../helpers/constants';
import './Banner.scss';

const Banner = ({
  buttonTarget,
  bannerContent,
  buttonName,
  buttonLink,
  sbOnMobile,
  sbOnTabletAndUp,
  sectionId,
}) => {
  const { user } = useAuth();
  const { isMobile } = useWindowSize();
  const containerClass = `banner-wrapper ${
    isMobile
      ? convertValueToClassName(sbOnMobile)
      : convertValueToClassName(sbOnTabletAndUp)
  }`.trim();

  /**
   * Handler function for item anchor tag click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleButtonClick(event) {
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Banner',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  return (
    <div
      className={containerClass}
      data-testid={`lc-banner-${sectionId}`}
      id={sectionId}
    >
      <div
        className={`banner-content-and-button container px-relaxed ${
          isMobile && buttonName ? 'py-relaxed' : 'pt-relaxed'
        }`}
      >
        {!!bannerContent && (
          <RichText className="banner-content" content={bannerContent} />
        )}
        {!!buttonName && (
          <Button
            className="btn-small text-decoration-none"
            onClick={handleButtonClick}
            style="btn-primary"
            target={buttonTarget}
            text={buttonName}
            url={buttonLink}
          />
        )}
      </div>
    </div>
  );
};

export default Banner;
