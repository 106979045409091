/**
 * @module AboutLifeChurch
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import ButtonItem from '../ButtonItem/ButtonItem';
import './AboutLifeChurch.scss';

export const aboutLifeChurchContent = {
  body: `Our mission is to lead people to become fully devoted followers of Christ. That's how we're able to make a difference. And it's the driving force behind what we do.`,
  ctaLabel: 'Learn More',
  ctaUrl: `https://www.life.church/who-we-are/?utm_source=life.church&utm_medium=website&utm_campaign=Life.Church`,
  title: `About Life.Church`,
};

/**
 * Represents a simple full-width container with title, body, and Learn More button with information about Life.Church.
 *
 * @param {object} props - The component props object.
 * @param {Function} props.onLearnMoreClick - Handler function for Learn More button click.
 *
 * @returns {React.ReactElement} The AboutLifeChurch component.
 */
function AboutLifeChurch({ onLearnMoreClick }) {
  return (
    <div className="about-life-church">
      <div className="contents text-center">
        <h2 className="mt-none text-group_header">
          {aboutLifeChurchContent.title}
        </h2>
        <p className="text-paragraph_large mt-none">
          {aboutLifeChurchContent.body}
        </p>
        <ButtonItem
          buttonSize="medium"
          className="align-center"
          onClick={onLearnMoreClick}
          style="btn-secondary"
          text={aboutLifeChurchContent.ctaLabel}
          url={aboutLifeChurchContent.ctaUrl}
        />
      </div>
    </div>
  );
}

export default AboutLifeChurch;
