/**
 * @module LocationPosterAddress
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import LocationPosterSpan from '../../LocationPoster/LocationPosterSpan';

/**
 * Represents markup of a location address.
 *
 * Note: The markup returned for Special Event Times location address and phone
 * is slightly different than that of a normal Location Poster component. As
 * such, this component-specific instance was created to ensure full compliance
 * with the design, styling, and functionality needed.
 *
 * @param {object} props - The component props object.
 * @param {Address} props.address - The location address object.
 * @param {boolean} props.enableAsLink - Boolean flag denoting whether or not to wrap the address in an anchor tag when coordinates are set on the address object.
 * @param {Function} props.onClick - Handler function for link click event.
 *
 * @returns {React.ReactElement} The LocationPosterAddress component with location markup.
 */
export default function LocationPosterAddress({
  address,
  enableAsLink = true,
  onClick,
}) {
  if (!address) {
    return null;
  }

  const {
    cityOrTown,
    latitude,
    longitude,
    secondaryCityOrTown,
    secondaryLatitude,
    secondaryLongitude,
    secondaryState,
    secondaryStreet1,
    secondaryStreet2,
    secondaryZip,
    state,
    street1,
    street2,
    zip,
  } = address;
  const locationCoordinates = {
    latitude: latitude || secondaryLatitude,
    longitude: longitude || secondaryLongitude,
  };

  const locationAddressContent = (
    <>
      <LocationPosterSpan
        addLineBreak={true}
        text={street1 || secondaryStreet1}
      />
      <LocationPosterSpan
        addLineBreak={true}
        text={street2 || secondaryStreet2}
      />
      <LocationPosterSpan
        endWith={', '}
        text={cityOrTown || secondaryCityOrTown}
      />
      <LocationPosterSpan endWith={' '} text={state || secondaryState} />
      <LocationPosterSpan text={zip || secondaryZip} />
    </>
  );

  return (
    <span className="address" data-testid="set-location-poster-address">
      {locationCoordinates.latitude &&
      locationCoordinates.longitude &&
      enableAsLink ? (
        <a
          href={`https://www.google.com/maps/dir//${latitude},${longitude}`}
          onClick={onClick}
          rel="noreferrer"
          target="_blank"
        >
          {locationAddressContent}
        </a>
      ) : (
        <span>{locationAddressContent}</span>
      )}
    </span>
  );
}
