/**
 * @module MediaCollectionSeriesBased
 */
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import useWindowSize from '@lifechurch/web-tools-io/dist/hooks/useWindowSize';
import AboutLifeChurch from '../../AboutLifeChurch/AboutLifeChurch';
import ButtonItem from '../../ButtonItem/ButtonItem';
import RichText from '../../RichText/RichText';
import ContentAndForm from '../../MediaSharedComponents/ContentAndForm';
import InThisSeries from '../../MediaSharedComponents/InThisSeries';
import FindArticleListing from '../../MediaSharedComponents/FindArticleListing';
import RelatedContentCarousel from '../../MediaSharedComponents/RelatedContentCarousel';
import BitmovinPlayer from '../../BitmovinPlayer/BitmovinPlayer';
import Modal from '../../Modal/Modal';
import LCBackground from '../../LCImage/LCBackground';
import { ACTIONS, EVENTS } from '../../../helpers/constants';
import '../../MediaSharedComponents/style/_MediaComponentSharedStyle.scss';
import '../../OpenContentList/OpenContentList.scss';

/**
 * Component for showing series based media collections.
 *
 * @param {object} props - The component props object.
 * @param {object} props.data - The data of the series-based media collection.
 * @param {object} props.preload - The preloaded data of the series-based media collection.
 *
 * @returns {React.ReactElement} The MediaCollectionSeriesBased component.
 */
function MediaCollectionSeriesBased({ data, preload }) {
  const {
    channelImageList,
    talkItOver,
    promo_url,
    hasPromoVideo,
    findHashtags,
  } = data;
  const { user } = useAuth();
  const { inThisSeries, relatedContent } = preload;
  const { isMobile } = useWindowSize();
  const [showPromoVideo, setShowPromoVideo] = React.useState(false);
  const [openTalkItOver, setOpenTalkItOver] = React.useState(false);

  const mediaDetails = {
    id: preload['@id'] ?? data['@id'] ?? '',
    title: preload.title ?? data.title ?? '',
  };

  const superHeroImageLink = channelImageList?.superHeroImage_public ?? '';

  const modal = {
    modalType: {
      field: 'videoModal',
      videoUrl: promo_url,
    },
  };

  /**
   * Convenience function to trigger Segment analytics tracking.
   *
   * @param {Event} event - The Event object.
   * @param {string} label - The label value to use in the analytics properties object.
   */
  function callAnalytics(event, label) {
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Media Collection',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: label || event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  // Handler Functions.
  const handleButtonWatchPromoClick = (event) => {
    setShowPromoVideo(true);
    callAnalytics(event);
  };

  const handleOpenTalkItOver = (event) => {
    event.preventDefault();
    /**
     * Note: When openTalkItOver truthy, it means its open, thus the following
     * logic uses 'Close' when it's true, as the event would be to close it.
     */
    /* istanbul ignore next */
    callAnalytics(event, `${openTalkItOver ? 'Close' : 'Show'} Talk It Over`);
    setOpenTalkItOver(!openTalkItOver);
  };

  /**
   * Handler function for About Life.Church Learn More button click event.
   *
   * Note: Condition to preventDefault() in place to avoid window location
   * change for test-induced link click, and as such, is safe to ignore.
   *
   * @param {Event} event - The Event data object associated with the click.
   */
  function handleAboutLifeChurchLearnMoreClick(event) {
    /* istanbul ignore next */
    if (
      process.env.IS_JEST_ENV &&
      process.env.IS_JEST_ENV.toString() === 'true'
    ) {
      event.preventDefault();
    }
    callAnalytics(event);
  }

  return (
    <section
      className="media-collection-series-based"
      data-testid="lc-media-collection-series-based"
    >
      <div
        className={`promo-wrapper ${
          isMobile ? 'mb-bit_more_relaxed' : 'mb-slightly_more_relaxed'
        }`}
      >
        {superHeroImageLink ? (
          <LCBackground
            backup={'super-hero-img'}
            className={`promo-hero`}
            src={superHeroImageLink}
          />
        ) : null}

        {showPromoVideo && promo_url ? (
          <Modal
            data={modal}
            setShowStatus={setShowPromoVideo}
            showStatus={showPromoVideo}
          >
            <BitmovinPlayer mediaData={mediaDetails} videoUrl={promo_url} />
          </Modal>
        ) : null}

        {hasPromoVideo ? (
          <ButtonItem
            className="align-center watch-promo"
            icon={{ field: 'general-icons', generalIcon: 'icon-play' }}
            onClick={handleButtonWatchPromoClick}
            style="btn-primary"
            text="Watch Promo"
          />
        ) : null}
      </div>

      {data ? (
        <ContentAndForm
          data={data}
          isMobile={isMobile}
          onTalkItOverClick={handleOpenTalkItOver}
          showSocialShare={false}
        />
      ) : /* istanbul ignore next */ null}

      {inThisSeries ? (
        <InThisSeries
          preload={inThisSeries}
          showAsCarousel={true}
          showCount={true}
        />
      ) : null}

      <AboutLifeChurch onLearnMoreClick={handleAboutLifeChurchLearnMoreClick} />

      {findHashtags ? (
        <FindArticleListing data={data} isMobile={isMobile} />
      ) : null}

      {relatedContent ? (
        <RelatedContentCarousel
          data={data}
          hasBgColor={false}
          isMobile={isMobile}
          preload={relatedContent}
        />
      ) : null}

      {openTalkItOver && talkItOver ? (
        <Modal
          className="tio-modal"
          setShowStatus={() => {
            setOpenTalkItOver(false);
          }}
          showStatus={true}
        >
          <div className="talk-it-over container py-normal">
            <div className="talk-it-over-content">
              <div className="talk-it-over-item">
                <RichText
                  className="tio-content"
                  content={talkItOver.richText}
                />
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
    </section>
  );
}

export default MediaCollectionSeriesBased;
