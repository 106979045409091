/**
 * @module Heading
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { convertValueToClassName } from '@lifechurch/web-tools-io/dist/utils/helpers/validators';
import useWindowSize from '@lifechurch/web-tools-io/dist/hooks/useWindowSize';
import { isColorSettingValidClassName } from '../../helpers/classNames';
import { implementAnchorTagAttributes } from '../../helpers/scriptHelper';
import './Heading.scss';

const Heading = (props) => {
  const {
    headingText,
    headingLevel,
    headingType,
    sbOnMobile,
    sbOnTabletAndUp,
    alignment = '',
    colorsetting,
    sectionId,
  } = props;
  const { isMobile } = useWindowSize();
  const [markup, setMarkup] = React.useState('');
  const Tag = `${headingLevel}`;
  const colorSettingClass = convertValueToClassName(
    isColorSettingValidClassName(colorsetting) ? colorsetting : '',
  );
  const containerClass =
    `heading container rich-text text-${convertValueToClassName(
      headingType,
    )} ${colorSettingClass} ${convertValueToClassName(alignment)} ${
      isMobile
        ? convertValueToClassName(sbOnMobile)
        : convertValueToClassName(sbOnTabletAndUp)
    }`.trim();

  /**
   * Convenience effect to convert content to implement anchor tag attributes on
   * content set to be injected via dangerouslySetInnerHTML.
   */
  React.useEffect(() => {
    setMarkup(implementAnchorTagAttributes(headingText, 'Heading'));
  }, [headingText]);

  return (
    <Tag
      className={containerClass}
      dangerouslySetInnerHTML={{ __html: markup }}
      id={sectionId}
    ></Tag>
  );
};

export default Heading;
