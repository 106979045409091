/**
 * @module Select
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { EditableArea, EditorContextHelper } from '@magnolia/react-editor';
import SelectOptions from './SelectOptions';
import './Select.scss';

const Select = ({ hint, items, label, metadata, selectName }) => {
  const isDevMode = EditorContextHelper.inIframe();

  return (
    <div className="select">
      <label>{label}</label>
      <div className="select-input">
        {!isDevMode && (
          <select name={selectName}>
            <option hidden={true}></option>
            {items['@nodes'].map((node) => (
              <SelectOptions
                autoSelect={items[node].autoSelect}
                displayName={items[node].displayName}
                key={items[node]['@id']}
                sbOnMobile={items[node].sbOnMobile}
                sbOnTabletAndUp={items[node].sbOnTabletAndUp}
                value={items[node].value}
              />
            ))}
          </select>
        )}
        <svg
          fill="none"
          height="19"
          viewBox="0 0 20 19"
          width="20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M16 7L10 13L4 7" stroke="#404041" strokeWidth="2" />
        </svg>
      </div>
      {!!isDevMode && (
        <EditableArea
          content={items}
          parentTemplateId={metadata['mgnl:template']}
        />
      )}
      <span>{hint}</span>
    </div>
  );
};

export default Select;
