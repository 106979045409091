/**
 * @module PodcastHero
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import MediaYouTubeOrFallBackPlayer from './MediaYouTubeOrFallBackPlayer';

const PodcastHero = (props) => {
  return (
    <div
      className="podcast-hero mb-bit_more_relaxed mb-tablet-very_relaxed mb-laptop-slightly_abit_more_relaxed"
      data-testid="lc-podcast-hero"
    >
      <MediaYouTubeOrFallBackPlayer {...props} />
    </div>
  );
};

export default PodcastHero;
