/**
 * @module LocationButton
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import { FacebookIcon, InstagramIcon, ShareIcon } from './ButtonIcons';
import { ACTIONS, EVENTS } from '../../helpers/constants';
import './LocationButton.scss';

/**
 * Not necessary to cover defaults, as each instance passes in data for each.
 * However, leaving default values in place as failsafe.
 */
/* istanbul ignore next */
const Button = ({
  Icon = '',
  onClick = null,
  target = '',
  text = '',
  url = '#',
}) => (
  <a className="btn-location" href={url} onClick={onClick} target={target}>
    {<Icon />}
    <span>{text}</span>
  </a>
);

const LocationButton = (props) => {
  const { user } = useAuth();
  const siteUrl = window.location.href;

  /**
   * Handler function for button click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleButtonClick(event) {
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Location Button',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  return (
    <section
      className={`location-button py-slightly_more_relaxed py-laptop-very_relaxed`}
      data-testid="lc-location-button"
    >
      <div className="container">
        {props?.facebook ? (
          <Button
            Icon={FacebookIcon}
            onClick={handleButtonClick}
            target="_blank"
            text="Follow Us On Facebook"
            url={`https://www.facebook.com/${props.facebook}`}
          />
        ) : null}
        {props?.instagram ? (
          <Button
            Icon={InstagramIcon}
            onClick={handleButtonClick}
            target="_blank"
            text="Follow Us On Instagram"
            url={`https://www.instagram.com/${props.instagram}`}
          />
        ) : null}
        <Button
          Icon={ShareIcon}
          onClick={handleButtonClick}
          target="_blank"
          text="Share With Your Friends"
          url={`https://facebook.com/sharer/sharer.php?u=${siteUrl}`}
        />
      </div>
    </section>
  );
};

export default LocationButton;
