/**
 * @module ChurchResources
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import ButtonItem from '../ButtonItem/ButtonItem';
import LCBackground from '../LCImage/LCBackground';
import { ACTIONS, ENVIRONMENT, EVENTS } from '../../helpers/constants';

const ChurchResources = ({ data, label, isMobile }) => {
  const {
    churchResourcesBgOverride: bgOverride,
    churchResourcesButtonOverride: btnOverride,
  } = data;
  const { user } = useAuth();
  const { desc, title } = label;

  const bgDesktop =
    bgOverride?.bgDesktop ??
    `${process.env.IMGIX_BASE_URL}/pages/worship/resources-background-lg.jpg`;
  const bgMobile =
    bgOverride?.bgMobile ??
    `${process.env.IMGIX_BASE_URL}/pages/worship/resources-background-sm.jpg`;

  /**
   * Handler function for button click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleButtonClick(event) {
    /* istanbul ignore next */
    if (event?.target?.preventDefault && ENVIRONMENT.test) {
      event.preventDefault();
    }
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Media - Church Resources',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  return btnOverride?.field ? (
    <LCBackground
      className="church-resources"
      src={isMobile ? bgMobile : bgDesktop}
    >
      <div className="container">
        <div className="church-resources-content">
          {title ? (
            <h2 className="church-resources-title text-section_header">
              {title}
            </h2>
          ) : null}
          {desc ? (
            <p className="church-resources-desc text-feature_paragraph">
              {desc}
            </p>
          ) : null}
          <div className="button-group-wrapper">
            <ul className="button-item-listing buttons-group btn-width-auto left-align">
              <div>
                {btnOverride.field.toString() === 'openUrl' ? (
                  <ButtonItem
                    buttonSize="medium"
                    className="mt-bit_more_relaxed"
                    onClick={handleButtonClick}
                    style="btn-secondary dark-mode"
                    target={btnOverride?.linkTarget}
                    text={btnOverride?.linkText || 'Download from Open Network'}
                    url={btnOverride?.linkUrl || '#'}
                  />
                ) : null}
                {btnOverride.field.toString() === 'openModal' ? (
                  <ButtonItem
                    buttonSize="medium"
                    className="mt-bit_more_relaxed"
                    modalTrigger={btnOverride?.modal}
                    onClick={handleButtonClick}
                    style="btn-secondary dark-mode"
                    text="Download from Open Network"
                  />
                ) : null}
              </div>
            </ul>
          </div>
        </div>
      </div>
    </LCBackground>
  ) : null;
};

export default ChurchResources;
