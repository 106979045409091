/* eslint-disable jsx-a11y/aria-role */
/**
 * @module PodcastTextAndImage
 */
// eslint-disable-next-line jsdoc/require-file-overview
import React from 'react';
import {
  callGtagEvent,
  callSegmentTrack,
} from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import { Log } from '@lifechurch/web-tools-io/dist/utils/helpers/browserLogger';
import {
  addQueryParamToUrl,
  getQueryParam,
} from '@lifechurch/web-tools-io/dist/utils/helpers/queryParams';
import { getLabelValue } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/labels';
import { mediaUrlFromType } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/mediaTypeUtils';
import { getUrlStructureByPath } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/urls';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import Cookies from 'js-cookie';
import {
  ACTIONS,
  CATEGORIES,
  ENVIRONMENT,
  EVENTS,
  SCREENS,
} from '../../helpers/constants';
import ButtonItem from '../ButtonItem/ButtonItem';
import DownloadLeaderGuideModal from './DownloadLeaderGuideModal';
import LCBackground from '../LCImage/LCBackground';

/**
 * Represents a section containing podcast text and image for newest episode.
 *
 * Note: For analytics tracking, this is presently *intentionally* using very
 * explicit non-shared/convenience functions for `callSegmentTrack`, as there is
 * a need and desire to be very explicit about the data captured.
 *
 * @param {object} props - The component props object.
 * @returns {React.ReactElement} The PodcastTextAndImage component.
 */
const PodcastTextAndImage = (props) => {
  const { user } = useAuth();
  const { featuredImg, labelList, latestMedia, modalLabels, podcastTitle } =
    props;
  const [modal, setModal] = React.useState(false);
  const [currentResource, setCurrentResource] = React.useState({});
  const [currentResourceLang] = React.useState('');

  React.useEffect(() => {
    document.body.style.overflow = modal ? 'hidden' : '';
  }, [modal]);

  const handleBtnClick = (event, resourceItem) => {
    setCurrentResource(resourceItem);

    const leaderCookie = Cookies.get('leadership_podcast_optin');
    const eventLabel = `${resourceItem?.lang} - ${latestMedia?.title}`;
    const utmMap = {
      campaign: getQueryParam('utm_campaign'),
      medium: getQueryParam('utm_medium'),
      name: getQueryParam('utm_name'),
      source: getQueryParam('utm_source'),
    };

    /**
     * Note: With the completion of epic MND-1694 and Segment
     * tracking improvements, the new set of event properties is
     * used, but the original (legacy) ones are also spread on
     * to the properties object.
     */
    const legacySegmentProperties = {
      campaign: utmMap.campaign,
      label: eventLabel,
      screen: SCREENS.podcastListingPage,
    };

    if (leaderCookie) {
      callGtagEvent({
        parameters: {
          event_category: CATEGORIES.downloadLeaderGuide,
          event_label: eventLabel,
        },
        type: 'Download',
      });
      callSegmentTrack({
        event: EVENTS.downloadLeaderGuide,
        properties: {
          action: ACTIONS.clicked,
          component: 'Podcast Text and Image',
          component_url: event?.currentTarget?.getAttribute('href'),
          label: event?.currentTarget?.textContent,
          logged_in: !!user,
          preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
          referrer: document?.referrer || null,
          title: document?.title || '',
          url: window?.location?.href,
          user_id: user?.['https://www.life.church/rock_person_alias_id'],
          ...legacySegmentProperties,
        },
      });

      const resourceUrl = addQueryParamToUrl({
        name: 'utm_source',
        url: resourceItem?.asset_public,
        value: 'life_church',
      });
      const leaderGuideWindow = window.open(resourceUrl, '_blank');
      try {
        leaderGuideWindow.focus();
      } catch (error) {
        /* istanbul ignore next*/
        Log.error(error);
      }
    } else {
      setModal(!modal);
    }
  };

  const resource = latestMedia?.additionalResources;
  const { collection, item, subCollection } = getUrlStructureByPath(
    latestMedia?.['@path'],
  );
  const watchButtonUrl = mediaUrlFromType({
    slug: item,
    subCollection,
    type: collection,
  });

  /* istanbul ignore next*/
  const handleDownloadBtnClick = (resourceData) => {
    const resourceUrl = addQueryParamToUrl({
      name: 'utm_source',
      url: resourceData?.asset_public,
      value: 'life_church',
    });

    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Podcast Text and Image',
        component_url: resourceUrl,
        label: 'Download Leader Guide',
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });

    const leaderGuideWindow = window.open(resourceUrl, '_blank');
    try {
      leaderGuideWindow.focus();
    } catch (error) {
      Log.error(error);
    }
  };

  /**
   * Handler function for watch latest episode button click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleLatestEpisodeButtonClick(event) {
    /* istanbul ignore next */
    if (event?.target?.preventDefault && ENVIRONMENT.test) {
      event.preventDefault();
    }
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Podcast Text and Image',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  return (
    <div
      className="podcast-text-image container py-bit_more_relaxed"
      data-testid="lc-podcast-text-image"
    >
      {latestMedia?.featuredImage && featuredImg ? (
        <div className="podcast-image">
          <LCBackground
            aria-label={latestMedia.featuredImage.metadata?.caption || ''}
            backup={'cinema-img'}
            className="image"
            role="image"
            src={featuredImg}
          ></LCBackground>
        </div>
      ) : null}
      {latestMedia ? (
        <div className="podcast-text">
          {labelList ? (
            <h2 className="title">
              {getLabelValue(labelList, 'the_newest_episode')}
            </h2>
          ) : null}
          {latestMedia?.title ? (
            <h3 className="subtitle">{latestMedia.title}</h3>
          ) : null}
          {latestMedia?.description ? (
            <p className="description">{latestMedia.description}</p>
          ) : null}
          <div className="podcast-button">
            <ButtonItem
              buttonSize="medium"
              onClick={handleLatestEpisodeButtonClick}
              style="btn-primary"
              text="Watch the Episode"
              url={watchButtonUrl}
            />
            {resource?.['@nodes']?.length
              ? resource['@nodes'].map((node) => {
                  const resourceItem = resource[node];
                  return (
                    <ButtonItem
                      buttonSize="medium"
                      className="mr-none mr-tablet-more_slight_tight"
                      key={resourceItem['@id']}
                      onClick={(event) => handleBtnClick(event, resourceItem)}
                      style="btn-secondary"
                      text={resourceItem.text}
                    />
                  );
                })
              : null}
          </div>
        </div>
      ) : null}
      {modal ? (
        <DownloadLeaderGuideModal
          lang={currentResourceLang}
          latestPodcastTitle={latestMedia.title}
          onClose={setModal}
          onDownloadClick={handleDownloadBtnClick}
          podcastTitle={podcastTitle}
          resource={currentResource}
          resourceLabels={modalLabels}
          screen={SCREENS.podcastListingPage}
        />
      ) : null}
    </div>
  );
};

export default PodcastTextAndImage;
