/**
 * @module Signature
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import SignatureCanvas from 'react-signature-canvas';
import './Signature.scss';

/**
 * Represents a form control element containing a SignatureCanvas component with label and hint.
 *
 * @param {object} props - The component props object.
 * @param {string} props.hint - The value to display for the component hint element.
 * @param {string} props.label - The label value for the component.
 *
 * @returns {React.ReactElement} The Signature component.
 */
const Signature = ({ hint, label }) => {
  return (
    <div className="form-control signature">
      <label>{label}</label>
      <div className="signature-input">
        <SignatureCanvas
          canvasProps={{ className: 'sigCanvas' }}
          penColor="#000"
        />
      </div>
      <span className="text-paragraph_xsmall">{hint}</span>
    </div>
  );
};

export default Signature;
