/**
 * @module RichText
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import './RichText.scss';

/**
 * Represents a form control element containing a textarea element with corresponding label and hint.
 *
 * @param {object} props - The component props object.
 * @param {string} props.hint - The value to display for the component hint element.
 * @param {string} props.inputName - The value to use for the textarea name attribute.
 * @param {string} props.label - The label value for the component.
 * @param {string} props.type - The input type (Example: richtext).
 *
 * @returns {React.ReactElement} The Radio component.
 */
const RichText = ({ hint, inputName, label, type }) => {
  return (
    <div
      className="form-control richtext"
      data-testid="richtext-component-wrapper"
    >
      <label data-testid="richtext-label">{label}</label>
      <textarea
        data-testid="richtext-textarea"
        name={inputName}
        rows="5"
        type={type || 'text'}
      ></textarea>
      <span data-testid="richtext-hint">{hint}</span>
    </div>
  );
};

export default RichText;
