/**
 * @module CollectionListingAlbumHero
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import { generateAlbumSubtitle } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/generateAlbumSubtitle';
import { mediaUrlFromType } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/mediaTypeUtils';
import { getUrlStructureByPath } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/urls';
import { implementUtmParams } from '@lifechurch/web-tools-io/dist/utils/helpers/utmParams';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import { ACTIONS, ENVIRONMENT, EVENTS } from '../../helpers/constants';
import ButtonItem from '../ButtonItem/ButtonItem';
import LCBackground from '../LCImage/LCBackground';

const CollectionListingAlbumHero = ({ data }) => {
  const {
    '@path': path,
    albumCopyrightDate,
    channelImageList,
    parts,
    title,
  } = data;
  const { user } = useAuth();
  const imgixParamsCollectionListingAlbumHero = { blur: 160, bri: -15, w: 400 };
  const imgixParamsAlbumImage = { w: 400 };
  const imgixImage = channelImageList?.albumImage_public ?? '';

  const { collection, item, subCollection } = getUrlStructureByPath(path);
  const mediaUrl = mediaUrlFromType({
    slug: item,
    subCollection,
    type: collection,
  });

  /**
   * Convenience function to trigger Segment analytics tracking.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleButtonClick(event) {
    /* istanbul ignore next */
    if (event?.target?.preventDefault && ENVIRONMENT.test) {
      event.preventDefault();
    }
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Media - Collection Listing Album Hero',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  return (
    <div data-testid="collection-listing-album-hero">
      <LCBackground
        backup={'cinema-img'}
        className={`collection-listing-album-hero-container has-bg-image ${
          imgixImage ? 'text-white' : ''
        }`}
        imgixParams={imgixParamsCollectionListingAlbumHero}
        src={imgixImage}
      >
        <div className="album-detail">
          <p className="album-sub-title text-group_header text-weight-normal mt-none">
            Latest Release
          </p>
          <h1 className="album-title text-title text-weight-bold mt-none">
            {title}
          </h1>

          {albumCopyrightDate || parts ? (
            <p className="album-year mt-none">
              {generateAlbumSubtitle(albumCopyrightDate, parts)}
            </p>
          ) : null}

          <ButtonItem
            buttonSize="large"
            className="align-center"
            onClick={handleButtonClick}
            style="btn-primary"
            text="Listen Now"
            url={mediaUrl}
          />
        </div>

        {imgixImage ? (
          <a
            className="image-link"
            href={implementUtmParams(mediaUrl, window?.location)}
            target="_self"
          >
            <LCBackground
              backup={'album-img'}
              className="album-image has-bg-image"
              imgixParams={imgixParamsAlbumImage}
              src={imgixImage}
            ></LCBackground>
          </a>
        ) : null}
      </LCBackground>
    </div>
  );
};

export default CollectionListingAlbumHero;
