/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @module FindArticleListing
 */
import React from 'react';
import algoliasearch from 'algoliasearch';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import { Log } from '@lifechurch/web-tools-io/dist/utils/helpers/browserLogger';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import useWindowSize from '@lifechurch/web-tools-io/dist/hooks/useWindowSize';
import Card from '../OpenContentListItem/OpenContentListItem';
import ButtonItem from '../ButtonItem/ButtonItem';
import { ACTIONS, ALGOLIA, EVENTS } from '../../helpers/constants';

/**
 * Component for showing the articles of the media collection.
 *
 * @param {object} props - The component props object.
 * @param {object} props.data - The data of the media collection.
 *
 * @returns {React.ReactElement} The FindArticleListing component.
 */
function FindArticleListing({ data }) {
  const { user } = useAuth();
  const { isMobile, isTablet } = useWindowSize();
  const [showItem, setShowItem] = React.useState(1);
  const [hashTags, setHashTags] = React.useState([]);
  const [posts, setPosts] = React.useState([]);
  const client = algoliasearch(ALGOLIA.appId, ALGOLIA.apiKey);
  const index = client.initIndex('production_magnolia-finds_finds-articles');
  let tempArr = [];

  const handleAlgoliaSearch = (searchTerm) => {
    if (searchTerm === '') {
      searchTerm = tempArr[0];
    }
    index
      .search(searchTerm, { hitsPerPage: 3 })
      .then((result) => {
        setPosts(result.hits);
      })

      .catch(
        /* istanbul ignore next */ (err) => {
          Log.error(err);
        },
      );
  };

  const checkShowItem = () => {
    if (isMobile) {
      setShowItem(1);
    } else if (isTablet) {
      setShowItem(2);
    } else {
      setShowItem(3);
    }
  };

  React.useEffect(() => {
    const type = data?.['@path'].split('/')?.[1];

    if (type === 'messages' || type === 'lifegroups') {
      const tempHashtags = data?.findHashtags;
      if (tempHashtags) {
        tempArr = tempHashtags.split(',').map((item) => item.trim());
        setHashTags(tempArr);
      }
    }

    const locationHash = window.location.hash.replace('#', '');
    handleAlgoliaSearch(locationHash);

    window.addEventListener('resize', checkShowItem);
    return () => window.removeEventListener('resize', checkShowItem);
    // Note: Adding dependencies for hook-related state items so resize logic
    // for determining/setting image source is properly utilized and set.
  }, [isMobile, isTablet]);

  React.useEffect(() => {
    if (posts.length) {
      checkShowItem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [posts]);

  const handleHashTagClick = (hashtag) => (event) => {
    event.preventDefault();
    event.nativeEvent.stopImmediatePropagation();
    window.location.hash = hashtag;
    handleAlgoliaSearch(hashtag);

    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Media - Find Article Listing',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  };

  return (
    <>
      {hashTags?.length && posts?.length ? (
        <div
          className="finds-article-listing"
          data-testid="lc-find-article-listing"
        >
          <div className="button-group-wrapper container mb-bit_more_relaxed">
            <ul className="button-item-listing links-group normal-width center-align">
              <div>
                {hashTags.map((item) => (
                  <ButtonItem
                    buttonSize="large"
                    className="finds-query"
                    key={item}
                    onClick={handleHashTagClick(item)}
                    style="link-tertiary"
                    text={`#${item}`}
                    url={`#${item}`}
                  />
                ))}
              </div>
            </ul>
          </div>
          <div
            className={`open-content-list container odd content-vertical-cards ${
              posts.slice(0, showItem).length === 1 ? 'has-single-item' : ''
            }`}
          >
            <div>
              {posts.slice(0, showItem).map((item) => (
                <Card
                  image={
                    /* istanbul ignore next */ item.data?.featuredImage ?? ''
                  }
                  imageIsExternal={true}
                  key={item.objectID}
                  target="_blank"
                  title={item.data?.title}
                  type="vertical-cards"
                  url={`https://finds.life.church${item.data?.path}`}
                  wholeCardLink={true}
                />
              ))}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default FindArticleListing;
