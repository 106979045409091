/**
 * @module LocationPosterPhone
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
 * Represents markup of a location phone number with `tel:` link.
 *
 * @param {object} props - The component props object.
 * @param {Function} props.onClick - Handler function for link click event.
 * @param {string} props.phone - The location phone number.
 *
 * @returns {React.ReactElement} The LocationPosterPhone component with location markup.
 */
export default function LocationPosterPhone({ onClick, phone }) {
  if (!phone) {
    return null;
  }

  return (
    <p className="mt-none" data-testid="set-location-phone">
      <a
        href={`tel:${phone
          .replace(/[^\d- ]/g, '')
          .trim()
          .replace(' ', '-')}`}
        onClick={onClick}
      >
        {phone}
      </a>
    </p>
  );
}
