/**
 * @module ClassNames
 */

/**
 * Convenience function to determine whether or not the supplied color setting
 * value is a valid class name. Some color setting values are set as values like
 * currentColor or inherit, which shouldn't be used as a class name.
 *
 * @param {string} colorSetting - The color setting value to check.
 *
 * @returns {boolean} Boolean flag denoting whether or not the provided color setting is a valid class name.
 */
export function isColorSettingValidClassName(colorSetting) {
  return !['currentColor', 'inherit'].includes(colorSetting);
}
