/**
 * @module ContentListFetcher
 */
import { collectionTypeToPath } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/collectionTypeUtils';
import { getMagnoliaItem } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/getMagnoliaItem';
import { normalizeContentList } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/normalizeContentList';
import {
  ENDPOINT_WORKSPACE_MAP,
  MAGNOLIA_ENDPOINTS,
  MGNL_ENV_VARS,
  WEB_DISTRIBUTION_PLATFORM_UUID,
} from '../constants';

const mediathumbnail = 'lifechurch:components/mediathumbnail';
const card = 'lifechurch:components/opencontentlistitem';
const carousel = 'lifechurch:components/carousel';

/**
 * Convenience function to fetch the media elements for the ContentList.
 *
 * Note: With the migration to PaaS, the timeout is not the same as that of the
 * now-old Cloud3 setup. As such, for tests, accessing URLs with no things like
 * no or invalid limit values or media collection types is not advisable since
 * it would either cause timeout errors and failed tests and/or needing to
 * increase test timeout values. With test coverage for adding this filter, it's
 * safe to ignore for the "not present" or "invalid value" cases.
 *
 * @param {object} props - The props object.
 *
 * @returns {object} The object that contains the data for the ContentList.
 */
async function fetchContentList(props) {
  const {
    collectDataFrom = { field: 'media' },
    limitFilter,
    sbOnMobile,
    sbOnTabletAndUp,
    type,
  } = props;
  const collections = {
    fields: ['mediacollection', 'mediacollectionV2'],
    name: 'mediaCollections',
  };
  const items = {
    fields: ['media', 'mediaItemV2'],
    name: 'mediaItems',
  };
  const mediaTypeByField = [collections, items].find((mediaType) =>
    mediaType.fields.includes(collectDataFrom.field),
  );
  const fieldTypes = {
    carousel,
    'media-thumbnail': mediathumbnail,
  };
  const template = fieldTypes?.[type.field] ?? card;

  let apiEndpoint = `${MAGNOLIA_ENDPOINTS.delivery[mediaTypeByField.name]}`;
  let queryString = '?';

  if (items.fields.includes(collectDataFrom.field)) {
    const {
      filterMediaByStartDate,
      filterMediaByTag,
      filterMediaByType,
      filterMediaByMediaCollection,
    } = collectDataFrom;

    const mediaTypes = {
      episode: 'lc:mediaEpisode',
      message: 'lc:mediaMessage',
      podcast: 'lc:mediaPodcast',
      song: 'lc:mediaWorship',
    };

    if (filterMediaByMediaCollection) {
      apiEndpoint = `${MAGNOLIA_ENDPOINTS.delivery.mediaItems}`;
      queryString += `@ancestor=${filterMediaByMediaCollection}&`;
    }
    if (filterMediaByType) {
      queryString += `jcr:primaryType=${mediaTypes[filterMediaByType]}&`;
    }
    if (filterMediaByTag?.length) {
      queryString += `tag[like]=%${filterMediaByTag}%&`;
    }
    if (filterMediaByStartDate) {
      queryString += `startDate[gte]=${filterMediaByStartDate}&`;
    }

    queryString += `availablePlatforms[eq]=${WEB_DISTRIBUTION_PLATFORM_UUID}&`;
    queryString += `orderBy=startDate desc&`;

    /* istanbul ignore next */
    if (limitFilter && !Number.isNaN(Number(limitFilter))) {
      queryString += `limit=${limitFilter}`;
    }
  } else {
    const { mediaCollectionType, mediaCollectionStartDate, filterMediaByTag } =
      collectDataFrom;

    if (filterMediaByTag?.length) {
      queryString += `tag[like]=%${filterMediaByTag}%&`;
    }
    if (mediaCollectionStartDate) {
      queryString += `startDate[gte]=${mediaCollectionStartDate}&`;
    }
    /* istanbul ignore next */
    if (mediaCollectionType) {
      queryString += `@ancestor=/${collectionTypeToPath(
        mediaCollectionType,
      )}/&`;
    }

    queryString += `availablePlatforms[eq]=${WEB_DISTRIBUTION_PLATFORM_UUID}&`;
    queryString += `orderBy=startDate desc&`;

    /* istanbul ignore next */
    if (!Number.isNaN(Number(limitFilter))) {
      queryString += `limit=${limitFilter}`;
    }
  }

  const mediaData = await getMagnoliaItem({
    caller: 'src/helpers/dataFetchers/contentListFetcher.js > fetchContentList',
    fallbackdata: [],
    mgnlEnvVars: MGNL_ENV_VARS,
    path: encodeURI(apiEndpoint + queryString),
    workspaceMap: ENDPOINT_WORKSPACE_MAP,
  });

  /* istanbul ignore next */
  const data = mediaData?.results ?? [];

  const normalized = normalizeContentList({
    data,
    template,
    type,
  });
  const metadata = props.metadata || {};
  metadata['mgnl:template'] = 'lifechurch:components/opencontentlist';

  /**
   * Ignoring next for normalized and metadata fallbacks, as the fallback values
   * should always be set properly above, but are left here for precaution
   * to guard against rogue edge cases.
   */
  /* istanbul ignore next */
  return {
    items: normalized || [],
    metadata: metadata || {},
    sbOnMobile,
    sbOnTabletAndUp,
    type,
  };
}

export { fetchContentList };
