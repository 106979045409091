/**
 * @module ModalContext
 */
import React from 'react';
import { Log } from '@lifechurch/web-tools-io/dist/utils/helpers/browserLogger';
import { getMagnoliaItem } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/getMagnoliaItem';
import { ENDPOINT_WORKSPACE_MAP, MGNL_ENV_VARS } from '../helpers/constants';

/**
 * @typedef {object} ModalContext
 * @property {Function} addModalTrigger - Function to add a modal trigger to the object of all modal triggers.
 * @property {Function} fetchModalList - Function to fetch the modal list data for the specified modal trigger id.
 * @property {object} modalTriggers - Data object of modal triggers, with key values being the trigger id, and its value the modal data object.
 * @property {object} modalVisibilities - Data object of modal visibilities, with key values being the trigger id, and its value a boolean flag denoting the modal visibility status.
 * @property {Function} removeModalTrigger - Function to remove a modal trigger from the object of all modal triggers.
 * @property {Function} showModal - Function to set the visibility status of the specified modal trigger id.
 * @property {Function} storeModalTriggers - Function to set the context modal triggers object to the specified object.
 */

export const ModalContext = React.createContext({
  addModalTrigger: null,
  fetchModalList: null,
  modalTriggers: null,
  modalVisibilities: null,
  removeModalTrigger: null,
  showModal: null,
  storeModalTriggers: null,
});
ModalContext.displayName = 'ModalContext';

/**
 * React Context Provider for Life.Church Magnolia client modals.
 *
 * @param {object} props - The context component props object.
 * @param {React.ReactNode} props.children - The React children around which the context provider is wrapped.
 * @returns {React.ReactElement} The Modal Context Provider.
 */
export function ModalProvider({ children, ...props }) {
  /**
   * Data object with modal trigger id as key, and its value the corresponding modal data object.
   */
  const [modalTriggers, setModalTriggers] = React.useState({});

  /**
   * Data object, similar to modalTriggers, keyed with modal trigger id, with boolean value denoting modal visibility status.
   */
  const [modalVisibilities, setModalVisibilities] = React.useState({});

  /**
   * Data retrieval function to fetch modal list data for the specified modal trigger id.
   *
   * @param {string} modalTriggerId - The modal trigger id value.
   *
   * @returns {object} The modal data object.
   */
  const fetchModalList = React.useCallback(async (modalTriggerId) => {
    if (modalTriggerId) {
      try {
        const response = await getMagnoliaItem({
          caller: 'src/context/ModalContext.js > fetchModalList',
          mgnlEnvVars: MGNL_ENV_VARS,
          path: encodeURI(`/.rest/delivery/modal?@jcr:uuid=${modalTriggerId}`),
          workspaceMap: ENDPOINT_WORKSPACE_MAP,
        }); // NOSONAR
        const tempData = response?.results?.[0];
        setModalTriggers((prevModalTriggers) => {
          return {
            ...prevModalTriggers,
            [modalTriggerId]: tempData,
          };
        });
        setModalVisibilities((prevModalVisibilities) => {
          return {
            ...prevModalVisibilities,
            [modalTriggerId]: false,
          };
        });
      } catch (error) {
        Log.error(error);
      }
    }
  }, []);

  /**
   * Convenience function to add the specified modal trigger id to the object of all modal triggers.
   *
   * @param {string} modalTriggerId - The modal trigger id value.
   */
  const addModalTrigger = React.useCallback(
    (modalTriggerId) => {
      if (modalTriggerId) {
        fetchModalList(modalTriggerId);
      }
    },
    [fetchModalList],
  );

  /**
   * Convenience function to remove the specified modal trigger id from the object of all modal triggers.
   *
   * @param {string} modalTriggerId - The modal trigger id value.
   */
  const removeModalTrigger = React.useCallback(
    (modalTriggerId) => {
      const updatedModalTriggers = { ...modalTriggers };
      const updatedModalVisibilities = { ...modalVisibilities };
      delete updatedModalTriggers[modalTriggerId];
      delete updatedModalVisibilities[modalTriggerId];

      setModalTriggers(() => {
        return {
          ...updatedModalTriggers,
        };
      });
      setModalVisibilities(() => {
        return {
          ...updatedModalVisibilities,
        };
      });
    },
    [modalTriggers, modalVisibilities],
  );

  /**
   * Convenience function to set the visibility status of the specified modal trigger id.
   *
   * @param {string} modalTriggerId - The modal trigger id value.
   * @param {boolean} showStatus - Boolean flag denoting the visibility status to attribute to the specified modal trigger id.
   */
  const showModal = React.useCallback((modalTriggerId, showStatus) => {
    setModalVisibilities((prevModalVisibilities) => {
      return {
        ...prevModalVisibilities,
        [modalTriggerId]: showStatus ?? false,
      };
    });
  }, []);

  /**
   * Convenience function to add the context modal triggers object to the overall object of all modal triggers.
   *
   * @param {object} new - The modal triggers data to add to the stored modal triggers object.
   */
  const storeModalTriggers = React.useCallback(
    (newModalTriggers) => {
      if (
        newModalTriggers &&
        typeof newModalTriggers === 'object' &&
        !Array.isArray(newModalTriggers)
      ) {
        Object.keys(newModalTriggers).forEach((modalTriggerId) => {
          fetchModalList(modalTriggerId);
        });
      }
    },
    [fetchModalList],
  );

  const value = React.useMemo(
    () => ({
      addModalTrigger,
      fetchModalList,
      modalTriggers,
      modalVisibilities,
      removeModalTrigger,
      showModal,
      storeModalTriggers,
    }),
    [
      addModalTrigger,
      fetchModalList,
      modalTriggers,
      modalVisibilities,
      removeModalTrigger,
      showModal,
      storeModalTriggers,
    ],
  );

  return (
    <ModalContext.Provider value={value} {...props}>
      {children}
    </ModalContext.Provider>
  );
}
