/**
 * @module BasicTemplateFetcher
 */
import { Log } from '@lifechurch/web-tools-io/dist/utils/helpers/browserLogger';
import { getMagnoliaItem } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/getMagnoliaItem';
import { ENDPOINT_WORKSPACE_MAP, MGNL_ENV_VARS, THEMES } from '../constants';

/**
 * Convenience function to fetch nav bar data.
 *
 * @param {string} path - The path value for the page rendering the nav bar (Example: '/media/messages').
 *
 * @returns {object} Data object of nav bar data.
 */
const getNavBarData = async (path) => {
  const tmpNavBar = [];

  /**
   * Note: The following is just ensuring we have a bulletproof way to avoid
   * continuing the logical flow if there's any reason why path is null.
   */
  /* istanbul ignore next */
  if (!path) {
    return tmpNavBar;
  }
  const pathParts = path.split('/').filter((e) => e);
  const data = await getMagnoliaItem({
    caller:
      'src/helpers/dataFetchers/basicTemplateFetcher.js > fetchBasicTemplate',
    mgnlEnvVars: MGNL_ENV_VARS,
    path: `/.rest/delivery/pagenav/v1/${pathParts[0]}`,
    workspaceMap: ENDPOINT_WORKSPACE_MAP,
  }); // NOSONAR
  tmpNavBar.push(data || false);
  return tmpNavBar;
};

/**
 * Convenience function to fetch menu data.
 *
 * @returns {object} Data object of menu data.
 */
async function getMenuData() {
  const data = await getMagnoliaItem({
    caller: 'src/helpers/dataFetchers/basicTemplateFetcher.js > getMenuData',
    mgnlEnvVars: MGNL_ENV_VARS,
    path: '/.rest/delivery/menu/@nodes',
    workspaceMap: ENDPOINT_WORKSPACE_MAP,
  }); // NOSONAR
  /**
   * The following is an edge case to guard against returning null and as such,
   * the need to cover with tests doesn't seem entirely necessary.
   */
  /* istanbul ignore next */
  const menuData = data ? [...data] : [];
  /* istanbul ignore next */
  const menuFolder =
    (!!data &&
      data.filter(
        (menuItem) =>
          menuItem['@name'] === 'menu' &&
          menuItem['@nodeType'] === 'mgnl:folder',
      )[0]) ||
    [];
  /**
   * No need to cover simple log warning with test coverage.
   */
  /* istanbul ignore next */
  if (menuFolder?.length === 0) {
    Log.warn(
      'No folder named "menu" was found in the cms, no items will be shown in the hamburger menu.',
    );
  }
  return menuData;
}

/**
 * Convenience function to fetch footer data.
 *
 * @returns {object} Data object of footer data.
 */
const getFooterData = async () => {
  const data = await getMagnoliaItem({
    caller: 'src/helpers/dataFetchers/basicTemplateFetcher.js > getFooterData',
    mgnlEnvVars: MGNL_ENV_VARS,
    path: '/.rest/delivery/footer',
    workspaceMap: ENDPOINT_WORKSPACE_MAP,
  }); // NOSONAR
  /**
   * The following is an edge case to guard against returning null and as such,
   * the need to cover with tests doesn't seem entirely necessary.
   */
  /* istanbul ignore next */
  return data.results ? { ...data.results[0] } : {};
};

/**
 * Convenience function to fetch the necessary data items used to render a basic template.
 *
 * @param {object} props - The function props object.
 * @param {string} [theme] - Optional theme value to use when rendering the page to determine whether or not to show content only or full page (Example: 'content-only').
 *
 * @returns {object} Data object for the basic template, including footerData, menuData, and navBar.
 */
async function fetchBasicTemplate(props, theme) {
  const { displayNavBar, worshipSubNav } = props;
  const path = props?.['@path'] ?? props?.metadata?.['@path'] ?? '/';

  let navBar = {};
  if (displayNavBar?.toString() === 'true') {
    if (worshipSubNav?.toString() === 'true') {
      navBar = await getNavBarData('/worship');
    } else {
      navBar = await getNavBarData(path);
    }
  }

  const showContentOnly = theme === THEMES.contentOnly;
  const menuData = showContentOnly ? null : await getMenuData();
  const footerData = showContentOnly ? null : await getFooterData();

  return {
    footerData,
    menuData,
    navBar,
  };
}

export default fetchBasicTemplate;
