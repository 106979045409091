/* eslint-disable react/jsx-props-no-spreading */
/**
 * @module LocationLaunchedV1
 */
import React from 'react';
import { Log } from '@lifechurch/web-tools-io/dist/utils/helpers/browserLogger';
import { getMagnoliaItem } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/getMagnoliaItem';
import { getLabelValue } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/labels';
import LocationConnectLocally from '../LocationConnectLocally/LocationConnectLocally';
import LocationEvents from '../LocationEvents/LocationEvents';
import LocationPoster from '../LocationPoster/LocationPoster';
import LocationServe from '../LocationServe/LocationServe';
import LocationStaticText from '../LocationStaticText/LocationStaticText';
import { ENDPOINT_WORKSPACE_MAP, MGNL_ENV_VARS } from '../../helpers/constants';

const LocationLaunchedV1 = (props) => {
  const [specialEventData, setSpecialEventData] = React.useState(null);

  const locationHasSpecialEvent =
    props.hasSpecialEvent?.field?.toString() === 'true' &&
    props.hasSpecialEvent?.specialEvent?.['@nodes']?.length;

  const locationSpecialEvent =
    (locationHasSpecialEvent &&
      props.hasSpecialEvent?.specialEvent?.[
        props.hasSpecialEvent?.specialEvent?.['@nodes']?.[0]
      ]) ||
    {};

  /**
   * Single-run convenience effect to fetch event data.
   */
  React.useEffect(() => {
    async function fetchEvent() {
      try {
        /* istanbul ignore next */
        const eventId =
          typeof locationSpecialEvent.event === 'string'
            ? locationSpecialEvent?.event
            : locationSpecialEvent?.event?.['@id'];
        const response = await getMagnoliaItem({
          caller:
            'src/components/LocationLaunched/LocationLaunchedV1.js > fetchEvent',
          forceCall: props?.testOverride,
          mgnlEnvVars: MGNL_ENV_VARS,
          path: encodeURI(`/.rest/delivery/specialEvent?jcr:uuid=${eventId}`),
          workspaceMap: ENDPOINT_WORKSPACE_MAP,
        }); // NOSONAR
        setSpecialEventData(response?.results?.[0]);
      } catch (error) {
        /* istanbul ignore next*/
        Log.error(error);
      }
    }

    if (locationHasSpecialEvent) {
      fetchEvent(); // NOSONAR
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="location-detail" data-testid="lc-location-launched-v1">
      <LocationPoster
        {...props}
        hasSpecialEvent={!!locationHasSpecialEvent}
        label={{
          lifegroups_desc: getLabelValue(props.labelList, 'lifegroups_desc'),
          lifegroups_title: getLabelValue(props.labelList, 'lifegroups_title'),
          new_to_lc_desc: getLabelValue(props.labelList, 'new_to_lc_desc'),
          new_to_lc_title: getLabelValue(props.labelList, 'new_to_lc_title'),
          prayer_request: getLabelValue(props.labelList, 'prayer_request'),
          service_times_active_tab: getLabelValue(
            props.labelList,
            'service_times_active_tab',
          ),
        }}
        specialEventData={specialEventData}
        specialEventTimes={locationSpecialEvent?.eventTimes}
      />

      <LocationStaticText
        {...props}
        className={
          'mb-very_relaxed mb-tablet-a_little_relaxed mb-laptop-so_relaxed'
        }
        ctas={[
          {
            actionType: 'link',
            btn_txt: getLabelValue(props.labelList, 'info_btn_txt'),
            url: '/who-we-are/what-to-expect/',
          },
        ]}
        descClassName={'mb-slight_relaxed'}
        label={{
          desc: getLabelValue(props.labelList, 'info_desc'),
          title: getLabelValue(props.labelList, 'info_title'),
        }}
        titleClassName={'location-section-title'}
      />

      <LocationEvents
        {...props}
        label={{
          event_baptism_content: getLabelValue(
            props.labelList,
            'event_baptism_content',
          ),
          event_known_content: getLabelValue(
            props.labelList,
            'event_known_content',
          ),
        }}
      />
      <LocationServe
        {...props}
        label={{
          serve_at_desc: getLabelValue(props.labelList, 'serve_at_desc'),
          serve_at_title: getLabelValue(props.labelList, 'serve_at_title'),
        }}
      />
      <LocationConnectLocally
        {...props}
        label={{
          connect_locally_desc: getLabelValue(
            props.labelList,
            'connect_locally_desc',
          ),
          connect_locally_title: getLabelValue(
            props.labelList,
            'connect_locally_title',
          ),
        }}
      />
    </section>
  );
};

export default LocationLaunchedV1;
