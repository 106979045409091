/**
 * @module TalkItOver
 */
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import {
  Log,
  replaceMagnoliaAssetsToImgix,
} from '@lifechurch/web-tools-io/dist/utils/helpers';
import { getMagnoliaItem } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/getMagnoliaItem';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import {
  ACTIONS,
  ENDPOINT_WORKSPACE_MAP,
  ENVIRONMENT,
  EVENTS,
  MGNL_ENV_VARS,
} from '../../helpers/constants';
import RichText from '../RichText/RichText';
import './TalkItOver.scss';

const TalkItOver = (props) => {
  const { user } = useAuth();
  const [data, setData] = React.useState(props.name ? props : false);
  const [bg, setBg] = React.useState({});

  async function setBgStyle(bgTemp) {
    try {
      const background = await replaceMagnoliaAssetsToImgix(
        bgTemp,
        process.env.IMGIX_BASE_URL,
      );
      /* istanbul ignore next */
      setBg({
        backgroundImage: `linear-gradient(rgba(20,20,20, .7), rgba(20,20,20, .7)), url(${background})`,
      });
    } catch (error) {
      /* istanbul ignore next */
      Log.error(error);
    }
  }

  /**
   * Handler function for element click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleElementClick(event) {
    /* istanbul ignore next */
    if (event?.target?.preventDefault && ENVIRONMENT.test) {
      event.preventDefault();
    }
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Talk It Over',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  React.useEffect(() => {
    async function fetchingData() {
      try {
        const params = window.location.search
          .slice(1)
          .split('&')
          .map((p) => p.split('='))
          .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});
        const hasPreviewSlug = !!params && !!params.previewSlug;
        const response = await getMagnoliaItem({
          caller: 'src/components/TalkItOver/TalkItOver.js > fetchingData',
          forceCall: props?.testOverride,
          mgnlEnvVars: MGNL_ENV_VARS,
          path: encodeURI(
            `/.rest/delivery/talkItOver${
              !!params && params.previewSlug
                ? `/${params.previewSlug}`
                : `?jcr:uuid=${props.talkItOverID}`
            }`,
          ),
          workspaceMap: ENDPOINT_WORKSPACE_MAP,
        }); // NOSONAR
        const tmpData = hasPreviewSlug
          ? response
          : !!response.results.length && response.results[0];
        if (tmpData) {
          const bgTemp =
            tmpData?.featuredImage?.['@link'] ??
            tmpData?.featuredImage ??
            /* istanbul ignore next*/ '';
          setBgStyle(bgTemp); // NOSONAR
          setData(tmpData);
        }
      } catch (error) {
        /* istanbul ignore next */
        Log.error(error);
      }
    }

    if (!data) {
      fetchingData(); // NOSONAR
    } else {
      const bgTemp =
        data?.featuredImage?.['@id'] ??
        data?.featuredImage ??
        /* istanbul ignore next*/ '';
      setBgStyle(bgTemp); // NOSONAR
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <article className="tio" data-testid="lc-talk-it-over">
      {!!data && (
        <>
          <header className="tio-hero" style={bg}>
            <h2>{data.name}</h2>
          </header>
          <div className="tio-container">
            <RichText className="tio-content" content={data.richText} />
            <div className="container">
              <hr />
              <p>
                <em>
                  Find this in our{' '}
                  <a
                    href="https://www.life.church/app/"
                    onClick={handleElementClick}
                  >
                    app
                  </a>
                  . Open the app, choose this message, then tap “Talk It Over.”
                </em>
              </p>
              <p>
                <em>
                  Get this in your inbox. Visit{' '}
                  <a
                    href="https://info.life.church/talkitover"
                    onClick={handleElementClick}
                  >
                    info.life.church/talkitover
                  </a>
                </em>
              </p>
              <p>
                <em>
                  Whoever finds God, finds life. More of God’s truth all week
                  long at{' '}
                  <a
                    href="https://finds.life.church/"
                    onClick={handleElementClick}
                  >
                    finds.life.church
                  </a>
                </em>
              </p>
            </div>
          </div>
        </>
      )}
    </article>
  );
};

export default TalkItOver;
