/**
 * @module SocialMediaShare
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import ButtonItem from '../ButtonItem/ButtonItem';
import { ACTIONS, EVENTS } from '../../helpers/constants';

const SocialMediaShare = ({
  center = false,
  data,
  email = true,
  emailUrl = '',
  facebook = true,
  facebookUrl = '',
  instagram = true,
  instagramUrl = '',
  linkedin = true,
  linkedinUrl = '',
  shareLink = false,
  shareLinkOnClick,
  tiktok = false,
  tiktokUrl = '',
  youtube = true,
  youtubeUrl = '',
}) => {
  const { user } = useAuth();
  const { title, description, headline } = data;
  const siteUrl = window.location.href;
  const emailBody = (description || headline) ?? '';
  const emailURL =
    emailUrl ||
    `mailto:?subject=${title}&body=${emailBody}%0D%0A${encodeURI(siteUrl)}`;
  const facebookURL =
    facebookUrl || `https://facebook.com/sharer/sharer.php?u=${siteUrl}`;

  const mediaShareIcons = [
    {
      iconSlug: 'instagram',
      label: 'Instagram',
      url: instagram && instagramUrl,
    },
    {
      iconSlug: 'facebook',
      label: 'Facebook',
      url: facebook && facebookURL,
    },
    {
      iconSlug: 'tiktok',
      label: 'TikTok',
      url: tiktok && tiktokUrl,
    },
    {
      iconSlug: 'email',
      label: 'Email',
      url: email && emailURL,
    },
    {
      iconSlug: 'linkedin',
      label: 'LinkedIn',
      url: linkedin && linkedinUrl,
    },
    {
      iconSlug: 'youtube',
      label: 'YouTube',
      url: youtube && youtubeUrl,
    },
  ];

  /**
   * Handler function for button click.
   *
   * @param {Event} event - The Event object associated with the click.
   * @param {string} [label] - Optional label to use for analytics properties data object.
   */
  function handleButtonClick(event, label) {
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Social Media Share',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: label || event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  return (
    <div
      className="button-group-wrapper social-media-share"
      data-testid="lc-social-media-share"
    >
      <ul
        className={`button-item-listing share-links-group normal-width ${
          center ? 'center' : 'left'
        }-align`}
      >
        {mediaShareIcons.map((shareIcon) => {
          return shareIcon.url ? (
            <ButtonItem
              icon={{
                externalIcon: `icon-${shareIcon.iconSlug}`,
                field: 'external-icons',
              }}
              key={`share-icon-${shareIcon.iconSlug}`}
              onClick={(event) => handleButtonClick(event, shareIcon.label)}
              style="share-link"
              target="_blank"
              url={shareIcon.url}
            />
          ) : null;
        })}
        {shareLink &&
        shareLinkOnClick &&
        typeof shareLinkOnClick === 'function' ? (
          <ButtonItem
            icon={{ externalIcon: 'icon-share-link', field: 'external-icons' }}
            onClick={(event) => {
              handleButtonClick(event);
              shareLinkOnClick(event);
            }}
            style="share-link"
          />
        ) : null}
      </ul>
    </div>
  );
};

export default SocialMediaShare;
