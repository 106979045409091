/**
 * @module LocationServe
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import Card from '../OpenContentListItem/OpenContentListItem';
import '../OpenContentList/OpenContentList.scss';

const LocationServe = (props) => {
  const { label, servingOpportunities, singleIcon = true } = props;

  const validServingOpportunities = servingOpportunities?.filter(
    (servingOpportunity) => servingOpportunity['@id'],
  );

  return validServingOpportunities?.length ? (
    <div
      className="position-relative bg-serving_blue py-very_relaxed py-tablet-even_extra_more_relaxed py-laptop-even_more_relaxed"
      data-testid="lc-location-serve"
    >
      {!!singleIcon && (
        <div className="singleIcon">
          <svg
            fill="none"
            height="96"
            viewBox="0 0 96 96"
            width="96"
            xmlns="http://www.w3.org/2000/svg"
          >
            <ellipse cx="48" cy="47.8256" fill="#1C70C9" rx="48" ry="47.8256" />
            <path
              clipRule="evenodd"
              d="M59.2912 62.4185C59.2835 62.3966 59.2709 62.3777 59.2626 62.3565C59.2101 62.2201 59.1516 62.0868 59.0813 61.9605C59.0801 61.9593 59.0795 61.9575 59.0789 61.9557C59.0049 61.8229 58.919 61.6984 58.8272 61.578C58.8123 61.5585 58.798 61.5385 58.7824 61.5196C58.6894 61.4033 58.5898 61.2935 58.4818 61.1914C58.4747 61.1843 58.4663 61.1778 58.4586 61.1707C57.9951 60.7393 57.4088 60.4466 56.7772 60.3498C56.7748 60.3498 56.7724 60.3492 56.77 60.3492C56.6245 60.3273 56.4778 60.312 56.3286 60.312C56.3239 60.312 56.3191 60.3132 56.3137 60.3132C56.3072 60.3132 56.3006 60.3114 56.2935 60.3114C55.4179 60.3114 54.6264 60.6697 54.0604 61.2451C53.7174 61.5928 53.4585 62.0195 53.3118 62.4952C53.0589 61.6754 52.5018 61.0055 51.7378 60.6325C51.3316 60.4306 50.8747 60.312 50.3814 60.312C50.3719 60.312 50.3624 60.3132 50.3522 60.3132C50.3445 60.3132 50.3373 60.3114 50.3296 60.3114C50.1071 60.3114 49.8715 60.348 49.6341 60.4129C49.6299 60.4141 49.6251 60.4147 49.621 60.4159C49.5655 60.4312 49.51 60.4489 49.4546 60.4678C49.4003 60.4849 49.346 60.5044 49.2923 60.5245C49.2846 60.528 49.2762 60.531 49.2685 60.5339C48.669 60.7676 48.0953 61.1879 47.7046 61.7928C47.6795 61.8312 47.6551 61.8696 47.6312 61.9091C47.6175 61.9321 47.6044 61.9557 47.5913 61.9799C47.3938 62.3276 47.2626 62.7212 47.2262 63.1556C47.2244 63.1739 47.2203 63.191 47.2185 63.2093L47.2232 63.2123C47.2191 63.2737 47.2119 63.3339 47.2119 63.3964C47.2119 64.11 47.3843 64.8991 47.7612 65.7567L47.7553 65.7549C47.7684 65.7838 47.7827 65.8145 47.7964 65.8435C47.8024 65.8576 47.809 65.8718 47.8155 65.886C48.4376 67.247 49.7373 69.2372 53.3691 72.1215C54.3019 71.3867 55.1101 70.6785 55.805 69.9985C55.805 69.9985 56.309 69.5577 56.9018 68.8441C58.7449 66.7412 59.469 64.9345 59.469 63.4472C59.469 63.086 59.4028 62.7419 59.2876 62.4208L59.2912 62.4185Z"
              fill="white"
              fillRule="evenodd"
            />
            <path
              clipRule="evenodd"
              d="M46.8897 23.5296C52.7415 23.5296 57.5017 28.24 57.5017 34.0306C57.5017 39.8206 52.7415 44.5311 46.8897 44.5311C41.0385 44.5311 36.2783 39.8206 36.2783 34.0306C36.2783 28.24 41.0385 23.5296 46.8897 23.5296ZM46.8898 48.0723C54.7147 48.0723 61.0806 41.773 61.0806 34.0306C61.0806 26.2876 54.7147 19.9883 46.8898 19.9883C39.0656 19.9883 32.6997 26.2876 32.6997 34.0306C32.6997 41.773 39.0656 48.0723 46.8898 48.0723Z"
              fill="white"
              fillRule="evenodd"
            />
            <path
              clipRule="evenodd"
              d="M46.8901 50.4797C33.7724 50.4797 23.1001 60.3617 23.1001 72.5076C23.1001 73.4856 23.9011 74.2783 24.8894 74.2783C25.8778 74.2783 26.6788 73.4856 26.6788 72.5076C26.6788 62.3141 35.7454 54.021 46.8901 54.021C58.0348 54.021 67.1014 62.3141 67.1014 72.5076C67.1014 73.4856 67.9024 74.2783 68.8908 74.2783C69.8791 74.2783 70.6801 73.4856 70.6801 72.5076C70.6801 60.3617 60.0078 50.4797 46.8901 50.4797Z"
              fill="white"
              fillRule="evenodd"
            />
          </svg>
        </div>
      )}
      <div className="maxWidth-768 container mx-auto mb-slightly_more_relaxed mb-laptop-very_relaxed">
        <h2 className="location-section-title text-white mb-slight_relax">
          {!!label && label.serve_at_title} {props.name}
        </h2>
        <p className="text-white text-feature_paragraph line-height-tighter text-center">
          {!!label && label.serve_at_desc}
        </p>
      </div>
      <div className="maxWidth-1026 container mx-auto open-content-list even content-vertical-cards">
        <div>
          {validServingOpportunities.map((item) => {
            let link;
            try {
              link =
                !!item && !!item.link && item.link !== '#'
                  ? item.link
                      .replace(/{{campus_code}}/g, props.campusCode || '')
                      .replace(/{{legacy_id}}/g, props.legacyId || '')
                  : '#';
            } catch {
              link = '#';
            }
            return (
              !!item && (
                <Card
                  content={item.description}
                  image={`${item.featuredImage}`}
                  imageIsExternal={true}
                  key={item['@id']}
                  linkTitle={item.linkTitle}
                  target={item.linkTarget}
                  title={item.showName === 'true' ? item.name : null}
                  type="vertical-cards"
                  url={link}
                />
              )
            );
          })}
        </div>
      </div>
    </div>
  ) : null;
};

export default LocationServe;
