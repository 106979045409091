/**
 * @module TabPanel
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
 * Represents a tab panel element.
 *
 * @param {object} props - The component props object.
 * @param {ReactNode} props.children - The content to be displayed within the tab panel.
 * @param {boolean} props.isHidden - Boolean flag indicating whether or not the tab panel is hidden.
 * @param {string} props.tabIdentifier - The identifier for the tab, used for accessibility.
 *
 * @returns {React.ReactElement} The Poster component.
 */
export default function TabPanel({ children, isHidden, tabIdentifier }) {
  return !isHidden ? (
    <div aria-labelledby={tabIdentifier} className="tabpanel" role="tabpanel">
      {children}
    </div>
  ) : null;
}
