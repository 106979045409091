/* eslint-disable jsx-a11y/media-has-caption */
/**
 * @module SingleContentZone
 */
import React from 'react';
import { EditableArea, EditorContextHelper } from '@magnolia/react-editor';
import { Log } from '@lifechurch/web-tools-io/dist/utils/helpers/browserLogger';
import { convertToCmsAssetUrl } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/imgix';
import { convertValueToClassName } from '@lifechurch/web-tools-io/dist/utils/helpers/validators';
import useWindowSize from '@lifechurch/web-tools-io/dist/hooks/useWindowSize';
import YoutubeVideo from '../YoutubeVideo/YoutubeVideo';
import LCBackground from '../LCImage/LCBackground';
import { isColorSettingValidClassName } from '../../helpers/classNames';
import './SingleContentZone.scss';

const SingleContentZone = (props) => {
  const {
    backgroundImage,
    backgroundImageMobile,
    backgroundVideo,
    bgcolor,
    bgRepeat,
    colorsetting,
    gradientend,
    gradientstart,
    gradientType,
    icon,
    metadata,
    pyOnMobile = 'py-even_more_relaxed',
    pyOnTabletAndUp = 'py-even_more_relaxed',
    sbOnMobile,
    sbOnTabletAndUp,
    sectionId,
    youtubeID,
    zoneHeight,
    zones,
    zoneWidth = 'half',
  } = props;
  let { alignment } = props;

  const { isMobile, isTablet } = useWindowSize();
  const singleRef = React.useRef(null);
  const bgImgDesktop = backgroundImage || '';
  const bgImgMobile = backgroundImageMobile ?? backgroundImage ?? '';
  const [finalVideoBackground, setFinalVideoBackground] = React.useState();
  const [youTubeVideoError, setYouTubeVideoError] = React.useState(false);

  const backgroundSrc = isTablet || isMobile ? bgImgMobile : bgImgDesktop;

  const isDevMode = EditorContextHelper.inIframe();

  const flexAlignments = {
    'text-center': 'center',
    'text-left': 'flex-start',
    'text-right': 'flex-end',
  };

  alignment = flexAlignments?.[alignment] ?? '';

  const iconClassName = !backgroundImage
    ? ` bg-${convertValueToClassName(bgcolor)} p-10`
    : '';
  const colorSettingClass = convertValueToClassName(
    isColorSettingValidClassName(colorsetting) ? colorsetting : '',
  );

  const lcBackgroundClass =
    `single-contentzone position-relative bg-${convertValueToClassName(
      bgcolor,
    )} ${bgRepeat === 'true' ? 'bg-repeat' : ''} ${
      zoneHeight === '70vh' ? 'height-70vh' : ''
    } ${!!icon && 'mt-56'} ${colorSettingClass} ${
      isMobile
        ? `${convertValueToClassName(sbOnMobile)} ${convertValueToClassName(
            pyOnMobile,
          )}`
        : `${convertValueToClassName(
            sbOnTabletAndUp,
          )} ${convertValueToClassName(pyOnTabletAndUp)}`
    }`.trim();
  const gradientClass = `gradient-${convertValueToClassName(
    gradientstart,
  )}-${convertValueToClassName(gradientend)}-${convertValueToClassName(
    gradientType,
  )}`;
  const zoneContainerClass = `container justify-${convertValueToClassName(
    alignment,
  )} ${convertValueToClassName(zoneWidth)}`.trim();

  /**
   * Convenience callback to determine final video URL based on conversion to
   * CMS Assets URL rather than Imgix.
   */
  const convertUrl = React.useCallback(async () => {
    try {
      const cmsAssetUrl = await convertToCmsAssetUrl({
        cmsAssetsBaseUrl: process.env.CMS_ASSETS_BASE_URL,
        imgixBaseUrl: process.env.IMGIX_BASE_URL,
        source: backgroundVideo,
      });
      setFinalVideoBackground(cmsAssetUrl);
    } catch (error) {
      /* istanbul ignore next */
      Log.error(error);
    }
  }, [backgroundVideo]);

  /**
   * Convenience effect to trigger url conversion to CMS Asset.
   */
  React.useEffect(() => {
    convertUrl();
  }, [backgroundVideo, convertUrl]);

  const handleOnYTNotAvailable = (error) => {
    setYouTubeVideoError(error);
  };

  return (
    <section id={sectionId} ref={singleRef}>
      <LCBackground
        className={lcBackgroundClass}
        devmodeexception={isDevMode}
        imgixParams={bgRepeat === 'true' ? { ar: '1:1' } : ''}
        parentref={singleRef}
        src={backgroundSrc}
      >
        {finalVideoBackground && !youtubeID && !isMobile ? (
          <video
            autoPlay="autoplay"
            className="video-background"
            loop={true}
            muted="muted"
            style={{ maxWidth: '100%' }}
          >
            <source src={finalVideoBackground} />
          </video>
        ) : null}

        {youtubeID && !youTubeVideoError ? (
          <div className="video-background" style={{ maxWidth: '100%' }}>
            <YoutubeVideo
              autoplay={true}
              hideInfo={true}
              loop={true}
              onYTNotAvailable={handleOnYTNotAvailable}
              youtubeID={youtubeID}
            />
          </div>
        ) : null}

        {!!icon && (
          <div className={`singleIcon${iconClassName}`}>
            <img alt="single-icon" src={`${icon}`} />
          </div>
        )}
        <div
          className={gradientClass}
          style={{
            height: '100%',
            position: 'absolute',
            top: 0,
            width: '100%',
          }}
        ></div>
        <div className={zoneContainerClass} style={{ position: 'relative' }}>
          {!!zones && (
            <EditableArea
              content={zones}
              parentTemplateId={metadata['mgnl:template']}
            />
          )}
        </div>
      </LCBackground>
    </section>
  );
};

export default SingleContentZone;
