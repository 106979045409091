/**
 * @module LaunchedBannerV2
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import Button from '../../ButtonItem/ButtonItem';
import { ACTIONS, EVENTS } from '../../../helpers/constants';
import './LaunchedBannerV2.scss';

/**
 * Represents a Banner component for a launched location containing the campus name and service time call-to-action.
 *
 * @param {object} props - The component props object.
 * @param {boolean} [props.isVisible] - The value which will be used to show or hide the banner.
 * @param {object} props.locationData - The location data object for the banner.
 * @param {Function} props.openServiceTimesModal - The callback to open the ServiceTimes modal.
 *
 * @returns {React.ReactElement} The LaunchedBannerV2 component.
 */
export default function LaunchedBannerV2({
  isVisible,
  locationData,
  openServiceTimesModal,
}) {
  const { user } = useAuth();

  /**
   * Handler function for button click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleButtonClick(event) {
    /* istanbul ignore next */
    if (openServiceTimesModal) {
      openServiceTimesModal();
    }

    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Location Launched - Banner',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  return (
    <>
      {locationData ? (
        <section
          className={`location-banner bg-blue ${
            isVisible ? 'sticky-banner' : 'display-block'
          } `}
          data-testid="lc-launched-banner-v2"
        >
          <div className="banner-container text-white">
            <h3>{locationData.title}</h3>
            <Button
              buttonSize="medium"
              darkVariant="true"
              icon={{
                externalIcon: 'icon-clock-stroked',
                field: 'external-icons',
              }}
              onClick={handleButtonClick}
              style="btn-primary"
              text="Find a Service Time"
            />
          </div>
        </section>
      ) : null}
    </>
  );
}
