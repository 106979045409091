/**
 * @module LocationPosterAddress
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import { ACTIONS, EVENTS } from '../../helpers/constants';
import LocationPosterSpan from './LocationPosterSpan';

const LocationPosterAddress = ({ address, title }) => {
  const { user } = useAuth();
  if (!address) {
    return null;
  }
  const { cityOrTown, latitude, longitude, state, street1, street2, zip } =
    address;

  const locationAddressContent = (
    <>
      <LocationPosterSpan addLineBreak={true} text={street1} />
      <LocationPosterSpan addLineBreak={true} text={street2} />
      <LocationPosterSpan endWith={', '} text={cityOrTown} />
      <LocationPosterSpan endWith={' '} text={state} />
      <LocationPosterSpan text={zip} />
    </>
  );

  /**
   * Handler function for element click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleElementClick(event) {
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Location Poster',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  return (
    <p
      className="text-weight-semi_bold display-flex justify-space-between"
      data-testid="lc-location-poster-address"
    >
      <span className="section-title">{title}</span>
      <span className="address">
        {latitude && longitude ? (
          <a
            href={`https://www.google.com/maps/dir//${latitude},${longitude}`}
            onClick={handleElementClick}
            rel="noreferrer"
            target="_blank"
          >
            {locationAddressContent}
          </a>
        ) : (
          <span>{locationAddressContent}</span>
        )}
      </span>
    </p>
  );
};

export default LocationPosterAddress;
