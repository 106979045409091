/**
 * @module CollectionAlbumHero
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import ButtonItem from '../ButtonItem/ButtonItem';
import LCBackground from '../LCImage/LCBackground';
import { ACTIONS, EVENTS } from '../../helpers/constants';

const CollectionAlbumHero = ({ data, isMobile }) => {
  const {
    amazonMusicUnlimitedURL,
    amazonMusicURL,
    appleMusicURL,
    channelImageList,
    googlePlayURL,
    itunesURL,
    pandoraURL,
    spotifyURL,
    title,
    youtubeURL,
  } = data;

  const { user } = useAuth();
  const imgixParamsCollectionListingAlbumHero = { blur: 160, bri: -15, w: 400 };
  const imgixParamsAlbumImage = { w: 400 };
  const imgixImage = channelImageList?.albumImage_public ?? '';

  const channelIcons = [
    {
      iconSlug: 'apple-music',
      url: appleMusicURL,
    },
    {
      iconSlug: 'spotify',
      url: spotifyURL,
    },
    {
      iconSlug: 'itunes',
      url: itunesURL,
    },
    {
      iconSlug: 'google-music',
      url: googlePlayURL,
    },
    {
      iconSlug: 'amazon-music',
      url: amazonMusicURL,
    },
    {
      iconSlug: 'amazon-music-unlimited',
      url: amazonMusicUnlimitedURL,
    },
    {
      iconSlug: 'pandora',
      url: pandoraURL,
    },
    {
      iconSlug: 'youtube',
      url: youtubeURL,
    },
  ];

  /**
   * Convenience function to trigger Segment analytics tracking.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleButtonClick(event) {
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Media - Collection Album Hero',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  return (
    <LCBackground
      backup={'album-img'}
      className={`collection-album-hero-container has-bg-image ${
        imgixImage ? 'text-white' : ''
      }`}
      imgixParams={imgixParamsCollectionListingAlbumHero}
      src={imgixImage}
    >
      {isMobile && title ? <h1 className="album-title">{title}</h1> : null}
      {imgixImage ? (
        <LCBackground
          backup={'album-img'}
          className="album-image has-bg-image"
          imgixParams={imgixParamsAlbumImage}
          src={imgixImage}
        ></LCBackground>
      ) : null}
      <div className="button-group-wrapper mb-relaxed">
        {!isMobile && title ? <h1 className="album-title">{title}</h1> : null}
        {data ? (
          <ul className="button-item-listing channel-buttons-group full-width center-align">
            {channelIcons.map((iconItem) => {
              return iconItem.url ? (
                <ButtonItem
                  icon={{
                    channelIcon: `icon-channel-${iconItem.iconSlug}`,
                    field: 'channel-icons',
                  }}
                  key={`icon-channel-${iconItem.iconSlug}`}
                  onClick={handleButtonClick}
                  style="btn-channel"
                  target="_blank"
                  url={iconItem.url}
                />
              ) : null;
            })}
          </ul>
        ) : /* istanbul ignore next*/ null}
      </div>
    </LCBackground>
  );
};

export default CollectionAlbumHero;
