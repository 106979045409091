/**
 * @module PodcastLeadGenerationForm
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { getLabelValue } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/labels';
import EmbedItem from '../EmbedItem/EmbedItem';

/**
 * Represents a container with content for lead generation.
 *
 * @param {object} props - The component props object.
 * @param {Array<LabelItem>} props.labelList - Array of label items, specifically those specified in PodcastLeadGenerationLabels.
 *
 * @returns {React.ReactElement} The PodcastLeadGenerationForm element.
 */
function PodcastLeadGenerationForm({ labelList }) {
  const labelPrefix = 'lead_gen';
  const labelItems = {
    content: getLabelValue(labelList, `${labelPrefix}_content`),
    disclaimer: getLabelValue(labelList, `${labelPrefix}_disclaimer`),
    formId: getLabelValue(labelList, `${labelPrefix}_form_id`),
    title: getLabelValue(labelList, `${labelPrefix}_title`),
  };

  return (
    <>
      {Object.values(labelItems).some((value) => value) ? (
        <div
          className="podcast-lead-gen-form mb-bit_more_relaxed mb-tablet-very_relaxed mb-laptop-a_little_relaxed"
          data-testid="podcast-lead-gen-form"
        >
          <div className="container" data-testid="podcast-lead-gen-container">
            {labelItems.title ? (
              <h3 className="title" data-testid="podcast-lead-gen-form-title">
                {labelItems.title}
              </h3>
            ) : null}
            <div
              className="content-and-form"
              data-testid="podcast-lead-gen-content-and-form"
            >
              {labelItems.content ? (
                <p
                  className="text-paragraph_large"
                  data-testid="podcast-lead-gen-form-content"
                >
                  {labelItems.content}
                </p>
              ) : null}
              {labelItems.formId ? (
                <EmbedItem
                  sectionId="podcast-lead-gen-form"
                  type={{
                    field: 'hubspot',
                    hubspotFormID: labelItems.formId,
                  }}
                />
              ) : null}
              {labelItems.disclaimer ? (
                <p
                  className="text-paragraph_small mb-none mt-relaxed"
                  data-testid="podcast-lead-gen-form-disclaimer"
                >
                  {labelItems.disclaimer}
                </p>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default PodcastLeadGenerationForm;
