/**
 * @module EventDaysAndTimes
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
 * Represents the days and times section of the event.
 *
 * @param {object} props - The component props object.
 * @param {object} props.schedule - Object where keys are event days and values are arrays of event times.
 *
 * @returns {React.ReactElement} The EventDaysAndTimes component.
 */
export default function EventDaysAndTimes({ schedule }) {
  if (!schedule) {
    return null;
  }
  return (
    <>
      {Object.entries(schedule).map(([eventDay, eventTimes]) => (
        <div className="poster-event-date-time" key={eventDay}>
          <h5 className="poster-event-date">{eventDay}</h5>
          <div className="poster-event-times">
            {eventTimes.map((eventTime) => (
              <div className="poster-event-time" key={eventTime}>
                {eventTime}
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
}
