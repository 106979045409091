/**
 * @module GetUpdates
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { HUBSPOT } from '../../helpers/constants';
import Hubspot from '../EmbedItem/Hubspot/Hubspot';

const GetUpdates = () => {
  return (
    <div className="get-update" data-testid="lc-get-updates">
      <div className="container">
        <Hubspot
          className="btn-inside-input"
          hubspotFormID={HUBSPOT.formIds.getUpdates}
        />
      </div>
    </div>
  );
};

export default GetUpdates;
