export const metroCoordinates = [
  {
    name: 'okc',
    lat: '35.42719580978272',
    lng: '-97.54184327948172',
    zoom: 9.540790271779617,
    mobileZoom: 8.874201590041702,
  },

  {
    name: 'tulsa',
    lat: '36.14590588991187',
    lng: '-95.89550553365997',
    zoom: 10.131175854455098,
    mobileZoom: 9.688598162825894,
  },

  {
    name: 'dfw',
    lat: '32.91623819925323',
    lng: '-97.07747101123256',
    zoom: 9.249588744764916,
    mobileZoom: 8.129847737808158,
  },

  {
    name: 'wichita',
    lat: '37.6927113563011',
    lng: '-97.32706212653076',
    zoom: 10.544184766572581,
    mobileZoom: 9.488063773208026,
  },

  {
    name: 'kansascity',
    lat: '39.03792187620368',
    lng: '-94.64064949802457',
    zoom: 9.988063773208047,
    mobileZoom: 9.0753130768849,
  },

  {
    name: 'denver',
    lat: '39.76399606890894',
    lng: '-104.98663176548145',
    zoom: 9.8,
    mobileZoom: 9,
  },

  {
    name: 'southflorida',
    lat: '26.667709785091475',
    lng: '-80.1808321846363',
    zoom: 12,
    mobileZoom: 11.2,
  },
];
