/**
 * @module LocationDetailPreLaunched
 */
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import ButtonItem from '../ButtonItem/ButtonItem';
import LCImage from '../LCImage/LCImage';
import Mapbox from '../LocationsListing/_Mapbox';
import Modal from '../Modal/Modal';
import { ACTIONS, EVENTS } from '../../helpers/constants';
import './LocationDetailPreLaunched.scss';

const LocationDetailPreLaunched = (props) => {
  const { user } = useAuth();
  const [mapIsHovered, setMapIsHovered] = React.useState(false);
  const [modal, setModal] = React.useState(null);
  const { label } = props;
  const sAddress = props.secondaryAddress;
  const pAddress = props.primaryAddress;

  /**
   * Convenience function to trigger analytics tracking.
   *
   * @param {Event} event - The Event object associated with the user interaction to track.
   * @param {string} labelValue - Optional label value to use for analytics properties data object.
   */
  function callAnalytics(event, labelValue) {
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Location Detail Pre-Launched',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: labelValue || event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  /**
   * Handler function for link click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleLinkClick(event) {
    callAnalytics(event);
  }

  /**
   * Handler function for phone number link click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handlePhoneClick(event) {
    callAnalytics(event, 'Phone');
  }

  const handleOpenModal = (event) => {
    event.preventDefault();
    callAnalytics(event);

    setModal({
      modalType: {
        field: 'formModal',
        type: {
          field: 'iframe',
          iframeSource: `https://my.life.church/forms/questions?Location=${props.campusCode}&embedded=true`,
        },
      },
    });
  };

  return (
    <section
      className="location-detail-prelaunched paster-detail-and-map container"
      data-testid="lc-location-detail-prelaunched"
    >
      <div className="pastor-detail-wrapper">
        {!!pAddress &&
        (!!pAddress.street1 ||
          !!pAddress.street2 ||
          !!pAddress.cityOrTown ||
          !!pAddress.state ||
          !!pAddress.zip) ? (
          <div className="contact-service align-center mb-bit_more_relaxed">
            <h2 className="service-title">
              {!!label && label.weekend_services}
            </h2>
            <p className="service-address">
              {!!pAddress.street1 && (
                <>
                  <span>{pAddress.street1}</span>
                  <br />
                </>
              )}
              {!!pAddress.street2 && (
                <>
                  <span>{pAddress.street2}</span>
                  <br />
                </>
              )}
              {!!pAddress.cityOrTown && (
                <>
                  <span>{pAddress.cityOrTown}, </span>
                </>
              )}
              {!!pAddress.state && (
                <>
                  <span>{pAddress.state} </span>
                </>
              )}
              {!!pAddress.zip && (
                <>
                  <span>{pAddress.zip}</span>
                </>
              )}
            </p>
          </div>
        ) : null}
        {!!sAddress &&
        (!!sAddress.secondaryStreet1 ||
          !!sAddress.secondaryStreet2 ||
          !!sAddress.secondaryCityOrTown ||
          !!sAddress.secondaryState ||
          !!sAddress.secondaryZip) ? (
          <div className="contact-service align-center">
            <h2 className="service-title">
              {!!label && label.weekday_contact}
            </h2>
            <p className="service-address">
              {!!sAddress.secondaryStreet1 && (
                <>
                  <span>{sAddress.secondaryStreet1}</span>
                  <br />
                </>
              )}
              {!!sAddress.secondaryStreet2 && (
                <>
                  <span>{sAddress.secondaryStreet2}</span>
                  <br />
                </>
              )}
              {!!sAddress.secondaryCityOrTown && (
                <>
                  <span>{sAddress.secondaryCityOrTown}, </span>
                </>
              )}
              {!!sAddress.secondaryState && (
                <>
                  <span>{sAddress.secondaryState} </span>
                </>
              )}
              {!!sAddress.secondaryZip && (
                <>
                  <span>{sAddress.secondaryZip}</span>
                </>
              )}
            </p>
          </div>
        ) : null}
        {props.phone ? (
          <a
            className="service-phone text-weight-bold text-paragraph_large mb-more_relaxed"
            data-testid="phone-link"
            href={`tel:${props.phone}`}
            onClick={handlePhoneClick}
          >
            {props.phone}
          </a>
        ) : null}
        <ButtonItem
          backgroundColor="blue"
          className="align-center mb-more_relaxed"
          onClick={handleOpenModal}
          style="btn-secondary"
          text="Connect With Our Team"
          url=""
        />
        <div className="profile-wrapper align-center">
          {props.pastorImage ? (
            <LCImage
              className="profile-image"
              htmlAttributes={{ alt: `${props.pastorName}-pastor-img` }}
              src={`${props.pastorImage}`}
            />
          ) : null}
          {props.pastorName ? (
            <div className="profile-detail">
              <h1 className="profile-name">{props.pastorName}</h1>
              <p className="profile-role">Pastor</p>
            </div>
          ) : null}
        </div>
      </div>
      <div
        className="map-wrapper"
        onMouseEnter={() => setMapIsHovered(true)}
        onMouseLeave={() => setMapIsHovered(false)}
      >
        <Mapbox
          center={
            !!pAddress.latitude && !!pAddress.longitude
              ? [Number(pAddress.longitude), Number(pAddress.latitude)]
              : false
          }
          isPrelaunch={true}
          locations={[
            {
              latitude: pAddress.latitude ? Number(pAddress.latitude) : false,
              longitude: pAddress.longitude
                ? Number(pAddress.longitude)
                : false,
              name: props.name,
              phase: props.phase ? props.phase.field : 'Launched',
              slug: props.slug ? props.slug : props['@name'],
            },
          ]}
          zoom={11}
        />
        <div
          className={`get-direction-wrapper ${mapIsHovered ? 'hovered' : ''}`}
        >
          <a
            className="btn-with-map-icon"
            data-testid="btn-map-icon-link"
            href={`https://www.google.com/maps/search/?api=1&query=${Number(
              pAddress.latitude,
            )},${Number(pAddress.longitude)}`}
            onClick={handleLinkClick}
            rel="noreferrer"
            target="_blank"
          >
            <svg
              aria-hidden="true"
              data-icon="map-marker-alt"
              data-prefix="fal"
              fill="currentColor"
              focusable="false"
              viewBox="0 0 384 512"
              width="22px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M192 96c-52.935 0-96 43.065-96 96s43.065 96 96 96 96-43.065 96-96-43.065-96-96-96zm0 160c-35.29 0-64-28.71-64-64s28.71-64 64-64 64 28.71 64 64-28.71 64-64 64zm0-256C85.961 0 0 85.961 0 192c0 77.413 26.97 99.031 172.268 309.67 9.534 13.772 29.929 13.774 39.465 0C357.03 291.031 384 269.413 384 192 384 85.961 298.039 0 192 0zm0 473.931C52.705 272.488 32 256.494 32 192c0-42.738 16.643-82.917 46.863-113.137S149.262 32 192 32s82.917 16.643 113.137 46.863S352 149.262 352 192c0 64.49-20.692 80.47-160 281.931z"></path>
            </svg>
            <span>Get Directions</span>
          </a>
        </div>
      </div>
      {modal ? (
        <Modal
          data={modal}
          setShowStatus={() => setModal(null)}
          showStatus={true}
        />
      ) : null}
    </section>
  );
};

export default LocationDetailPreLaunched;
