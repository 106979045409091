/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module MenuModal
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import MenuModalLink from './MenuModalLink';
import './MenuModal.scss';

const MenuModal = (props) => {
  /**
   * Handler function for menu item click.
   *
   * @param {Event} event - The Event object associated with the click event.
   */
  /* istanbul ignore next */
  function handleMenuItemClick(event) {
    // If `onMenuItemSelect` prop is present and a function, invoke it and pass
    // in the function to call from it, which in this case, is the function to
    // call Segment track.
    if (
      props.onMenuItemSelect &&
      typeof props.onMenuItemSelect === 'function'
    ) {
      const anchorElements =
        event?.currentTarget?.getElementsByTagName('a') || [];
      if (anchorElements.length) {
        const spanElements =
          anchorElements[0].getElementsByClassName('modal-link-title') || [];
        if (spanElements.length) {
          props.onMenuItemSelect();
        }
      }
    }
  }

  function getRootItem(item) {
    if (item['@nodeType'] === 'mgnl:folder') {
      return (
        <li
          className="root-menu-item menu-folder"
          data-testid="lc-menu-modal-section"
          key={item['@id']}
        >
          <span>{/* istanbul ignore next */ item.name || ''}</span>
          <ul key={`${item['@id']}-list`}>
            {item['@nodes'].map((child) => (
              <li
                className="menu-sub-item"
                key={item[child]['@id']}
                onClick={handleMenuItemClick}
              >
                <MenuModalLink menuItem={item[child]} />
              </li>
            ))}
          </ul>
        </li>
      );
    }
    return (
      <li
        className="root-menu-item menu-link"
        key={item['@id']}
        onClick={handleMenuItemClick}
      >
        <MenuModalLink menuItem={item} />
      </li>
    );
  }

  return (
    <div
      className={`reveal-overlay${props.isOpen ? ' show' : ' hide'}`}
      data-testid="lc-careers-menu-modal"
    >
      <section
        className={`careers-menu-modal${props.isOpen ? ' show' : ' hide'}`}
      >
        {props?.menuData ? (
          <div className={`modal-navigation laptop-hide`}>
            <ul className="careers-menu-list" key={'careers-menu-list'}>
              {props.menuData.length
                ? props.menuData.map((menuItem) => getRootItem(menuItem))
                : null}
            </ul>
          </div>
        ) : null}
      </section>
    </div>
  );
};

export default MenuModal;
