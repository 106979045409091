/* eslint-disable no-unused-vars */
/**
 * @module LocationEvents
 */
import React from 'react';
import Card from '../OpenContentListItem/OpenContentListItem';
import baptism from './baptism.png';
import known from './known.png';
import '../OpenContentList/OpenContentList.scss';

/**
 * Represents a location events element.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.campusCode] - The campus code value.
 * @param {object} [props.label] - The label object.
 * @param {string} [props.name] - The location name.
 *
 * @returns {React.ReactElement} The LocationEvents component.
 */
export default function LocationEvents(props) {
  const { campusCode, label, name } = props;
  const baptismUrl = `https://my.life.church/forms/baptism?source=web&referrer=${window.location.origin}&Location=${campusCode}`;
  const knownUrl = `https://my.life.church/forms/known?source=web&referrer=${window.location.origin}&Location=${campusCode}`;

  return (
    <div
      className="maxWidth-1026 mx-auto mb-so_relaxed mb-tablet-a_little_relaxed mb-laptop-so_relaxed"
      data-testid="lc-location-events"
    >
      <h2 className="location-section-title text-gray mb-bit_more_relaxed mb-tablet-slightly_more_relaxed mb-laptop-very_relaxed">
        {name} Events
      </h2>
      <div className="container">
        <div className="open-content-list even content-vertical-cards">
          <div>
            <Card
              content={label?.event_baptism_content}
              image={baptism}
              imageIsExternal={true}
              linkTitle="Learn More"
              target="_blank"
              title=""
              type="vertical-cards"
              url={baptismUrl}
            />
            <Card
              content={label?.event_known_content}
              image={known}
              imageIsExternal={true}
              linkTitle="Learn More"
              target="_blank"
              title=""
              type="vertical-cards"
              url={knownUrl}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
