/**
 * @module LocationWhatToExpect
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import useWindowSize from '@lifechurch/web-tools-io/dist/hooks/useWindowSize';
import Button from '../ButtonItem/ButtonItem';
import { ACTIONS, EVENTS } from '../../helpers/constants';
import './LocationWhatToExpect.scss';

const LocationWhatToExpect = ({ label }) => {
  const { user } = useAuth();
  const { isDesktop } = useWindowSize();

  /**
   * Handler function for button click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleButtonClick(event) {
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Location Page',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  return (
    <section
      className="location-what-to-expect mb-slightly_more_relaxed text-white"
      data-testid="lc-what-to-expect"
    >
      {!!isDesktop && (
        <video
          autoPlay="autoplay"
          className="bg-video"
          loop={true}
          muted={true}
          src="https://d.life.church/info/cities/cities-header_720p.mp4"
        ></video>
      )}
      <div className="container">
        <div className="maxWidth-768">
          <h2 className="title mb-normal">
            {!!label && label.what_to_expect_title}
          </h2>
          <p className="description">{!!label && label.what_to_expect_desc}</p>
          <div className="btn-group">
            <Button
              buttonSize="medium"
              className=""
              darkVariant="true"
              onClick={handleButtonClick}
              style="btn-primary"
              target="_blank"
              text="Attend Online"
              url="https://live.life.church/"
            />
            <Button
              buttonSize="medium"
              className=""
              darkVariant="true"
              onClick={handleButtonClick}
              style="btn-secondary"
              target="_self"
              text="Learn More"
              url="/what-to-expect/"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default LocationWhatToExpect;
