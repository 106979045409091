/* eslint-disable react/jsx-props-no-spreading */
/**
 * @module LocationPrelaunchedV1
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { getLabelValue } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/labels';
import { LOCATIONS } from '../../helpers/constants';
import LocationButton from '../LocationButton/LocationButton';
import LocationDetailPreLaunched from '../LocationDetailPreLaunched/LocationDetailPreLaunched';
import LocationPrelaunchedHero from '../LocationPrelaunchedHero/LocationPrelaunchedHero';
import LocationServe from '../LocationServe/LocationServe';
import LocationStaticText from '../LocationStaticText/LocationStaticText';
import LocationWhatToExpect from '../LocationWhatToExpect/LocationWhatToExpect';

const LocationPrelaunchedV1 = (props) => {
  const { campusCode, hubspotId, labelList } = props;
  const showMap = !LOCATIONS.hide.map?.includes(campusCode);
  const showLifeKids = !LOCATIONS.hide.lifeKids?.includes(campusCode);
  const showCommunity = !LOCATIONS.hide.community?.includes(campusCode);
  const hasLiveInYourCity = LOCATIONS.liveInYourCity?.includes(campusCode);

  return (
    <section
      className="location-detail location-prelaunched"
      data-testid="lc-location-prelaunched-v1"
    >
      <LocationPrelaunchedHero
        {...props}
        label={{
          hero_btn_txt: getLabelValue(labelList, 'hero_btn_txt'),
        }}
      />
      {showMap ? (
        <LocationDetailPreLaunched
          {...props}
          label={{
            weekday_contact: getLabelValue(labelList, 'weekday_contact'),
            weekend_services: getLabelValue(labelList, 'weekend_services'),
          }}
        />
      ) : null}
      <LocationButton {...props} />
      <LocationWhatToExpect
        {...props}
        label={{
          what_to_expect_desc: getLabelValue(labelList, 'what_to_expect_desc'),
          what_to_expect_title: getLabelValue(
            labelList,
            'what_to_expect_title',
          ),
        }}
      />

      {/* Live Event */}
      {hasLiveInYourCity ? (
        <LocationStaticText
          {...props}
          className={'py-slightly_more_relaxed has-background'}
          ctas={[
            {
              actionType: 'button',
              btn_txt: 'RSVP Now',
              iframeSrc: '',
              modalType: 'iframe',
            },
          ]}
          descClassName={'mb-bit_more_relaxed'}
          label={{
            desc: '',
            title: '',
          }}
          titleClassName={'text-section_header mb-normal'}
        />
      ) : null}

      {/* Start Building Community */}
      {showCommunity ? (
        <LocationStaticText
          {...props}
          className={
            'mb-slightly_more_relaxed py-slightly_more_relaxed has-background'
          }
          ctas={[
            {
              actionType: 'button',
              btn_txt: 'Start A LifeGroup',
              iframeSrc: `https://www.life.church/webview-app/multistep-forms/serving/`,
              modalType: 'iframe',
            },
            {
              actionType: 'button',
              btn_txt: 'Find A LifeGroup',
              style: 'btn-secondary',
              url: `/lifegroups/search/?campus=${campusCode}`,
            },
          ]}
          descClassName={'mb-bit_more_relaxed'}
          label={{
            desc: getLabelValue(labelList, 'building_community_desc'),
            title: getLabelValue(labelList, 'building_community_title'),
          }}
          titleClassName={'text-section_header mb-normal'}
        />
      ) : null}

      {/* Learn How To Get Involved */}
      {hubspotId !== '' ? (
        <LocationStaticText
          {...props}
          className={'mb-slightly_more_relaxed'}
          ctas={[
            {
              actionType: 'button',
              btn_txt: getLabelValue(labelList, 'info_btn_txt'),
            },
          ]}
          descClassName={'mb-bit_more_relaxed'}
          label={{
            desc: getLabelValue(labelList, 'info_desc'),
            title: getLabelValue(labelList, 'info_title'),
          }}
          titleClassName={'text-section_header mb-normal'}
        />
      ) : null}

      <LocationServe
        {...props}
        label={{
          serve_at_desc: getLabelValue(labelList, 'serve_at_desc'),
          serve_at_title: getLabelValue(labelList, 'serve_at_title'),
        }}
        singleIcon={false}
      />

      {/* New To Life.Church? */}
      {showLifeKids ? (
        <LocationStaticText
          {...props}
          className={
            'mb-slightly_more_relaxed mt-very_relaxed mt-tablet-even_extra_more_relaxed mt-laptop-even_more_relaxed'
          }
          ctas={[
            {
              actionType: 'button',
              btn_txt: 'Tell Us About Your Family',
              target: '_blank',
              url: `https://my.life.church/forms/family?source=web&referrer=${window.location.origin}${window.location.pathname}&Location=${campusCode}`,
            },
          ]}
          descClassName={'mb-bit_more_relaxed'}
          label={{
            desc: getLabelValue(labelList, 'new_to_lc_desc'),
            title: getLabelValue(labelList, 'new_to_lc_title'),
          }}
          titleClassName={'text-section_header mb-normal'}
        />
      ) : null}
    </section>
  );
};

export default LocationPrelaunchedV1;
