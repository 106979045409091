/**
 * @module PastSeries
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { getLogoPathBasedOnCollectionType } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/imgix';
import { getLabelValueV2 } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/labels';
import { mediaUrlFromType } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/mediaTypeUtils';
import { getUrlStructureByPath } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/urls';
import { convertValueToClassName } from '@lifechurch/web-tools-io/dist/utils/helpers/validators';
// eslint-disable-next-line import/no-relative-packages
import LCImage from '../LCImage/LCImage';
import Card from '../OpenContentListItem/OpenContentListItem';

const PastSeries = ({
  data,
  source,
  collectionType,
  className = '',
  labels,
}) => {
  const logoPath = getLogoPathBasedOnCollectionType(
    collectionType,
    process.env.IMGIX_BASE_URL,
  );
  const moreMediaTitleLabel = getLabelValueV2(labels, collectionType);

  const containerClass = `past-series ${convertValueToClassName(
    className,
  )}`.trim();

  return data?.length ? (
    <div className={containerClass} data-testid="lc-past-series">
      <div
        className={`open-content-list container odd content-vertical-cards ${
          data.length === 1 ? 'has-single-item' : ''
        }`}
      >
        {moreMediaTitleLabel ? (
          <h1 className="past-series-title">
            {logoPath ? (
              <LCImage
                className="extra-icon"
                src={`${logoPath}.svg`}
                width={32}
              />
            ) : null}
            {moreMediaTitleLabel}
          </h1>
        ) : null}
        <div>
          {data.map((item) => {
            const {
              collection,
              item: itemSlug,
              subCollection,
            } = getUrlStructureByPath(item?.['@path']);
            const itemUrl = mediaUrlFromType({
              slug: itemSlug,
              subCollection,
              type: collection,
            });
            const image =
              source === 'media'
                ? item.featuredImage_public
                : item.channelImageList.cinemaImage_public;

            return (
              <Card
                image={image}
                key={item['@id']}
                target="_self"
                title={item.title}
                type="vertical-cards"
                url={itemUrl}
                wholeCardLink={true}
              />
            );
          })}
        </div>
      </div>
    </div>
  ) : null;
};

export default PastSeries;
