/**
 * @module CountdownTimer
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import { convertValueToClassName } from '@lifechurch/web-tools-io/dist/utils/helpers/validators';
import useWindowSize from '@lifechurch/web-tools-io/dist/hooks/useWindowSize';
import { isColorSettingValidClassName } from '../../helpers/classNames';
import './CountdownTimer.scss';

const moment = require('moment-timezone');

// Possible issue in Arizona. No campus location at the moment.
const timezoneToCityMap = {
  CT: 'America/Chicago',
  ET: 'America/New_York',
  MT: 'America/Denver',
  PT: 'America/Los_Angeles',
};

const CountdownTimer = (props) => {
  const {
    date,
    timezone,
    display,
    message,
    messageSize,
    sbOnMobile,
    sbOnTabletAndUp,
    colorsetting,
    alignment,
    sectionId,
  } = props;
  const { isMobile } = useWindowSize();
  const colorSettingClass = convertValueToClassName(
    isColorSettingValidClassName(colorsetting) ? colorsetting : '',
  );

  const getDateDetails = () => {
    const [tmpDate, tmpTime] = date.split('T');
    const [year, month, day] = tmpDate.split('-');
    const [hour, minute, second] = tmpTime.substring(0, 8).split(':');
    return {
      d: day,
      h: hour,
      m: month,
      min: minute,
      s: second,
      y: year,
    };
  };

  const getZonedDate = () => {
    const dd = getDateDetails();
    const dateStr = `${dd.y}/${dd.m}/${dd.d} ${dd.h}:${dd.min}:${dd.s}`;
    const offset = moment.tz(dateStr, timezoneToCityMap[timezone]).format('Z');
    const zonedDate = new Date(`${dateStr} ${offset}`);
    return zonedDate;
  };

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    const containerClass = `countdown-timer${
      completed ? ' completed' : ''
    } text-${convertValueToClassName(messageSize)} ${
      isMobile
        ? convertValueToClassName(sbOnMobile)
        : convertValueToClassName(sbOnTabletAndUp)
    } ${convertValueToClassName(alignment)} ${colorSettingClass}`.trim();

    if (!completed) {
      return (
        <div className={containerClass} data-testid="lc-countdowntimer">
          <div className="timer-days timer-value">
            <span className="value-field">{days}</span>
            <span className="value-name">Day{days !== 0 ? 's' : ''}</span>
          </div>
          {display === 'time' && (
            <>
              <div className="timer-hours timer-value">
                <span className="value-field">{zeroPad(hours)}</span>
                <span className="value-name">Hour{hours !== 0 ? 's' : ''}</span>
              </div>
              <div className="timer-minutes timer-value">
                <span className="value-field">{zeroPad(minutes)}</span>
                <span className="value-name">
                  Minute{/* istanbul ignore next */ minutes !== 0 ? 's' : ''}
                </span>
              </div>
              <div className="timer-seconds timer-value">
                <span className="value-field">{zeroPad(seconds)}</span>
                <span className="value-name">
                  Second{/* istanbul ignore next */ seconds !== 0 ? 's' : ''}
                </span>
              </div>
            </>
          )}
        </div>
      );
    }
    return message ? (
      <div className={containerClass} data-testid="lc-countdown-timer-message">
        {message}
      </div>
    ) : null;
  };

  return <Countdown date={getZonedDate()} id={sectionId} renderer={renderer} />;
};

export default CountdownTimer;
