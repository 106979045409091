/**
 * @module LaunchedHeroV2
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import {
  Log,
  convertToCmsAssetUrl,
} from '@lifechurch/web-tools-io/dist/utils/helpers';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import useWindowSize from '@lifechurch/web-tools-io/dist/hooks/useWindowSize';
import BitmovinPlayer from '../../BitmovinPlayer/BitmovinPlayer';
import Button from '../../ButtonItem/ButtonItem';
import heroBackgroundMedium from '../../LocationV2/assets/HeroBackgroundMedium.png';
import heroBackgroundSmall from '../../LocationV2/assets/heroBackgroundSmall.png';
import Modal from '../../Modal/Modal';
import { ACTIONS, EVENTS } from '../../../helpers/constants';
import './LaunchedHeroV2.scss';

/**
 * Represents the Hero section for a launched campus location page.
 *
 * @param {object} props - The component props object.
 * @param {object} props.locationData - The location data object.
 *
 * @returns {React.Component} The LaunchedHeroV2 component.
 */
export default function LaunchedHeroV2({ locationData }) {
  const { user } = useAuth();
  const { isMobile, isDesktop } = useWindowSize();
  const [showMeetPastorVideo, setShowMeetPastorVideo] = React.useState(false);
  const [finalVideoBackground, setFinalVideoBackground] = React.useState();

  const modal = {
    modalType: {
      field: 'videoModal',
      videoUrl: locationData?.videoUrl,
    },
  };

  /**
   * Handler function for button click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleLearnMoreClick(event) {
    setShowMeetPastorVideo(true);

    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Location Launched - Header',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  /**
   * Convenience callback and memo to determine final video URL based on convert
   * to CMS Assets URL rather than Imgix.
   */
  const convertUrl = React.useCallback(async () => {
    try {
      const cmsAssetUrl = await convertToCmsAssetUrl({
        cmsAssetsBaseUrl: process.env.CMS_ASSETS_BASE_URL,
        imgixBaseUrl: process.env.IMGIX_BASE_URL,
        source: locationData?.videoBackground,
      });
      setFinalVideoBackground(cmsAssetUrl);
    } catch (error) {
      /* istanbul ignore next */
      Log.error(error);
    }
  }, [locationData?.videoBackground]);

  /**
   * Convenience effect to trigger url conversion to CMS Asset.
   */
  React.useEffect(() => {
    convertUrl();
  }, [convertUrl, locationData?.videoBackground]);

  return (
    <>
      {locationData ? (
        <section
          className="location-hero-v2"
          data-testid="lc-location-launched-hero-v2"
        >
          {finalVideoBackground && isDesktop ? (
            <video autoPlay={true} className="hero-bg" loop={true} muted={true}>
              <source src={finalVideoBackground} />
            </video>
          ) : (
            <img
              alt="Hero background"
              className="hero-bg"
              src={isMobile ? heroBackgroundSmall : heroBackgroundMedium}
            />
          )}

          <div className="container display-flex">
            <div className="content text-white text-center">
              <p>{locationData.description}</p>
              <Button
                buttonSize="large"
                className="align-center"
                darkVariant="true"
                icon={{
                  externalIcon: 'icon-play-filled',
                  field: 'external-icons',
                }}
                onClick={handleLearnMoreClick}
                style="btn-primary"
                text="Learn About Life.Church"
              />
            </div>
          </div>
          {showMeetPastorVideo && locationData.videoUrl ? (
            <Modal
              data={modal}
              setShowStatus={setShowMeetPastorVideo}
              showStatus={showMeetPastorVideo}
            >
              <BitmovinPlayer videoUrl={locationData.videoUrl} />
            </Modal>
          ) : null}
        </section>
      ) : null}
    </>
  );
}
