/**
 * @module LcoOnDemandBannerFetcher
 */
import { Log } from '@lifechurch/web-tools-io/dist/utils/helpers/browserLogger';
import { getLabelList } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/labels';
import { ENDPOINT_WORKSPACE_MAP, LABELS, MGNL_ENV_VARS } from '../constants';

/**
 * Convenience function to iterate over and normalize the provided label list.
 *
 * Note: The normalization of the label list includes logic to iterate over the
 * labels and assign the name and value as key/pair values of the final data
 * object returned. For certain labels, a number, integer, boolean, or date-time
 * are the values, but come in as strings. As such, there is conditional logic
 * to ensure those are properly cast here on initial setting.
 *
 * @param {Array<object>} labelList - The label list to iterate over and normalize.
 *
 * @returns {object} A normalized data object with key/value pairs based on the label names and values.
 */
export function normalizeLabelList(labelList) {
  const normalizedData = {};
  labelList.forEach((label) => {
    if (label.name.startsWith('countdown_')) {
      normalizedData[label.name] = parseInt(label.value, 10);
    } else if (['end', 'start'].includes(label.name)) {
      normalizedData[label.name] = new Date(label.value);
    } else if (label.name === 'is_enabled') {
      normalizedData[label.name] = label.value === 'true';
    } else if (label.name === 'days') {
      normalizedData[label.name] = label.value.split(',');
    } else {
      normalizedData[label.name] = label.value;
    }
  });
  return normalizedData;
}

/**
 * Convenience data fetcher method to retrieve Life.Church Online On-Demand data
 * (labels list) for the <LifeChurchOnlineOnDemandBanner /> component.
 *
 * @returns {object} A normalized data object with key/value pairs based on the label names and values.
 */
async function fetchLcoOnDemandData() {
  let labelsData = {};
  try {
    const lcoEhothLabelList = await getLabelList({
      ancestor: 'lco_on_demand_ehoth',
      mgnlEnvVars: MGNL_ENV_VARS,
      workspaceMap: ENDPOINT_WORKSPACE_MAP,
    });
    /**
     * This was an edge case left in for production environment fallback to use
     * default labels if Magnolia admin wasn't yet updated. No need to cover
     * this particular logic, as the intent is to ensure it returns good keys.
     */
    /* istanbul ignore next */
    const labelList =
      lcoEhothLabelList?.length > 0
        ? lcoEhothLabelList
        : LABELS.lco_on_demand_ehoth;
    labelsData = normalizeLabelList(labelList);
  } catch (error) {
    /* istanbul ignore next */
    Log.error(error);
    /* istanbul ignore next */
    labelsData = normalizeLabelList(LABELS.lco_on_demand_ehoth);
  }
  return labelsData;
}

export default fetchLcoOnDemandData;
