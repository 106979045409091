/**
 * @module AboutAlbumAndSong
 */
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import { Log } from '@lifechurch/web-tools-io/dist/utils/helpers/browserLogger';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import { normalizeTimestamp } from '@lifechurch/web-tools-io/dist/utils/helpers/date';
import LCImage from '../LCImage/LCImage';
import SocialMediaShare from './SocialMediaShare';
import Modal from '../Modal/Modal';
import ButtonItem from '../ButtonItem/ButtonItem';
import { ACTIONS, EVENTS } from '../../helpers/constants';

const AboutAlbumAndSong = ({ data, label, isMobile, preload }) => {
  const { user } = useAuth();
  const { albumCopyrightDate, channelImageList, description, title } = data;
  const songs = preload;
  const [modal, setModal] = React.useState(false);
  const [copyText, setCopyText] = React.useState('Copy');
  const inputLinkRef = React.useRef(null);
  const copyrightYear = albumCopyrightDate
    ? new Date(albumCopyrightDate).getFullYear()
    : '';

  /**
   * Convenience function to trigger Segment analytics tracking.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function callAnalytics(event) {
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Media - About Album and Song',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  // Handler Functions.
  const handleShareLinkClicked = (event) => {
    event.preventDefault();
    setModal(!modal);
    callAnalytics(event);
  };

  const handleCopyBtnClicked = async (event) => {
    callAnalytics(event);
    try {
      event.target.classList.add('copied');
      /* istanbul ignore next */
      if (inputLinkRef) {
        inputLinkRef.current?.select();
      }
      await navigator.clipboard.writeText(inputLinkRef?.current?.value);
      setCopyText('Copied!');
    } catch (err) {
      /* istanbul ignore next */
      Log.error('Failed to copy text: ', err);
    }
  };

  return (
    <div
      className={`about-album-and-songs container ${
        isMobile ? 'my-very_relaxed' : 'my-slightly_more_relaxed'
      }`}
      data-testid="lc-about-album-and-song"
    >
      <div className="content-wrapper">
        {description ? (
          <>
            <h2 className="content-title mt-none">{label.about_album}</h2>
            <p className="content-text">{description}</p>
          </>
        ) : null}
        {copyrightYear ? (
          <p className="copy-right-text">
            ©{copyrightYear} Life.Church Worship
          </p>
        ) : null}
        <div className="content-wrapper">
          <h3 className="share-album-title text-group_header mt-more_relaxed mb-relaxed">
            Share The Album
          </h3>
          <SocialMediaShare
            data={data}
            instagram={false}
            linkedin={false}
            shareLink={true}
            shareLinkOnClick={handleShareLinkClicked}
            youtube={false}
          />
        </div>
      </div>

      {songs?.length ? (
        <div className="songs-listing-wrapper">
          <h2 className="songs-heading text-subhead">Songs</h2>
          <ul className="songs-listing">
            {songs.map((item) => (
              <SongItem data={item} key={item['@id']} />
            ))}
          </ul>
        </div>
      ) : null}

      {modal ? (
        <Modal
          setShowStatus={() => {
            setModal(!modal);
          }}
          showStatus={true}
        >
          <div className="share-link-modal-content">
            <h2 className="share-title">Share The Album</h2>
            <LCImage
              className="album-image"
              htmlAttributes={{ alt: 'album-image' }}
              src={channelImageList?.albumImage_public ?? ''}
              width={100}
            />
            {title ? <p className="album-title">{title}</p> : null}
            <div className="link-copy-wrapper">
              <input
                className="link-input-box"
                defaultValue={window.location.href}
                ref={inputLinkRef}
                type="text"
              />
              <ButtonItem
                buttonSize="medium"
                className="btn-copy-link"
                onClick={handleCopyBtnClicked}
                style="btn-primary"
                text={copyText}
              />
            </div>
          </div>
        </Modal>
      ) : null}
    </div>
  );
};

const SongItem = ({ data }) => {
  const { timestamp, title, part } = data;
  return (
    <li className="song-item">
      <span className="song-order text-weight-bold">{part}</span>
      <span className="song-name">{title}</span>
      <span className="song-periods">
        {timestamp ? normalizeTimestamp(timestamp) : ''}
      </span>
    </li>
  );
};

export default AboutAlbumAndSong;
