/**
 * @module LaunchedDownloadV2
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import Button from '../../ButtonItem/ButtonItem';
import { ACTIONS, EVENTS } from '../../../helpers/constants';
import './LaunchedDownloadV2.scss';

/**
 * Represents the Download section for a launched location, featuring a call-to-action to download the Life.Church app.
 *
 * @param {object} props - The component props object.
 * @param {object} props.locationData - The location data object for the Download section.
 *
 * @returns {React.ReactElement} The LaunchedDownloadV2 component.
 */
export default function LaunchedDownloadV2({ locationData }) {
  const { user } = useAuth();

  /**
   * Handler function for download button click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleDownloadClick(event) {
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Location Launched - App Banner',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  return (
    <>
      {locationData ? (
        <section
          className="location-download-v2"
          data-testid="lc-launched-download-v2"
        >
          <div className="container text-center">
            <div className="content display-flex flex-column text-white">
              <img alt="Life.Church" src={locationData.img} />
              <h2 className="my-relaxed">{locationData.title}</h2>
              <Button
                buttonSize="medium"
                darkVariant="true"
                onClick={handleDownloadClick}
                style="btn-secondary"
                target="_blank"
                text="Download the Life.Church App"
                url="https://www.life.church/app/"
              />
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
}
