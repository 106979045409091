/* eslint-disable react/jsx-props-no-spreading */
/**
 * @module LocationPrelaunched
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { LOCATIONS } from '../../helpers/constants';
import LocationPrelaunchedV1 from './LocationPrelaunchedV1';
import LocationPrelaunchedV2 from './LocationPrelaunchedV2';

const LocationPrelaunched = (props) => {
  const showPrelaunchedV2 = LOCATIONS?.prelaunchedV2?.includes(
    props.campusCode,
  );

  return showPrelaunchedV2 ? (
    <LocationPrelaunchedV2 {...props} />
  ) : (
    <LocationPrelaunchedV1 {...props} />
  );
};

export default LocationPrelaunched;
