/* eslint-disable no-unused-vars */
/**
 * @module DownloadLeaderGuideModal
 */
import React from 'react';
import {
  callGtagEvent,
  callSegmentTrack,
} from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import { getLabelValue } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/labels';
import { getQueryParam } from '@lifechurch/web-tools-io/dist/utils/helpers/queryParams';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import Hubspot from '../EmbedItem/Hubspot/Hubspot';
import { ACTIONS, CATEGORIES, EVENTS } from '../../helpers/constants';

// NOTE: This file, while recently updated, did not get any updates for ESLint,
// as it was outside the scope of work for that update. This will get updated at
// a later date when it is within scope of the update.
const DownloadLeaderGuideModal = (props) => {
  const {
    latestPodcastTitle,
    onClose,
    onDownloadClick,
    podcastTitle,
    resource,
    resourceLabels,
    screen,
  } = props;

  const { user } = useAuth();
  const modalRef = React.useRef(null);
  const [title, setTitle] = React.useState('');
  const [desc, setDesc] = React.useState('');
  const [id, setId] = React.useState('');

  const handleOutsideClick = React.useCallback(
    (e) => {
      if (!!modalRef && !!modalRef.current) {
        if (
          !modalRef.current.contains(e.target) &&
          onClose &&
          typeof onClose === 'function'
        ) {
          onClose(false);
        }
      }
    },
    [onClose],
  );

  React.useEffect(() => {
    const labels = resourceLabels;
    const lang = resource.lang ? resource.lang : 'EN';
    if (lang === 'EN') {
      setTitle(getLabelValue(labels, 'modal_title_en'));
      setDesc(getLabelValue(labels, 'modal_desc_en'));
      setId(getLabelValue(labels, 'modal_id_en'));
    } else {
      setTitle(getLabelValue(labels, 'modal_title_es'));
      setDesc(getLabelValue(labels, 'modal_desc_es'));
      setId(getLabelValue(labels, 'modal_id_es'));
    }

    let timesRun = 0;
    /* istanbul ignore next */
    const form_interval = setInterval(() => {
      timesRun += 1;
      const hsButtons = document.querySelectorAll('input[type=submit]');

      if (timesRun < 5 && !!hsButtons && !!hsButtons.length) {
        clearInterval(form_interval);
        const downloadBtn = hsButtons[0];
        downloadBtn.addEventListener('click', () => {
          // Trigger onDownloadClick prop function.
          if (onDownloadClick && typeof onDownloadClick === 'function') {
            onDownloadClick(resource);
          }
        });
      }
    }, 1000);

    document.addEventListener('click', handleOutsideClick, false);

    return () => clearInterval(form_interval);
  }, [handleOutsideClick, onDownloadClick, resource, resourceLabels]);

  return (
    <div className="podcast-content-form-modal">
      <div className="modal-header">
        <button className="btn-exit mr-normal" onClick={() => onClose(false)}>
          <svg
            fill="none"
            viewBox="0 0 18 18"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clipRule="evenodd"
              d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM3.96967 5.03033L7.93934 9L3.96967 12.9697L5.03033 14.0303L9 10.0607L12.9697 14.0303L14.0303 12.9697L10.0607 9L14.0303 5.03033L12.9697 3.96967L9 7.93934L5.03033 3.96967L3.96967 5.03033Z"
              fill="#ECECEC"
              fillRule="evenodd"
            />
          </svg>
        </button>
      </div>
      <div className="container text-center" ref={modalRef}>
        <h2 className="title">{title}</h2>
        <p className="description">{desc}</p>
        {!!id && (
          <Hubspot
            className="btn-inside-input"
            hubspotFormID={id}
            onFormReady={
              /* istanbul ignore next */ ($form) => {
                document
                  .getElementsByClassName('hs-submit')[0]
                  .addEventListener('click', (event) => {
                    const emailInputParent =
                      document.getElementsByClassName('hs-email')[0];
                    const emailInput = emailInputParent.querySelectorAll(
                      "input[name='email']",
                    )[0];
                    const emailValue = emailInput ? emailInput.value : '';
                    const resourceTitle =
                      latestPodcastTitle ?? podcastTitle ?? '';
                    const eventLabel = `${resource.lang} - ${resourceTitle}`;
                    const utmMap = {
                      campaign: getQueryParam('utm_campaign'),
                      medium: getQueryParam('utm_medium'),
                      name: getQueryParam('utm_name'),
                      source: getQueryParam('utm_source'),
                    };

                    /**
                     * Note: With the completion of epic MND-1694 and Segment
                     * tracking improvements, the new set of event properties is
                     * used, but the original (legacy) ones are also spread on
                     * to the properties object.
                     */
                    const legacySegmentProperties = {
                      campaign: utmMap.campaign,
                      email: emailValue,
                      label: eventLabel,
                      screen,
                    };

                    callGtagEvent({
                      parameters: {
                        event_category: CATEGORIES.downloadLeaderGuide,
                        event_label: eventLabel,
                      },
                      type: 'Download',
                    });
                    callSegmentTrack({
                      event: EVENTS.leadershipPodcastSubscribeFormSubmit,
                      properties: {
                        action: ACTIONS.formSubmit,
                        component: 'Download Leader Guide Modal',
                        component_url:
                          event?.currentTarget?.getAttribute('href'),
                        label: event?.currentTarget?.textContent,
                        logged_in: !!user,
                        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
                        referrer: document?.referrer || null,
                        title: document?.title || '',
                        url: window?.location?.href,
                        user_id:
                          user?.[
                            'https://www.life.church/rock_person_alias_id'
                          ],
                        ...legacySegmentProperties,
                      },
                    });
                    document.cookie =
                      'leadership_podcast_optin=1; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT';
                  });
              }
            }
          />
        )}
      </div>
    </div>
  );
};

export default DownloadLeaderGuideModal;
