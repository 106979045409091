/* eslint-disable react/jsx-props-no-spreading */
/**
 * @module LCBackground
 */
import React from 'react';
import { Background } from 'react-imgix';
import { replaceMagnoliaAssetsToImgix } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/imgix';
import { convertValueToClassName } from '@lifechurch/web-tools-io/dist/utils/helpers/validators';

const LCBackground = (props) => {
  const {
    backup,
    devmodeexception,
    htmlAttributes,
    imgixParams,
    src = '',
    ...noSrcProps
  } = props;
  const [imgixSrc, setImgixSrc] = React.useState('');
  const [sizes, setSizes] = React.useState({});

  const bgClass = `imgixBG ${convertValueToClassName(props.className)}`;

  async function setImgixUrl() {
    let url = await replaceMagnoliaAssetsToImgix(
      decodeURI(src),
      process.env.IMGIX_BASE_URL,
    );
    if (backup && !url) {
      url = `${process.env.IMGIX_BASE_URL}/Media/${backup}.jpg`;
    }
    setImgixSrc(url);
  }

  function handleImgOnLoad() {
    if (props?.parentref?.current) {
      setSizes({
        h: props?.parentref?.current.clientHeight,
        w: props?.parentref?.current.clientWidth,
      });
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useMemo(() => setImgixUrl(), [src]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => handleImgOnLoad(), []);

  if (devmodeexception && !!src) {
    const style = imgixSrc
      ? { backgroundImage: `url(${imgixSrc})` }
      : { backgroundImage: `url(${src})` };
    return (
      <div {...noSrcProps} style={style}>
        {props.children}
      </div>
    );
  }

  return (
    <>
      {!!src || !!backup ? (
        !!imgixSrc && (
          <Background
            {...props}
            className={bgClass}
            htmlAttributes={{ ...htmlAttributes }}
            imgixParams={
              imgixParams
                ? {
                    fit: 'auto',
                    fm: 'webp',
                    ...sizes,
                    ...imgixParams,
                  }
                : { fit: 'auto', fm: 'webp', ...sizes }
            }
            src={decodeURI(imgixSrc)}
          >
            {props.children}
          </Background>
        )
      ) : (
        <div {...noSrcProps}>{props.children}</div>
      )}
    </>
  );
};
export default LCBackground;
