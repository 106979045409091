/**
 * @module LaunchedContactCardAddressV2
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

const LaunchedContactCardAddressV2 = (props) => {
  const { addressData, handleAddressClick } = props;

  if (!addressData) {
    return null;
  }

  const { cityOrTown, latitude, longitude, state, street1, zip } = addressData;
  const campusAddress = (
    <>
      {street1 ? <p>{street1}</p> : null}
      <p>
        {cityOrTown ? <span>{`${cityOrTown},`} </span> : null}
        {state ? <span>{state} </span> : null}
        {zip ? <span>{zip}</span> : null}
      </p>
    </>
  );

  return latitude && longitude ? (
    <a
      href={`https://www.google.com/maps/dir//${latitude},${longitude}`}
      onClick={handleAddressClick}
      rel="noreferrer"
      target="_blank"
    >
      {campusAddress}
    </a>
  ) : (
    campusAddress
  );
};

export default LaunchedContactCardAddressV2;
