/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module AncherNavigation
 */
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import { convertValueToClassName } from '@lifechurch/web-tools-io/dist/utils/helpers/validators';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import useWindowSize from '@lifechurch/web-tools-io/dist/hooks/useWindowSize';
import angle from './angle.svg';
import { ACTIONS, EVENTS } from '../../helpers/constants';
import './AncherNavigation.scss';

const AncherNavigation = (props) => {
  const { user } = useAuth();
  const { isMobile } = useWindowSize();
  const { ancherButton, sbOnMobile, sbOnTabletAndUp } = props;
  const [activeTitle, setActiveTitle] = React.useState(null);
  const [mbActive, setMbActive] = React.useState(null);
  const [urlBase, setUrlBase] = React.useState('');

  const sectionClass = `ancher-navigation text-center ${
    isMobile
      ? convertValueToClassName(sbOnMobile)
      : convertValueToClassName(sbOnTabletAndUp)
  }`.trim();
  const ulClass = `nav-list ${mbActive ? 'active' : ''}`.trim();

  React.useEffect(() => {
    if (ancherButton?.['@nodes']?.length) {
      setActiveTitle(ancherButton[ancherButton['@nodes'][0]].ancherTitle);
    }
    setUrlBase(window.location.href.split('#')[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Handler function for mobile active list item click.
   *
   * @param {string} title - The title of the element.
   * @param {Event} event - The Event object associated with the click.
   */
  const handleMobileActive = (title, event) => {
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Anchor Navigation',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    setMbActive(!mbActive);
    setActiveTitle(title);
  };

  return (
    <section className={sectionClass} data-testid="lc-anchor-navigation">
      <h3
        className="title mobile-active"
        onClick={() => setMbActive(!mbActive)}
      >
        {activeTitle}
        <img alt="Angle icon" src={angle} />
      </h3>
      <ul className={ulClass}>
        {ancherButton?.['@nodes']?.length
          ? ancherButton['@nodes'].map((ancher) => {
              let url = '#';
              if (ancherButton[ancher].ancherUrl) {
                url =
                  ancherButton[ancher].ancherUrl.charAt(0) === '#'
                    ? urlBase + ancherButton[ancher].ancherUrl
                    : ancherButton[ancher].ancherUrl;
              }
              const liClass = `title ${
                activeTitle === ancherButton[ancher].ancherTitle ? 'active' : ''
              }`.trim();

              return (
                <li key={ancherButton[ancher]['@id']}>
                  <a
                    className={liClass}
                    href={url}
                    onClick={(event) => {
                      handleMobileActive(
                        ancherButton[ancher].ancherTitle,
                        event,
                      );
                    }}
                    target={ancherButton[ancher].target}
                  >
                    {ancherButton[ancher].ancherTitle}
                  </a>
                </li>
              );
            })
          : null}
      </ul>
    </section>
  );
};

export default AncherNavigation;
