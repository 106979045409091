/**
 * @module MediaCollectionEarlyChildhoodBased
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import useWindowSize from '@lifechurch/web-tools-io/dist/hooks/useWindowSize';
import { getLabelValue } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/labels';
import CollectionAlbumHero from '../../MediaSharedComponents/CollectionAlbumHero';
import AboutAlbumAndSong from '../../MediaSharedComponents/AboutAlbumAndSong';
import ChurchResources from '../../MediaSharedComponents/ChurchResources';
import GetUpdates from '../../MediaSharedComponents/GetUpdates';
import '../../MediaSharedComponents/style/_MediaComponentSharedStyle.scss';

const MediaCollectionAlbumSongBased = ({ data, labelList, preload }) => {
  const { isMobile } = useWindowSize();

  return (
    <section
      className="media-collection-episode-based"
      data-testid="lc-media-collection-album-song-based"
    >
      <CollectionAlbumHero data={data} isMobile={isMobile} />
      <AboutAlbumAndSong
        data={data}
        isMobile={isMobile}
        label={{
          about_album: getLabelValue(labelList, 'about_album_title'),
        }}
        preload={preload.aboutAlbumAndSong}
      />
      <ChurchResources
        data={data}
        isMobile={isMobile}
        label={{
          desc: getLabelValue(labelList, 'church_resource_desc'),
          title: getLabelValue(labelList, 'church_resource_title'),
        }}
      />
      <GetUpdates data={data} isMobile={isMobile} />
    </section>
  );
};

export default MediaCollectionAlbumSongBased;
