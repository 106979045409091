/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/aria-role */
/**
 * @module OpenContentListItem
 */
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import { replaceMagnoliaAssetsToImgix } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/imgix';
import { titleCase } from '@lifechurch/web-tools-io/dist/utils/helpers/titleCase';
import { implementUtmParams } from '@lifechurch/web-tools-io/dist/utils/helpers/utmParams';
import { convertValueToClassName } from '@lifechurch/web-tools-io/dist/utils/helpers/validators';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import LCBackground from '../LCImage/LCBackground';
import useModals from '../../hooks/useModals';
import { ACTIONS, ENVIRONMENT, EVENTS } from '../../helpers/constants';
import { implementAnchorTagAttributes } from '../../helpers/scriptHelper';
import './OpenContentListItem.scss';

const OpenContentListItem = (props) => {
  const {
    alt,
    backgroundSize,
    cardIdentifier,
    content,
    image,
    imageIsExternal,
    linkaction,
    modalTrigger,
    type,
    urllink,
    urltarget,
    wholeCardLink = 'false',
  } = props;
  const { user } = useAuth();
  const { addModalTrigger, showModal } = useModals();
  let { linkTitle, subTitle, target, title, url } = props;
  const ref = React.useRef(null);
  const isWholeCardLink = wholeCardLink !== 'false';
  const isModalTrigger = linkaction === 'modaltrigger';
  const hash = title?.replace(/\s+/g, '-')?.toLowerCase();
  const bgImg = image?.['@id'] || image?.['@link'] || image || '';
  const [backgroundImageSrc, setBackgroundImageSrc] = React.useState(bgImg);
  const [markup, setMarkup] = React.useState('');
  const maxTitle = 72; // Note: Original 60 : 40
  const maxSubtitle = type === 'vertical-cards' ? 40 : 60;
  const maxLinkTitle = type === 'vertical-cards' ? 28 : 48;
  title = title?.length > maxTitle ? `${title.substr(0, maxTitle)}...` : title;
  subTitle =
    subTitle?.length > maxSubtitle
      ? `${subTitle.substr(0, maxSubtitle)}...`
      : subTitle;
  linkTitle =
    linkTitle?.length > maxLinkTitle
      ? `${linkTitle.substr(0, maxLinkTitle)}...`
      : linkTitle;

  // Keeping backwards compatibility with old URL props
  url = urllink || url;
  target = urltarget || target;

  /**
   * Single-run convenience effect to add modal trigger if present.
   */
  React.useEffect(() => {
    if (isModalTrigger) {
      // No need to test modal hook function call here, but in modal test.
      /* istanbul ignore next */
      addModalTrigger(modalTrigger);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Convenience function to convert and normalize the component type to a more
   * human-readable, title-cased value.
   *
   * @param {string} typeValue - The component type value.
   *
   * @returns {string} The normalized, title-cased component type value.
   */
  function normalizeGroupType(typeValue) {
    if (!typeValue) {
      return '';
    }
    return titleCase(typeValue.replace(/-/g, ' '));
  }

  /**
   * Handler function for outside click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  /* istanbul ignore next */
  const handleOutsideClick = () => {
    document.removeEventListener('click', handleOutsideClick, false);
  };

  /**
   * Handler function for button click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  const handleButtonClick = (event) => {
    const normalizedTypeValue = normalizeGroupType(type);
    const componentLabelValueAddOn = normalizedTypeValue
      ? ` - ${normalizedTypeValue}`
      : '';
    if (event?.target?.preventDefault && ENVIRONMENT.test) {
      event.preventDefault();
    }
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: `Open Content List Item${componentLabelValueAddOn}`,
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });

    // Event should always be here, as it's explicitly passed. Testing without
    // for coverage is not necessary, and is acceptable as-is.
    /* istanbul ignore next */
    if (event) {
      document.addEventListener('click', handleOutsideClick, false);
    }
    if (isModalTrigger) {
      // No need to test modal hook function call here, but in modal test.
      /* istanbul ignore next */
      showModal(modalTrigger, true);
      window.location.hash = hash;
    }
  };

  /**
   * Handler function for link click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  const handleLinkClicked = (event) => {
    if (isModalTrigger || !url || url === '#') {
      event.preventDefault();
    }
    handleButtonClick(event);
  };

  /**
   * Convenience effect to ensure bgImg is converted to Imgix if needed.
   */
  React.useEffect(() => {
    async function getImage() {
      const result = await replaceMagnoliaAssetsToImgix(
        decodeURI(bgImg),
        process.env.IMGIX_BASE_URL,
      );
      setBackgroundImageSrc(result);
    }
    getImage();
  }, [bgImg]);

  /**
   * Convenience effect to convert content to implement anchor tag attributes on
   * content set to be injected via dangerouslySetInnerHTML.
   */
  React.useEffect(() => {
    setMarkup(implementAnchorTagAttributes(content, 'Heading'));
  }, [content]);

  // No need to test all edge-case and backup size settings, as sizes covered as
  // adequately as needed in test coverage at present.
  /* istanbul ignore next */
  const verticalCardsSize = ref?.current?.clientWidth
    ? { h: (ref?.current?.clientWidth ?? 0) / 2 }
    : { h: 400, w: 800 };

  const imgixBackgroundParams =
    type === 'vertical-cards' ? verticalCardsSize : { h: 100, w: 100 };

  const containerClass = `open-content-list-item ${convertValueToClassName(
    type,
  )} ${!url && !isModalTrigger ? 'no-link-trigger' : ''}`.trim();

  return (
    <>
      <div
        className={containerClass}
        data-path={url}
        data-testid="lc-open-content-list-item"
        id={cardIdentifier}
        ref={ref}
      >
        {isWholeCardLink ? (
          <a
            className="whole-card-link"
            href={implementUtmParams(url, window?.location)}
            onClick={handleLinkClicked}
            target={target || '_self'}
          ></a>
        ) : null}
        {!!image &&
          (imageIsExternal ? (
            <div
              aria-label={alt}
              className="open-content-feature-image"
              data-testid="open-content-feature-image"
              style={{
                backgroundImage: `url(${backgroundImageSrc}`,
                backgroundSize: `${backgroundSize}`,
              }}
            ></div>
          ) : (
            <LCBackground
              aria-label={alt}
              backup={'cinema-img'}
              className="open-content-feature-image"
              imgixParams={imgixBackgroundParams}
              parentref={ref}
              role="image"
              src={bgImg}
              style={{ backgroundSize: `${backgroundSize}` }}
            ></LCBackground>
          ))}
        <div className="open-content">
          <div>
            {title ? <h3 className="card-title">{title}</h3> : null}
            {type === 'vertical-cards' && subTitle ? (
              <p className="card-subtitle">{subTitle}</p>
            ) : null}
            {markup ? (
              <div
                className={`card-content ${url ? 'mb-normal' : 'no-mb'}`}
                dangerouslySetInnerHTML={{ __html: markup }}
              >
                {}
              </div>
            ) : null}
          </div>
          {(url || isModalTrigger) && linkTitle ? (
            <a
              className="card-link line-height-tightest"
              href={url || '#'}
              onClick={handleLinkClicked}
              target={target || '_self'}
            >
              {linkTitle}
            </a>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default OpenContentListItem;
